import React from "react";
import Icon from "../general/Icon";
const ReasonModal = ({ setModalState, jobData }) => {
    return (
        <>
            <div className='modal-background'>
                <div className='card generalcard' style={{ width: "600px", borderRadius: "20px" }}>
                    <div className="container-right-icon">
                        <div onClick={() => { setModalState({ modalIsOpen: false }) }} className="right-icon">
                            <Icon
                                type={"fa"}
                                icon={"xmark"}
                                icon_size={2}
                                icon_color_code={"#6d6f71"}
                            ></Icon>
                        </div>
                    </div>
                    <div className="" style={{ paddingBottom: "15px", fontSize: "16px", overflow: "auto", maxHeight: "500px", whiteSpace: "pre-wrap" }}>
                        {jobData.reason}
                    </div>
                    <div className="button-container-center">
                        <div onClick={() => { setModalState({ modalIsOpen: false }) }} className="button-blue button-100" style={{ marginBottom: "20px" }}>Close</div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default ReasonModal;