import React, { FunctionComponent, useEffect, useState  } from 'react';

type Props = {
    errors?: Array<string>;
    error?: string;
    setErrorState?: any;
}

type IRender = {
    ShouldIRender: boolean;
}

const ErrorMessage: FunctionComponent<Props> = ({ errors, error, setErrorState }) => {

    const [RenderStates, setRenderStates] = useState<IRender>({
        ShouldIRender: true
    })

    const displayError = () =>{
        if (errors) {
            return (
                <div className="alert alert-danger" role="alert" style={{marginTop: "20px"}}>
                {errors.map((error, index) => <div key={index} style={{fontWeight: "600"}}>{error}</div>)}
            </div>
            )
        } else if (error) {
            return (
            <div className="alert alert-danger" role="alert">
        <span>{error}</span>
            </div>
            )
        }
    }
    const deleteMessage = () => {

        setTimeout( () => {
            setRenderStates({...RenderStates, ShouldIRender: false})
            setErrorState({errors: []})
        }, 4500000)
    }
    useEffect(() => {
        deleteMessage()
    }, [errors, error]);

    return (
        <>
        {!RenderStates.ShouldIRender ? null :
        displayError()
        }
        </>
    )
}

export default ErrorMessage
