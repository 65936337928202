import React, { useState, useEffect } from "react";
import axios from "axios";
import FileUpload from "./FileUpload";
import getCookie from '../auth/Cookie';
import { useOutletContext } from "react-router-dom";
var csrftoken = getCookie('csrftoken');
const ChangeProfileImageModal = ({ imageState, settingsState, fileUploadListener, clearContent }) => {
    const context = useOutletContext();
    const changeProfileImage = () => {
        let method = "create"
        if (settingsState && settingsState.profile_image) {
            method = "update"
        }
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken,
                "Content-Type": "multipart/form-data"
            },
            method: "post",
            url: `${process.env.API_URL}api/settings/`,
            data: {
                profile_image: imageState.profile_image,
                method: method,
                type: "profile_image"
            }
        })
            .then(function (response) {
                context.setModalState({ modalIsOpen: false, type: null })
            })
    }

    useEffect(() => {


    }, [])
    return (
        <>
            <div className='modal-background'>
                <div className='card generalcard billingcard'>
                    <div className="modal-title" style={{ paddingBottom: "5px" }}>
                        Edit profile image
                    </div>
                    <div style={{ textAlign: "center" }}>Update profile image</div>
                    <div className="full-upload">
                        <FileUpload
                            fileUpload={fileUploadListener}
                            clearContent={clearContent}
                            type="file"
                            name="profile_image"
                            ariaLabel="profile_image"
                            image={imageState.profile_image || null}
                        />
                    </div>
                    <div className="button-container-center">
                        <div onClick={() => { context.setModalState({ modalIsOpen: false }) }} className="button-red button-100">Cancel</div>

                        {imageState && imageState.profile_image ?
                            <div onClick={() => changeProfileImage()} className="button-blue button-100">Submit</div>
                            :
                            <div className="button-grey button-100">Submit</div>}
                    </div>
                </div>

            </div>
        </>
    )
}
export default ChangeProfileImageModal;