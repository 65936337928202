import React, { FunctionComponent } from 'react';
import Icon from '../general/Icon';
import { Link } from 'react-router-dom';
type ITableHead = {
    columns: any;
    key: number;
    deleteEntry: any;

}

const TableRowJobAplications: FunctionComponent<ITableHead> = ({ columns, setModalState }) => {

    const tableTrueFalse = (value) => {
        if (value === true) {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect width="24" height="24" rx="8" fill="#E4FFF1" />
                    <path d="M10.2288 15.2189C9.94497 15.219 9.67274 15.1061 9.47219 14.9053L7.18461 12.6185C6.93846 12.3723 6.93846 11.9732 7.18461 11.727C7.43083 11.4808 7.82996 11.4808 8.07618 11.727L10.2288 13.8796L15.9238 8.18461C16.17 7.93846 16.5692 7.93846 16.8154 8.18461C17.0615 8.43083 17.0615 8.82996 16.8154 9.07618L10.9855 14.9053C10.7849 15.1061 10.5127 15.219 10.2288 15.2189Z" fill="#009A7D" />
                </svg>
            )
        } else {
            return (
                <Icon
                    type={"fa"}
                    icon={"xmark"}
                    icon_size={1}
                    icon_color_code={"#ff9292"}
                ></Icon>
            )
        }
    }

    const statusDisplay = (value) => {
        if (value == "accepted") {
            return (
                "Accepted"
            )
        } else if (value == "rejected") {
            return ("Rejected")
        } else if (value === "no_response") {
            return (
                "No Response"
            )
        }
    }

    const tableColumn = () => {
        let table_columns = []
        let socials_icons = []

        // table_columns.push(<td onClick={() => { setModalState({ "modalIsOpen": true, "type": "create_relation", "job_application_id": columns["id"] }) }} className="truncate_text td-create_relation">
        //     <Icon
        //         type={"fa"}
        //         icon={"circle-plus"}
        //         icon_size={1}
        //         icon_color_code={"#c0fbb2"}
        //     ></Icon>
        // </td>)


        table_columns.push(<td style={{ display: "flex" }} className="truncate_text td-user"><div className='creator-username'>{columns["email"] || "-"}</div></td>)
        socials_icons.push(
            <>
                {columns["tiktok"] ?
                    <a href={`https://tiktok.com/@${columns["tiktok"]}/`} target="_blank">
                        <Icon
                            type={"fa-brands"}
                            icon={"tiktok"}
                            icon_size={1}

                            icon_color_code={"#6d6f71"}
                        ></Icon>
                    </a>
                    : null
                }
                {columns["instagram"] ?
                    <a href={`https://instagram.com/${columns["instagram"]}/`} target="_blank">
                        <Icon
                            type={"fa-brands"}
                            icon={"instagram"}
                            icon_size={1}

                            icon_color_code={"#6d6f71"}
                        ></Icon>
                    </a>
                    : null
                }

                {columns["facebook"] ?
                    <a href={`https://facebook.com/${columns["facebook"]}/`} target="_blank">
                        <Icon
                            type={"fa-brands"}
                            icon={"facebook"}
                            icon_size={1}

                            icon_color_code={"#6d6f71"}
                        ></Icon>
                    </a>
                    : null
                }
                {columns["website"] ?
                    <a href={`${columns["website"]}`} target="_blank">
                        <Icon
                            type={"fa-regular"}
                            icon={"home"}
                            icon_size={1}

                            icon_color_code={"#6d6f71"}
                        ></Icon>
                    </a>
                    : <a href={``}>
                        <Icon
                            type={"fa-regular"}
                            icon={"home"}
                            icon_size={1}

                            icon_color_code={"#6d6f71"}
                        ></Icon>
                    </a>
                }
            </>
        )
        table_columns.push(<td className="truncate_text trunc-socials">{socials_icons}</td>)
        if (columns["reason"]) {
            table_columns.push(<td onClick={() => { setModalState({ "modalIsOpen": true, "type": "reason", "job_application_id": columns["id"] }) }} className="truncate_text td-reason">
                <Icon
                    type={"fa"}
                    icon={"circle-plus"}
                    icon_size={1}
                    icon_color_code={"#867bba"}
                ></Icon>
            </td>)
        } else {
            table_columns.push(<td className="truncate_text">{"-"}</td>)
        }
        table_columns.push(<td className="truncate_text">{tableTrueFalse(columns["can_recieve_email"]) || "-"}</td>)
        table_columns.push(<td className="truncate_text">{tableTrueFalse(columns["older_than_18"]) || "-"}</td>)
        table_columns.push(<td className="truncate_text">{tableTrueFalse(columns["accept_gifted"]) || "-"}</td>)
        table_columns.push(<td className="truncate_text">{tableTrueFalse(columns["accept_barter"]) || "-"}</td>)
        table_columns.push(<td className="truncate_text td-time">{columns["created"] || "-"}</td>)



        return table_columns
    }
    return (
        <>
            <tr key={columns["id"]}>
                {tableColumn()}
            </tr>
        </>
    )
}

export default TableRowJobAplications;
