import React from "react";
import Icon from "../general/Icon";
const SocialMediaPolicyModal = ({ text, setModalState }) => {
    return (
        <>
            <div className='modal-background'>
                <div className='card generalcard social-media-poilicy-card'>
                    <div className="container-right-icon">
                        <div onClick={() => { setModalState({ modalIsOpen: false }) }} className="right-icon">
                            <Icon
                                type={"fa"}
                                icon={"xmark"}
                                icon_size={2}

                                icon_color_code={"#6d6f71"}
                            ></Icon>
                        </div>
                    </div>
                    <div className="text">
                        {text}
                    </div>
                </div>

            </div>
        </>
    )
}
export default SocialMediaPolicyModal;