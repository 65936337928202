import React from "react";
import Icon from "../general/Icon";
const DeleteModal = ({ deleteEntry, setModalState, message }) => {
    return (
        <>
            <div className='modal-background'>
                <div className='card generalcard'>
                    <div className="container-right-icon">
                        <div onClick={() => { setModalState({ modalIsOpen: false }) }} className="right-icon">
                            <Icon
                                type={"fa"}
                                icon={"xmark"}
                                icon_size={2}

                                icon_color_code={"#6d6f71"}
                            ></Icon>
                        </div>
                    </div>

                    <div className="center-icon">
                        <Icon
                            type={"fa"}
                            icon={"triangle-exclamation"}
                            icon_size={4}
                            icon_color_code={"#fe6a68"}
                        ></Icon>
                    </div>
                    <div className="modal-title" style={{ paddingBottom: "5px", fontSize: "18px" }}>
                        {message}
                    </div>
                    <div className="button-container-center">
                        <div onClick={() => { setModalState({ modalIsOpen: false }) }} className="button-red button-100">Cancel</div>
                        <div onClick={() => { deleteEntry() }} className="button-blue button-100">Yes</div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default DeleteModal;