import React, { useEffect, useState } from "react";
import axios from "axios";
import getCookie from '../auth/Cookie';
var csrftoken = getCookie('csrftoken');


const EmailConfirm = () => {
    const [state, setState] = useState({
        display: false

    })
    useEffect(() => {
        const website_url = window.location.href
        axios({
            headers: {
                'X-CSRFToken': csrftoken
            },
            method: 'post',
            url: process.env.API_URL + "api/confirm-email/",
            data: {
                activation_url: website_url
            }
        })
            .then(function (response) {
                localStorage.clear()
                setState({ display: false })
                if (response && response.data && response.data.auth_token) {
                    localStorage.setItem("auth_token", response.data.auth_token)
                    window.location.replace('/app/login/');
                }

            })
            .catch(function (response) {
                setState({ display: true })
            })


    }, [])

    return (
        <>
            {state && state.display ?
                <div className="no-match">
                    <h1>OH NO!</h1>
                    <h2>Something went wrong</h2>
                    <div>
                        We regret causing trouble, Probably, the page is lost in space and can't find its way home.
                        But you can try again by checking the URL You may also go back to our homepage
                    </div>
                    <div>But you can try again by checking the URL. You may also go back to our homepage</div>
                    <div className="a-button"><a href="/app/">Back to homepage</a></div>
                </div>
                : null}
        </>
    )
}
export default EmailConfirm;