import React, { useState, useEffect } from "react";
import SocialMediaPolicyModal from "../web/SocialMediaPolicyModal";
import Icon from "../general/Icon"
import axios from "axios"
import getCookie from '../auth/Cookie';
import LazyLoadScreen from "../general/LazyLoadScreen";
import ErrorMessage from "../web/ErrorMessage";
import Image from "../../files/favicon.png";
import Image_placholder from "../../files/ugcbird-image-1.jpg";
import Video from "../../files/SnapTik.ltd_1705946584.mp4";
import FileUploadImage from "../../files/fileupload-UGCBird.png";

var csrftoken = getCookie('csrftoken');
type IErrors = {
    errors?: Array<string>;
}
const SendUGCForm = () => {
    const [sendUGCFormState, setSendUGCFormState] = useState({

    })
    const [campaignState, setCampaignState] = useState({

    })
    const [modalState, setModalState] = useState({
        modalIsOpen: false
    })

    const [formState, setFormState] = useState({
        step: 1

    })
    const [errorState, setErrorState] = useState<IErrors>({
        errors: []
    });
    let view = null

    const FieldListener = (type, event) => {
        event.persist();
        setSendUGCFormState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }
    const fileUploadListener = (event: any) => {
        const file_obj = event.target.files[0]

        const video_obj = {
            obj: "",
            url: window.URL.createObjectURL(file_obj),
            new: true

        }
        const new_file = Object.assign(file_obj, video_obj)
        if (new_file.size < 25000000) {
            setSendUGCFormState(oldstate => ({ ...oldstate, video: new_file }))
        } else {
            setErrorState({
                "errors": ["Sorry, that file is too big"]
            })
        }
    };

    const fileImageUploadListener = (event: any) => {
        const file_obj = event.target.files[0]

        const image_obj = {
            obj: "",
            url: window.URL.createObjectURL(file_obj),
            new: true

        }
        const new_file = Object.assign(file_obj, image_obj)

        if (new_file.size < 25000000) {
            setSendUGCFormState(oldstate => ({ ...oldstate, image: new_file }))
        } else {
            setErrorState({
                "errors": ["Sorry, that file is too big"]
            })
        }
    };
    const booleanListener = (type) => {
        setSendUGCFormState(oldstate => ({ ...oldstate, [type]: !oldstate[type] }))

    };
    const clearContent = () => {
        {
            campaignState && campaignState.video_or_image === "video" ?
                setSendUGCFormState(oldstate => ({ ...oldstate, video: null }))
                :
                setSendUGCFormState(oldstate => ({ ...oldstate, image: null }))
        }
    }

    const submitContent = () => {
        if (campaignState.status === "active") {
            axios({
                headers: {
                    'Authorization': localStorage.auth_token,
                    'X-CSRFToken': csrftoken,
                    "Content-Type": "multipart/form-data"
                },
                method: 'post',
                url: process.env.API_URL + "api/ugc/",
                data: {
                    method: "create",
                    can_use_promotion: sendUGCFormState.can_use_promotion || false,
                    can_recieve_email: sendUGCFormState.can_recieve_email || false,
                    older_than_18: sendUGCFormState.older_than_18 || false,
                    email: sendUGCFormState.email,
                    name: sendUGCFormState.name,
                    tiktok: sendUGCFormState.tiktok,
                    instagram: sendUGCFormState.instagram,
                    facebook: sendUGCFormState.facebook,
                    website: sendUGCFormState.website,
                    video: sendUGCFormState.video,
                    image: sendUGCFormState.image,
                    campaign: campaignState.id,
                    platform: campaignState.platform

                }
            })
                .then(function (response) {
                    setFormState({ "step": 3 })
                })
                .catch(function (error) {
                    setErrorState({
                        "errors": error.response.data.message
                    })
                });
        }
    }
    const contentType = () => {
        if (campaignState.content_type == "unboxing_video") {
            return "Unboxing video"
        } else if (campaignState.content_type == "how_to_video") {
            return "How to video"
        } else if (campaignState.content_type == "product_review") {
            return "product Review"
        } else if (campaignState.content_type == "product_demo") {
            return "Product Demo"

        } else if (campaignState.content_type == "picture_with_product") {
            return "Picture with product"
        } else if (campaignState.content_type == "lifestyle_shot") {
            return "Lifestyle shot"

        } else if (campaignState.content_type == "picture_of_product") {
            return "Picture of the product"
        } else {
            return "-"
        }

    }
    useEffect(() => {
        let visit_url = window.location.href
        const regex = /(?<=[a-z])\d+/g;
        let campaign_id = visit_url.match(regex)[0]
        let frontend = null
        if (!window.location.href.includes("preview")) {
            frontend = "True"
        } else {
            frontend = "False"
        };
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken,
                'frontend': frontend

            },
            method: 'get',
            url: process.env.API_URL + `api/campaign/${campaign_id}/`,
        })
            .then(function (response) {
                if (response.data.status === "draft" && !window.location.href.includes("preview")) {
                    window.location.href = "/app/not-found/"
                }
                setCampaignState(response.data)

            })
            .catch(function (error) {
                console.log(error)
            });
    }, [])
    const displayErrors = () => {
        if (errorState.errors.length > 0) {
            return <ErrorMessage errors={errorState.errors} setErrorState={setErrorState}></ErrorMessage>;
        } else {
            return null;
        }
    };
    switch (formState.step) {
        case 1:
            view = <>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="sendugcform--card card">
                            <div className="sendugcform--card-header">
                                <div className="row">
                                    {/* <div className="col-6 left-image-con">
                                        {campaignState && campaignState.brand && campaignState.brand.logo ?
                                            <div className=''>
                                                <span className="sendugcform--logo-image">
                                                    <img src={`${process.env.API_URL}${campaignState.brand.logo.substr(1)}`}></img>
                                                </span>
                                            </div>
                                            : null}
 
                                    </div>
                                    <div className="col-6 right-image-con">
                                        <div className='card-user-image' ><img className='circle-image sendugcform--image-header' src={Image}></img></div>
                                    </div> */}
                                    <div style={{ fontSize: "30px", fontWeight: 600 }}>
                                        {campaignState.title}
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="sendugcform--card-body">
                                {/* <div className="sendugcform-input-container">
                                    <div className="sendugcform-input-title">
                                        Campaign
                                    </div>
                                    <div className="sendugcform-input-value">
                                        <div className="sendugcform-input-value-icon">
                                            {campaignState && campaignState.video_or_image === "video" ?
                                                <Icon
                                                    type={"fa"}
                                                    icon={"video"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                                : <Icon
                                                    type={"fa"}
                                                    icon={"camera"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>}
                                        </div>
                                        <div className="sendugcform-input-value-text">
                                            {campaignState.title}
                                        </div>
                                    </div>
                                </div> */}
                                <div className="sendugcform-reward">
                                    <div className="sendugcform-input-title">
                                        Reward
                                    </div>
                                    <div className="sendugcform-reward-container">
                                        <div className="sendugcform-reward-icon">
                                            <Icon
                                                type={"fa"}
                                                icon={"gift"}
                                                icon_size={1}
                                                icon_color_code={"#ffffff"}
                                            ></Icon>
                                        </div>
                                        <div className="sendugcform-reward-text">
                                            {campaignState.reward}
                                        </div>
                                    </div>
                                </div>
                                <div className="sendugcform-requirements">
                                    <div className="sendugcform-input-title">
                                        Requirements
                                    </div>
                                    <div className="sendugcform-requirement--container">
                                        <div className="sendugcform-requirement">
                                            <div className="sendugcform-requirement-icon">
                                                {campaignState && campaignState.video_or_image === "video" ?
                                                    <Icon
                                                        type={"fa"}
                                                        icon={"video"}
                                                        icon_size={1}
                                                        icon_color_code={"#4f4f4f"}
                                                    ></Icon>
                                                    : <Icon
                                                        type={"fa"}
                                                        icon={"image"}
                                                        icon_size={1}
                                                        icon_color_code={"#4f4f4f"}
                                                    ></Icon>}
                                            </div>
                                            <div className="sendugcform-requirement-text">
                                                {contentType()}
                                            </div>
                                        </div>
                                        <div className="sendugcform-requirement">
                                            <div className="sendugcform-requirement-icon">

                                                {campaignState && campaignState.video_or_image === "video" ?
                                                    <Icon
                                                        type={"fa"}
                                                        icon={"stopwatch"}
                                                        icon_size={1}
                                                        icon_color_code={"#4f4f4f"}
                                                    ></Icon>
                                                    : <Icon
                                                        type={"fa"}
                                                        icon={"file-image"}
                                                        icon_size={1}
                                                        icon_color_code={"#4f4f4f"}
                                                    ></Icon>}
                                            </div>
                                            <div className="sendugcform-requirement-text">
                                                {campaignState && campaignState.video_or_image === "video" ?
                                                    <>Video should be atleast {campaignState.video_length} seconds</>
                                                    : <>Content format {campaignState.content_format}</>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sendugcform-description">
                                    <div className="sendugcform-input-title">
                                        Description
                                    </div>
                                    <div className="sendugcform-input-description">
                                        {campaignState.text}
                                    </div>
                                </div>
                                <div className="sendugcform-button-container">
                                    <div onClick={() => (setModalState({ "modalIsOpen": true }))} className="sendugcform-button sendugcform-button-light">
                                        Social media policy
                                    </div>
                                    <div onClick={() => (setFormState({ "step": 2 }))} className="sendugcform-button sendugcform-button-dark">
                                        Get started now
                                    </div>
                                </div>

                            </div>
                            <div className="sendugcform--card-footer">

                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sendugcform-video--container" style={{ justifyContent: "center" }}>
                        {campaignState && campaignState.video_or_image === "video" ?
                            <>
                                {campaignState && campaignState.video ?
                                    <div className="brand-media">
                                        <video
                                            controls
                                            loop
                                            muted={false}
                                            autoPlay={false}
                                            className="video"
                                            poster={sendUGCFormState.thumbnail}
                                        >
                                            <source src={`${process.env.API_URL}${campaignState.video.substr(1)}`} type="video/mp4" />
                                        </video>
                                    </div>
                                    : <div className="brand-media">    <video
                                        controls
                                        loop
                                        muted={false}
                                        autoPlay={false}
                                        className="video"
                                        poster={sendUGCFormState.thumbnail}
                                    >
                                        <source src={Video} type="video/mp4" />

                                    </video></div>}
                            </> : <>
                                {campaignState && campaignState.image ?
                                    <div className="brand-media">
                                        <img className='sendugcform--image' src={`${process.env.API_URL}${campaignState.image.substr(1)}`}></img>
                                    </div>
                                    : <div className="brand-media">   <img className='sendugcform--image' src={Image_placholder}></img></div>}
                            </>
                        }
                    </div>
                </div>


            </>
            break;
        case 2:
            view = <>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="sendugcform--card card">
                            <div className="sendugcform--card-header">
                                {/* <div className="row">
                                    <div className="col-6 left-image-con">
                                        {campaignState && campaignState.brand && campaignState.brand.logo ?
                                            <div className=''>
                                                <span className="sendugcform--logo-image">
                                                    <img src={`${process.env.API_URL}${campaignState.brand.logo.substr(1)}`}></img>
                                                </span>
                                            </div>
                                            : null}
                            
                                    </div>
                                    <div className="col-6 right-image-con">
                                        <div className='card-user-image' ><img className='circle-image sendugcform--image-header' src={Image}></img></div>
                                    </div>
                                </div> */}
                                {/* <div className="row">
                                    <div style={{ fontSize: "30px" }}>
                                        {campaignState.title}
                                    </div>
                                </div> */}
                            </div>
                            {/* <hr></hr> */}
                            <div className="sendugcform--card-body">
                                <div className="sendugcform-input-container">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div className="sendugcform-input-title">
                                                Email
                                            </div>
                                            <div className="">
                                                <div className="sendugcform-input-value-text no-padding">
                                                    <div className="input-group mb-3">
                                                        <div className="sendugcform-input-icon-container">
                                                            <span className="" id="basic-addon1"><i style={{ color: "#333333" }} className={` fa-solid fa-envelope fa-1x `}></i></span>
                                                        </div>
                                                        <input placeholder="Enter your email" onChange={(e) => FieldListener("email", e)} name={"email"} value={sendUGCFormState.email || ""} className="form-control sendugcform-input" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div className="sendugcform-input-title">
                                                Name
                                            </div>
                                            <div className="">
                                                <div className="sendugcform-input-value-text no-padding">
                                                    <div className="input-group mb-3">
                                                        <div className="sendugcform-input-icon-container">
                                                            <span className="" id="basic-addon1"><i style={{ color: "#333333" }} className={` fa fa-user fa-1x `}></i></span>
                                                        </div>
                                                        <input placeholder="Enter your Name" onChange={(e) => FieldListener("name", e)} name={"name"} value={sendUGCFormState.name || ""} className="form-control sendugcform-input" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div className="sendugcform-input-title">
                                                Instagram
                                            </div>
                                            <div className="">
                                                <div className="sendugcform-input-value-text no-padding">
                                                    <div className="input-group mb-3">
                                                        <div className="sendugcform-input-icon-container">
                                                            <span className="" id="basic-addon1"><i style={{ color: "#333333" }} className={` fa-brands fa-instagram fa-1x `}></i></span>
                                                        </div>
                                                        <input placeholder="Enter your Instagram" onChange={(e) => FieldListener("instagram", e)} name={"instagram"} value={sendUGCFormState.instagram || ""} className="form-control sendugcform-input" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div className="sendugcform-input-title">
                                                Facebook
                                            </div>
                                            <div className="">
                                                <div className="sendugcform-input-value-text no-padding">
                                                    <div className="input-group mb-3">
                                                        <div className="sendugcform-input-icon-container">
                                                            <span className="" id="basic-addon1"><i style={{ color: "#333333" }} className={` fa-brands fa-facebook fa-1x `}></i></span>
                                                        </div>
                                                        <input placeholder="Enter your Facebook" onChange={(e) => FieldListener("facebook", e)} name={"facebook"} value={sendUGCFormState.facebook || ""} className="form-control sendugcform-input" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div className="sendugcform-input-title">
                                                Website
                                            </div>
                                            <div className="">
                                                <div className="sendugcform-input-value-text no-padding">
                                                    <div className="input-group mb-3">
                                                        <div className="sendugcform-input-icon-container">
                                                            <span className="" id="basic-addon1"><i style={{ color: "#333333" }} className={` fa fa-home fa-1x `}></i></span>
                                                        </div>
                                                        <input placeholder="Enter your portfolio" onChange={(e) => FieldListener("website", e)} name={"website"} value={sendUGCFormState.website || ""} className="form-control sendugcform-input" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div className="sendugcform-input-title">
                                                TikTok
                                            </div>
                                            <div className="">
                                                <div className="sendugcform-input-value-text no-padding">
                                                    <div className="input-group mb-3">
                                                        <div className="sendugcform-input-icon-container">
                                                            <span className="" id="basic-addon1"><i style={{ color: "#333333" }} className={` fa-brands fa-tiktok fa-1x `}></i></span>
                                                        </div>
                                                        <input placeholder="Enter your Tiktok" onChange={(e) => FieldListener("tiktok", e)} name={"tiktok"} value={sendUGCFormState.tiktok || ""} className="form-control sendugcform-input" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(sendUGCFormState.video && sendUGCFormState.video.url) || (sendUGCFormState.image && sendUGCFormState.image.url) ?
                                    <div id="clear" className="clear-button margin-bottom" onClick={clearContent}>
                                        Clear
                                    </div>
                                    : <div className="sendugcform-fileupload">
                                        <div className="">
                                            <div className="media-body ml-4">
                                                <div className="sendugcform-input-title">
                                                    {campaignState && campaignState.video_or_image === "video" ? <>Upload UGC Video</> : <>Upload UGC Image</>}
                                                </div>
                                                <div className="">
                                                    {campaignState && campaignState.video_or_image === "video" ?
                                                        <input accept=".mp4" id="file" onChange={(e) => fileUploadListener(e)} className="inputfile" type={"file"} name={"video"} aria-label={"video"} >

                                                        </input> :
                                                        <input accept=".jpg, .png, .jpeg" id="file" onChange={(e) => fileImageUploadListener(e)} className="inputfile" type={"file"} name={"image"} aria-label={"image"} ></input>}
                                                    <label className="sendugcform-video-form" htmlFor="file">
                                                        <img src={FileUploadImage}></img>
                                                        <div className="sendugcform-video-form--button">
                                                            Browse file
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                }
                                <div className="">
                                    {displayErrors()}
                                </div>
                                <div className="sendugcform-checkboxes">
                                    <div className="sendugcform-checkbox">
                                        <label className="container">
                                            {campaignState && campaignState.video_or_image === "video" ?
                                                <div className="checkbox--text">Can use my video for social marketing purpose</div>
                                                : <div className="checkbox--text">Can use my image for social marketing purpose</div>}
                                            <input type="checkbox" onClick={() => { booleanListener("can_use_promotion") }}></input>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="sendugcform-checkbox">
                                        <label className="container">
                                            <div className="checkbox--text">Can recieve promotion emails</div>
                                            <input type="checkbox" onClick={() => { booleanListener("can_recieve_email") }}></input>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="sendugcform-checkbox">
                                        <label className="container">
                                            <div className="checkbox--text">I'm older than 18 years old or i got premission from a adult</div>
                                            <input type="checkbox" onClick={() => { booleanListener("older_than_18") }}></input>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>


                                <div className="sendugcform-description">
                                    <div className="sendugcform-tos" style={{ textAlign: "center" }}>
                                        I am the sole right holder of the content and I’m over 18 years old and I accept the <a className="purple-link" href="https://www.ugcbird.com/tos/" target="_blank">Terms & Conditions</a> </div>
                                </div>
                                <div className="sendugcform-button-container">
                                    <div onClick={() => setFormState({ "step": 1 })} className="sendugcform-button sendugcform-button-light">
                                        Back
                                    </div>
                                    {campaignState && campaignState.video_or_image === "video" ? <>
                                        {sendUGCFormState && sendUGCFormState.email && sendUGCFormState.older_than_18 && sendUGCFormState.video ?
                                            <div onClick={() => submitContent()} className="sendugcform-button sendugcform-button-dark">
                                                Submit Video
                                            </div>
                                            : <div className="sendugcform-button sendugcform-button-grey">
                                                Submit Video
                                            </div>
                                        }
                                    </> : <>
                                        {sendUGCFormState && sendUGCFormState.email && sendUGCFormState.can_use_promotion && sendUGCFormState.older_than_18 && sendUGCFormState.image ?
                                            <div onClick={() => submitContent()} className="sendugcform-button sendugcform-button-dark">
                                                Submit Image
                                            </div>
                                            : <div className="sendugcform-button sendugcform-button-grey">
                                                Submit Image
                                            </div>
                                        }
                                    </>}
                                </div>


                            </div>
                            <div className="sendugcform--card-footer">

                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sendugcform-video--container" style={{ justifyContent: "center" }}>
                        {campaignState && campaignState.video_or_image === "video" ?
                            <>
                                {sendUGCFormState && sendUGCFormState.video ?
                                    <div className="brand-media">
                                        <video
                                            controls
                                            loop
                                            muted={false}
                                            autoPlay={false}
                                            className="video"
                                            poster={sendUGCFormState.thumbnail}
                                        >
                                            <source src={sendUGCFormState.video.url} type="video/mp4" />
                                        </video>
                                    </div>
                                    : <div className="brand-media">    <video
                                        controls
                                        loop
                                        muted={false}
                                        autoPlay={false}
                                        className="video"
                                        poster={sendUGCFormState.thumbnail}
                                    >
                                        {campaignState && campaignState.video ?
                                            <source src={`${process.env.API_URL}${campaignState.video.substr(1)}`} type="video/mp4" />
                                            : null}

                                    </video></div>}
                            </> : <>
                                {sendUGCFormState && sendUGCFormState.image ?
                                    <div className="brand-media">
                                        <img className='sendugcform--image' src={sendUGCFormState.image.url}></img>
                                    </div>
                                    : <div className="brand-media">
                                        {campaignState && campaignState.image ?
                                            <img className='sendugcform--image' src={`${process.env.API_URL}${campaignState.image.substr(1)}`}></img>

                                            : null}

                                    </div>}
                            </>
                        }
                    </div>
                </div>


            </>
            break;
        case 3:
            view = <div className="sendugcform-thankyou">
                {campaignState && campaignState.brand && campaignState.brand.logo ?
                    <div className='sendugcform-thankyou--logo logo-image'>
                        <span className="app-brand-logo ">
                            <img src={`${process.env.API_URL}${campaignState.brand.logo.substr(1)}`}></img>
                        </span>
                    </div>
                    : null}
                <div className="sendugcform-thankyou--title ralyway-bold">
                    Thank you!
                </div>
                <div className="sendugcform-thankyou--text">
                    Your video was uploaded successfully.
                    Thanks for your collaboration.
                </div>
            </div>
            break;
    }
    return (
        <>
            {campaignState && campaignState.title ?
                <div className="row">
                    <div className={`${modalState && modalState.modalIsOpen ? "policy-big" : "not-display"}`}>
                        {modalState && modalState.modalIsOpen ? <SocialMediaPolicyModal text={sendUGCFormState.social_media_policy || campaignState.social_media_policy} setModalState={setModalState}></SocialMediaPolicyModal> : null}
                    </div>
                    <div className={`${modalState && modalState.modalIsOpen ? "col-12 no-click" : "col-12"}`} style={{ margin: "auto", marginTop: "50px" }}>
                        <div className="container">
                            {window.location.href.includes("preview") ?
                                <div style={{ marginBottom: "20px" }}>
                                    <a className="" href="/app/campaign/" style={{ marginBottom: "20px" }}>
                                        <span className="">
                                            <Icon
                                                type={"fa"}
                                                icon={"chevron-left"}
                                                icon_size={1}
                                                icon_color_code={"#e3def9"}
                                            ></Icon></span>
                                        Back
                                    </a>
                                </div>
                                : null}
                            {view}
                            <div className="ugcform-footer" style={{ marginTop: "15px" }}>
                                <a href="https://www.ugcbird.com/" target="_blank" style={{ color: "#E3DEF9" }}>
                                    Powered By UGCBird
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                : <LazyLoadScreen></LazyLoadScreen>}
        </>
    )
}
export default SendUGCForm;