import React from "react";

const NoMatchRoute = () => {

    return (
        <div className="no-match">
            <h1>OH NO!</h1>
            <h2>Something went wrong</h2>
            <div>
                We regret causing trouble, Probably, the page is lost in space and can't find its way home.
                But you can try again by checking the URL You may also go back to our homepage
            </div>
            <div>But you can try again by checking the URL. You may also go back to our homepage</div>
            <div className="a-button"><a href="/app/">Back to homepage</a></div>
        </div>
    )
}

export default NoMatchRoute;