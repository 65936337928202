import React from "react";
import logo from '../../files/ugcturning.gif';

const LazyLoadScreen = () => {
    return (
        <div className="loading-container">
            <img src={logo} className="loading_screen" alt="loading..." />
        </div>
    )
}
export default LazyLoadScreen;