import React from "react";
import Icon from "../general/Icon";
const HitLimitModal = ({setModalState, message }) => {
    return (
        <>
            <div className='modal-background'>
                <div className='card generalcard'>
                    <div className="container-right-icon">
                        <div onClick={() => { setModalState({ modalIsOpen: false }) }} className="right-icon">
                            <Icon
                                type={"fa"}
                                icon={"xmark"}
                                icon_size={2}

                                icon_color_code={"#6d6f71"}
                            ></Icon>
                        </div>
                    </div>

                    <div className="center-icon">
                        <Icon
                            type={"fa"}
                            icon={"face-frown"}
                            icon_size={4}
                            icon_color_code={"#fe6a68"}
                        ></Icon>
                    </div>
                    <div className="modal-title" style={{ paddingBottom: "15px", fontSize: "30px" }}>
                        {message.title}
                    </div>
                    <div className="" style={{textAlign: "center"}}>{message.text}</div>
                    <div className="" style={{textAlign: "center", paddingBottom: "0px"}}>{message.text_2}</div>
                    <div className="button-container-center">
                        <div onClick={() => {window.location.href ="/app/settings/" }} className="button-blue button-100" style={{marginBottom: "20px"}}>Upgrade</div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default HitLimitModal;