import React, { useState, useEffect } from "react";
import Input from "../web/Input";
import Select from "./Select";
import axios from "axios";
import getCookie from '../auth/Cookie';
import { useOutletContext } from "react-router-dom";
var csrftoken = getCookie('csrftoken');
const ChangeBillingModal = ({ settingsState }) => {
    const context = useOutletContext();
    const [state, setState] = useState({
        email: null,
        country: null,
        city: null,
        countries: null
    })

    const FieldListener = (type, event) => {
        event.persist();
        setState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }

    const changeBilling = () => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: "post",
            url: `${process.env.API_URL}api/settings/`,
            data: {
                method: "update",
                type: "billing",
                name: state.name,
                email: state.email,
                country: state.country,
                vat_number: state.vat_number,
                address: state.address,
                city: state.city,
                zipcode: state.zipcode
            }
        })
            .then(function (response) {
                context.setModalState({ modalIsOpen: false, type: null })
            })
    }

    useEffect(() => {
        if (settingsState && settingsState.countries && settingsState.billing) {
            setState(oldstate => (
                {
                    ...oldstate,
                    "countries": settingsState.countries,
                    "country": settingsState.country,
                    "name": settingsState.billing.name,
                    "email": settingsState.billing.email

                }
            ))
        }

    }, [])
    return (
        <>
            <div className='modal-background'>
                <div className='card generalcard billingcard'>
                    <div className="modal-title" style={{ paddingBottom: "5px" }}>
                        Edit Address
                    </div>
                    <div style={{ textAlign: "center" }}>Edit your current address</div>
                    <Input
                        onChange={(e) => FieldListener("name", e)}
                        type="text"
                        label="Billing Name"
                        name="name"
                        value={state.name || ''}
                    />
                    <Input
                        onChange={(e) => FieldListener("vat_number", e)}
                        type="text"
                        label="Vat id"
                        name="vat_number"
                        value={state.vat_number || ''}
                    />
                    <Input
                        onChange={(e) => FieldListener("address", e)}
                        type="text"
                        label="Billing Address"
                        name="address"
                        value={state.address || ''}
                    />
                    <Input
                        onChange={(e) => FieldListener("city", e)}
                        type="text"
                        label="City"
                        name="city"
                        value={state.city || ''}
                    />
                    <Input
                        onChange={(e) => FieldListener("zipcode", e)}
                        type="text"
                        label="Zipcode"
                        name="zipcode"
                        value={state.zipcode || ''}
                    />
                    <div className="button-container-center">
                        <div onClick={() => { context.setModalState({ modalIsOpen: false }) }} className="button-red button-100">Cancel</div>
                        <div onClick={() => changeBilling()} className="button-blue button-100">Submit</div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default ChangeBillingModal;