import React, { useState, useEffect } from "react";
import Image from "../../files/favicon.png";
import Icon from "../general/Icon";
import axios from "axios"
import getCookie from '../auth/Cookie';
var csrftoken = getCookie('csrftoken');
import LazyLoadScreen from "../general/LazyLoadScreen";
import ErrorMessage from "../web/ErrorMessage";
type IErrors = {
    errors?: Array<string>;
}
const JobUGCForm = () => {
    const [state, setState] = useState({
        step: "view"

    })
    const [errorState, setErrorState] = useState<IErrors>({
        errors: []
    });

    const FieldListener = (type, event) => {
        event.persist();
        setState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }
    const booleanListener = (type) => {
        setState(oldstate => ({ ...oldstate, [type]: !oldstate[type] }))

    };

    useEffect(() => {
        let visit_url = window.location.href
        const regex = /(?<=[a-z])\d+/g;
        let job_id = visit_url.match(regex)
        if (job_id) {
            let frontend = null
            if (!window.location.href.includes("preview")) {
                frontend = "True"
            } else {
                frontend = "False"
            };
            axios({
                headers: {
                    'Authorization': localStorage.auth_token,
                    'X-CSRFToken': csrftoken,
                    'frontend': frontend

                },
                method: 'get',
                url: process.env.API_URL + `api/job/${job_id[0]}/`,
            })
                .then(function (response) {
                    if (response.data.status === "draft" && !window.location.href.includes("preview")) {
                        window.location.href = "/app/not-found/"
                    }
                    setState(response.data)
                    setState(oldstate => ({ ...oldstate, "step": "view" }))

                })
                .catch(function (error) {
                    window.location.href = "/app/not-found/"
                });
        } else {
            window.location.href = "/app/not-found/"
        }
    }, [])
    const submitContent = () => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken,
                "Content-Type": "multipart/form-data"
            },
            method: 'post',
            url: process.env.API_URL + "api/job-application/",
            data: {
                method: "create",
                form: true,
                can_recieve_email: state.can_recieve_email || false,
                older_than_18: state.older_than_18 || false,
                accept_gifted: state.accept_gifted || false,
                accept_barter: state.accept_barter || false,
                reason: state.reason,
                email: state.email,
                website: state.website,
                tiktok: state.tiktok,
                instagram: state.instagram,
                facebook: state.facebook,
                favorite_video: state.favorite_video,
                job: state.id

            }
        })
            .then(function (response) {
                setState({ "step": "thankyou" })
            })
            .catch(function (error) {
                setErrorState({
                    "errors": error.response.data.message
                })
            });

    }

    const contentType = () => {
        if (state.content_type == "unboxing_video") {
            return "Unboxing video"
        } else if (state.content_type == "how_to_video") {
            return "How to video"
        } else if (state.content_type == "product_review") {
            return "product Review"
        } else if (state.content_type == "product_demo") {
            return "Product Demo"

        } else if (state.content_type == "picture_with_product") {
            return "Picture with product"
        } else if (state.content_type == "lifestyle_shot") {
            return "Lifestyle shot"

        } else if (state.content_type == "picture_of_product") {
            return "Picture of the product"
        } else {
            return "-"
        }

    }
    let viewstep = null
    switch (state.step) {
        case "view":
            viewstep =
                <div className="jubugcform__container" style={{ marginTop: "75px" }}>
                    <div className="jobugcform__card">
                        <div className="jobugcform__card--top">
                            <div className="jobugcform__map--title">
                                Job Overview
                            </div>
                        </div>
                        <div className="jobugcform__card--body card">
                            <div className="job__ugcform__overview--top row">
                                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-job-overview" style={{ display: "flex" }}>

                                    {state && state.brand && state.brand.logo ?

                                        <div className="job__ugcform__logo" style={{ paddingRight: "25px" }}>
                                            <div className='card-user-image' ><img className='circle-image sendugcform--image-header' src={`${process.env.API_URL}${state.brand.logo.substr(1)}`}></img></div>
                                        </div>
                                        : null}

                                    <div className="job__ugcform__overview_info">
                                        <div className="">
                                            {state && state.brand && state.brand.name ? state.brand.name : null}
                                        </div>
                                        <div className="job__ugcform__overview_info_job_title">
                                            {state ? state.title : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 apply_now">
                                    <div className="job__ugcform__overview_info_button" onClick={() => { setState(oldstate => ({ ...oldstate, step: "form" })) }} >
                                        Apply now
                                    </div>
                                </div>
                            </div>
                            <div className="job__ugcform__atc__container" style={{ display: "flex" }}>
                                {state && state.brand && state.brand.website ? <div className="job__ugcform__overview_info_button--outline"> <a target="_blank" href={state.brand.website}>View Website</a></div> : null}

                                <div className="job__ugcform__socials">
                                    {state && state.brand && state.brand.tiktok ?
                                        <div className="social--container">
                                            <a href={`https://tiktok.com/@${state.brand.tiktok}/`} target="_blank">
                                                <div className="job__ugcform__socials--icon">
                                                    <Icon
                                                        type={"fa-brands"}
                                                        icon={"tiktok"}
                                                        icon_size={1}
                                                        icon_color_code={"#ffffff"}
                                                    ></Icon></div></a>
                                        </div>
                                        : null}

                                    {state && state.brand && state.brand.instagram ?
                                        <div className="social--container">
                                            <a href={`https://www.instagram.com/${state.brand.instagram}/`} target="_blank">
                                                <div className="job__ugcform__socials--icon">
                                                    <Icon
                                                        type={"fa-brands"}
                                                        icon={"instagram"}
                                                        icon_size={1}
                                                        icon_color_code={"#ffffff"}
                                                    ></Icon></div></a>
                                        </div>
                                        : null}
                                    {state && state.brand && state.brand.facebook ?
                                        <div className="social--container">
                                            <a href={`https://www.facebook.com/${state.brand.facebook}/`} target="_blank">
                                                <div className="job__ugcform__socials--icon">
                                                    <Icon
                                                        type={"fa-brands"}
                                                        icon={"facebook"}
                                                        icon_size={1}
                                                        icon_color_code={"#ffffff"}
                                                    ></Icon></div></a>
                                        </div>
                                        : null}
                                </div>
                                {/* <div className="job__ugcform__overview_end">
                                <div className="calendar">
                                    <Icon
                                        type={"fa-regular"}
                                        icon={"calendar-xmark"}
                                        icon_size={1}
                                        icon_color_code={"#ffffff"}
                                    ></Icon>
                                </div>
                                <div>
                                    <div style={{ fontSize: "14px", fontWeight: "600" }}>Job End</div>
                                    <div style={{ fontSize: "14px" }}>Tue, Mar 5, 2024</div>
                                </div>
                            </div> */}
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="job__ugcform__second_title">About Company</div>
                                <div className="job__ugcform__description">{state.tell_us}</div>
                            </div>
                            <div className="row">
                                <div className="job__ugcform__second_title">Content information</div>
                                <div className="job__ugcform__description row" style={{ display: "flex" }}>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }}>
                                        <div className="job__ugcform__container" style={{ marginRight: "80px" }}>
                                            <div>Type</div>
                                            <div>Media Format</div>
                                            <div>Duration</div>
                                            <div>Price range</div>
                                        </div>
                                        <div className="job__ugcform__container">
                                            {state && state.media_type === "video" ?
                                                <div>  <Icon
                                                    type={"fa-regular"}
                                                    icon={"video"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon> Video</div>
                                                :
                                                <div>  <Icon
                                                    type={"fa-regular"}
                                                    icon={"camera"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon> Camera</div>
                                            }
                                            <div>{state && state.content_format ? state.content_format : null}</div>
                                            <div>{state && state.content_information && state.content_information.min_duration ? state.content_information.min_duration : 0} - {state && state.content_information && state.content_information.max_duration ? state.content_information.max_duration : 0} seconds</div>

                                            {state && state.reward === "paid" ?
                                                <div>{state && state.content_information && state.content_information.min_price ? state.content_information.min_price : 0} - {state && state.content_information && state.content_information.max_price ? state.content_information.max_price : 0}</div>
                                                : <div>Gifted</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }}>
                                        <div className="job__ugcform__container" style={{ marginRight: "80px" }}>
                                            <div>Country</div>
                                            <div>Language</div>
                                            <div>Content type</div>
                                        </div>
                                        <div className="job__ugcform__container">
                                            <div>Netherlands</div>
                                            <div>Dutch</div>
                                            <div>{contentType()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="jobugcform__card" style={{ marginTop: "50px" }}>
                        <div className="jobugcform__card--top">
                            <div className="jobugcform__map--title">
                                Job Details
                            </div>
                        </div>
                        <div className="jobugcform__card--body card" style={{ marginBottom: "75px" }}>
                            <div className="job__ugcform__overview--top">
                                {state && state.image ?
                                    <div className="job__ugcform__logo" style={{ paddingRight: "25px" }}>
                                        <div className='' ><img className='' src={`${process.env.API_URL}${state.image.substr(1)}`}></img></div>
                                    </div>
                                    : null}
                                <div className="job__ugcform__overview_info">
                                    <div className="">
                                        {state && state.product && state.product.product_name ? state.product.product_name : null}
                                    </div>
                                    <div className="job__ugcform__overview_info_job_title">
                                        UGCBird - March 2024
                                    </div>
                                </div>
                            </div>

                            <hr></hr>
                            <div className="row">
                                <div className="job__ugcform__second_title">Briefing</div>
                                <div className="job__ugcform__description">{state && state.briefing ? state.briefing : null}</div>
                            </div>
                            <div className="row">
                                <div className="job__ugcform__second_title">Instructions</div>
                                <div className="row" style={{ marginTop: "15px", margin: "auto", padding: "0px" }}>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-to-do">
                                        <div className="job__ugcform__icon_card" style={{ borderLeftColor: "#16AF92", borderLeftWidth: "5px" }}>
                                            {/* <div className="job__ugcform__positive_icon">
                                            <Icon
                                                type={"fa-solid"}
                                                icon={"check"}
                                                icon_size={1}
                                                icon_color_code={"#ffffff"}
                                            ></Icon>
                                        </div> */}
                                            <div className="job__ugcform__instructions__title">
                                                To Do
                                            </div>
                                            <div className="instructions__list">
                                                {state && state.instructions && state.instructions.do_1 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M6.00241 8.14759L4.6375 9.5125L9.025 13.9L18.775 4.15L17.4101 2.78509L9.025 11.1212L6.00241 8.14759ZM17.8 10C17.8 14.2899 14.2899 17.8 10 17.8C5.71014 17.8 2.2 14.2899 2.2 10C2.2 5.71009 5.71009 2.2 10 2.2C10.7313 2.2 11.4625 2.29759 12.1452 2.49255L13.6562 0.98125C12.5351 0.49375 11.3163 0.25 10 0.25C4.6375 0.25 0.25 4.6375 0.25 10C0.25 15.3625 4.6375 19.75 10 19.75C15.3625 19.75 19.75 15.3625 19.75 10H17.8Z" fill="#16AF92" />
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {state.instructions.do_1}
                                                        </span>
                                                    </div>
                                                    : null}
                                                {state && state.instructions && state.instructions.do_2 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M6.00241 8.14759L4.6375 9.5125L9.025 13.9L18.775 4.15L17.4101 2.78509L9.025 11.1212L6.00241 8.14759ZM17.8 10C17.8 14.2899 14.2899 17.8 10 17.8C5.71014 17.8 2.2 14.2899 2.2 10C2.2 5.71009 5.71009 2.2 10 2.2C10.7313 2.2 11.4625 2.29759 12.1452 2.49255L13.6562 0.98125C12.5351 0.49375 11.3163 0.25 10 0.25C4.6375 0.25 0.25 4.6375 0.25 10C0.25 15.3625 4.6375 19.75 10 19.75C15.3625 19.75 19.75 15.3625 19.75 10H17.8Z" fill="#16AF92" />
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {state.instructions.do_2}
                                                        </span>
                                                    </div>
                                                    : null}
                                                {state && state.instructions && state.instructions.do_3 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M6.00241 8.14759L4.6375 9.5125L9.025 13.9L18.775 4.15L17.4101 2.78509L9.025 11.1212L6.00241 8.14759ZM17.8 10C17.8 14.2899 14.2899 17.8 10 17.8C5.71014 17.8 2.2 14.2899 2.2 10C2.2 5.71009 5.71009 2.2 10 2.2C10.7313 2.2 11.4625 2.29759 12.1452 2.49255L13.6562 0.98125C12.5351 0.49375 11.3163 0.25 10 0.25C4.6375 0.25 0.25 4.6375 0.25 10C0.25 15.3625 4.6375 19.75 10 19.75C15.3625 19.75 19.75 15.3625 19.75 10H17.8Z" fill="#16AF92" />
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {state.instructions.do_3}
                                                        </span>
                                                    </div>
                                                    : null}
                                                {state && state.instructions && state.instructions.do_4 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M6.00241 8.14759L4.6375 9.5125L9.025 13.9L18.775 4.15L17.4101 2.78509L9.025 11.1212L6.00241 8.14759ZM17.8 10C17.8 14.2899 14.2899 17.8 10 17.8C5.71014 17.8 2.2 14.2899 2.2 10C2.2 5.71009 5.71009 2.2 10 2.2C10.7313 2.2 11.4625 2.29759 12.1452 2.49255L13.6562 0.98125C12.5351 0.49375 11.3163 0.25 10 0.25C4.6375 0.25 0.25 4.6375 0.25 10C0.25 15.3625 4.6375 19.75 10 19.75C15.3625 19.75 19.75 15.3625 19.75 10H17.8Z" fill="#16AF92" />
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {state.instructions.do_4}
                                                        </span>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="job__ugcform__icon_card" style={{ borderLeftColor: "#af1616", borderLeftWidth: "5px" }}>
                                            {/* <div className="job__ugcform__negative_icon">
                                        <Icon
                                            type={"fa-solid"}
                                            icon={"check"}
                                            icon_size={1}
                                            icon_color_code={"#ffffff"}
                                        ></Icon>
                                    </div> */}
                                            <div className="job__ugcform__instructions__title_negative">
                                                Dont's
                                            </div>
                                            <div className="instructions__list">
                                                {state && state.instructions && state.instructions.dont_1 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                                <g clipPath="url(#clip0_870_244)">
                                                                    <path d="M9.5 19C4.275 19 0 14.725 0 9.5C0 4.275 4.275 0 9.5 0C14.725 0 19 4.275 19 9.5C19 14.725 14.725 19 9.5 19ZM9.5 1.58333C5.14583 1.58333 1.58333 5.14583 1.58333 9.5C1.58333 13.8542 5.14583 17.4167 9.5 17.4167C13.8542 17.4167 17.4167 13.8542 17.4167 9.5C17.4167 5.14583 13.8542 1.58333 9.5 1.58333Z" fill="#FC5A5A" />
                                                                    <path d="M6.33366 13.4583C6.09616 13.4583 5.93783 13.3791 5.77949 13.2208C5.46283 12.9041 5.46283 12.4291 5.77949 12.1125L12.1128 5.77913C12.4295 5.46246 12.9045 5.46246 13.2212 5.77913C13.5378 6.09579 13.5378 6.57079 13.2212 6.88746L6.88783 13.2208C6.72949 13.3791 6.57116 13.4583 6.33366 13.4583Z" fill="#FC5A5A" />
                                                                    <path d="M12.667 13.4583C12.4295 13.4583 12.2712 13.3791 12.1128 13.2208L5.77949 6.88746C5.46283 6.57079 5.46283 6.09579 5.77949 5.77913C6.09616 5.46246 6.57116 5.46246 6.88783 5.77913L13.2212 12.1125C13.5378 12.4291 13.5378 12.9041 13.2212 13.2208C13.0628 13.3791 12.9045 13.4583 12.667 13.4583Z" fill="#FC5A5A" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_870_244">
                                                                        <rect width="19" height="19" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {state.instructions.dont_1}
                                                        </span>
                                                    </div>
                                                    : null}
                                                {state && state.instructions && state.instructions.dont_2 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                                <g clipPath="url(#clip0_870_244)">
                                                                    <path d="M9.5 19C4.275 19 0 14.725 0 9.5C0 4.275 4.275 0 9.5 0C14.725 0 19 4.275 19 9.5C19 14.725 14.725 19 9.5 19ZM9.5 1.58333C5.14583 1.58333 1.58333 5.14583 1.58333 9.5C1.58333 13.8542 5.14583 17.4167 9.5 17.4167C13.8542 17.4167 17.4167 13.8542 17.4167 9.5C17.4167 5.14583 13.8542 1.58333 9.5 1.58333Z" fill="#FC5A5A" />
                                                                    <path d="M6.33366 13.4583C6.09616 13.4583 5.93783 13.3791 5.77949 13.2208C5.46283 12.9041 5.46283 12.4291 5.77949 12.1125L12.1128 5.77913C12.4295 5.46246 12.9045 5.46246 13.2212 5.77913C13.5378 6.09579 13.5378 6.57079 13.2212 6.88746L6.88783 13.2208C6.72949 13.3791 6.57116 13.4583 6.33366 13.4583Z" fill="#FC5A5A" />
                                                                    <path d="M12.667 13.4583C12.4295 13.4583 12.2712 13.3791 12.1128 13.2208L5.77949 6.88746C5.46283 6.57079 5.46283 6.09579 5.77949 5.77913C6.09616 5.46246 6.57116 5.46246 6.88783 5.77913L13.2212 12.1125C13.5378 12.4291 13.5378 12.9041 13.2212 13.2208C13.0628 13.3791 12.9045 13.4583 12.667 13.4583Z" fill="#FC5A5A" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_870_244">
                                                                        <rect width="19" height="19" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {state.instructions.dont_2}
                                                        </span>
                                                    </div>
                                                    : null}
                                                {state && state.instructions && state.instructions.dont_3 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                                <g clipPath="url(#clip0_870_244)">
                                                                    <path d="M9.5 19C4.275 19 0 14.725 0 9.5C0 4.275 4.275 0 9.5 0C14.725 0 19 4.275 19 9.5C19 14.725 14.725 19 9.5 19ZM9.5 1.58333C5.14583 1.58333 1.58333 5.14583 1.58333 9.5C1.58333 13.8542 5.14583 17.4167 9.5 17.4167C13.8542 17.4167 17.4167 13.8542 17.4167 9.5C17.4167 5.14583 13.8542 1.58333 9.5 1.58333Z" fill="#FC5A5A" />
                                                                    <path d="M6.33366 13.4583C6.09616 13.4583 5.93783 13.3791 5.77949 13.2208C5.46283 12.9041 5.46283 12.4291 5.77949 12.1125L12.1128 5.77913C12.4295 5.46246 12.9045 5.46246 13.2212 5.77913C13.5378 6.09579 13.5378 6.57079 13.2212 6.88746L6.88783 13.2208C6.72949 13.3791 6.57116 13.4583 6.33366 13.4583Z" fill="#FC5A5A" />
                                                                    <path d="M12.667 13.4583C12.4295 13.4583 12.2712 13.3791 12.1128 13.2208L5.77949 6.88746C5.46283 6.57079 5.46283 6.09579 5.77949 5.77913C6.09616 5.46246 6.57116 5.46246 6.88783 5.77913L13.2212 12.1125C13.5378 12.4291 13.5378 12.9041 13.2212 13.2208C13.0628 13.3791 12.9045 13.4583 12.667 13.4583Z" fill="#FC5A5A" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_870_244">
                                                                        <rect width="19" height="19" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {state.instructions.dont_3}
                                                        </span>
                                                    </div>
                                                    : null}
                                                {state && state.instructions && state.instructions.dont_4 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                                <g clipPath="url(#clip0_870_244)">
                                                                    <path d="M9.5 19C4.275 19 0 14.725 0 9.5C0 4.275 4.275 0 9.5 0C14.725 0 19 4.275 19 9.5C19 14.725 14.725 19 9.5 19ZM9.5 1.58333C5.14583 1.58333 1.58333 5.14583 1.58333 9.5C1.58333 13.8542 5.14583 17.4167 9.5 17.4167C13.8542 17.4167 17.4167 13.8542 17.4167 9.5C17.4167 5.14583 13.8542 1.58333 9.5 1.58333Z" fill="#FC5A5A" />
                                                                    <path d="M6.33366 13.4583C6.09616 13.4583 5.93783 13.3791 5.77949 13.2208C5.46283 12.9041 5.46283 12.4291 5.77949 12.1125L12.1128 5.77913C12.4295 5.46246 12.9045 5.46246 13.2212 5.77913C13.5378 6.09579 13.5378 6.57079 13.2212 6.88746L6.88783 13.2208C6.72949 13.3791 6.57116 13.4583 6.33366 13.4583Z" fill="#FC5A5A" />
                                                                    <path d="M12.667 13.4583C12.4295 13.4583 12.2712 13.3791 12.1128 13.2208L5.77949 6.88746C5.46283 6.57079 5.46283 6.09579 5.77949 5.77913C6.09616 5.46246 6.57116 5.46246 6.88783 5.77913L13.2212 12.1125C13.5378 12.4291 13.5378 12.9041 13.2212 13.2208C13.0628 13.3791 12.9045 13.4583 12.667 13.4583Z" fill="#FC5A5A" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_870_244">
                                                                        <rect width="19" height="19" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {state.instructions.dont_4}
                                                        </span>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            break;
        case "form":
            viewstep = <div className="jubugcform__container" style={{ marginTop: "75px" }}>
                <div className="jobugcform__card">
                    <div className="jobugcform__card--top">
                        <div className="jobugcform__map--title">
                            Job Form
                        </div>
                    </div>
                    <div className="jobugcform__card--body card" style={{ marginBottom: "75px" }}>


                        <hr></hr>
                        <div className="sendugcform-input-container">
                            <div className="sendugcform-input-title">
                                Email
                            </div>
                            <div className="">
                                <div className="sendugcform-input-value-text no-padding-dashboard">
                                    <div className="input-group mb-3">
                                        <div className="sendugcform-input-icon-container">
                                            <span className="" id="basic-addon1"><i style={{ color: "#333333" }} className={` fa-solid fa-envelope fa-1x `}></i></span>
                                        </div>
                                        <input placeholder="Enter your email" onChange={(e) => FieldListener("email", e)} name={"email"} value={state.email || ""} className="form-control sendugcform-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="sendugcform-input-title">
                                Website ( Portfolio )
                            </div>
                            <div className="">
                                <div className="sendugcform-input-value-text no-padding-dashboard">
                                    <div className="input-group mb-3">
                                        <div className="sendugcform-input-icon-container">
                                            <span className="" id="basic-addon1"><i style={{ color: "#333333" }} className={` fa-regular fa-home fa-1x `}></i></span>
                                        </div>
                                        <input placeholder="Enter your portfolio" onChange={(e) => FieldListener("website", e)} name={"website"} value={state.website || ""} className="form-control sendugcform-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="sendugcform-input-title">
                                Social Username ( TikTok )
                            </div>
                            <div className="">
                                <div className="sendugcform-input-value-text no-padding-dashboard">
                                    <div className="input-group mb-3">
                                        <div className="sendugcform-input-icon-container">
                                            <span className="" id="basic-addon1"><i style={{ color: "#333333" }} className={` fa-brands fa-tiktok fa-1x `}></i></span>
                                        </div>
                                        <input placeholder="Enter your social username" onChange={(e) => FieldListener("tiktok", e)} name={"tiktok"} value={state.tiktok || ""} className="form-control sendugcform-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="sendugcform-input-title">
                                Social Username ( Instagram )
                            </div>
                            <div className="">
                                <div className="sendugcform-input-value-text no-padding-dashboard">
                                    <div className="input-group mb-3">
                                        <div className="sendugcform-input-icon-container">
                                            <span className="" id="basic-addon1"><i style={{ color: "#333333" }} className={` fa-brands fa-instagram fa-1x `}></i></span>
                                        </div>
                                        <input placeholder="Enter your social username" onChange={(e) => FieldListener("instagram", e)} name={"instagram"} value={state.instagram || ""} className="form-control sendugcform-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="sendugcform-input-title">
                                Social Username ( Facebook )
                            </div>
                            <div className="">
                                <div className="sendugcform-input-value-text no-padding-dashboard">
                                    <div className="input-group mb-3">
                                        <div className="sendugcform-input-icon-container">
                                            <span className="" id="basic-addon1"><i style={{ color: "#333333" }} className={` fa-brands fa-facebook fa-1x `}></i></span>
                                        </div>
                                        <input placeholder="Enter your social username" onChange={(e) => FieldListener("facebook", e)} name={"facebook"} value={state.facebook || ""} className="form-control sendugcform-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="sendugcform-input-title">
                                Why should we work with you?
                            </div>
                            <div className="">
                                <div className="sendugcform-input-value-text no-padding-dashboard">
                                    <div className="input-group mb-3">

                                        <textarea placeholder="Tell us more about yourself" onChange={(e) => FieldListener("reason", e)} name={"reason"} value={state.reason || ""} className="form-control sendugcform-input" style={{ borderLeft: "2px solid #E5E5E5", height: "200px" }} />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="sendugcform-checkboxes">
                            <div className="sendugcform-checkbox">
                                <label className="container">
                                    <div className="checkbox--text">I accept barter jobs.</div>
                                    <input type="checkbox" onClick={() => { booleanListener("accept_barter") }}></input>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="sendugcform-checkbox">
                                <label className="container">
                                    <div className="checkbox--text">I accepts gifted jobs</div>
                                    <input type="checkbox" onClick={() => { booleanListener("accept_gifted") }}></input>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="sendugcform-checkbox">
                                <label className="container">
                                    <div className="checkbox--text">Can receive emails.</div>
                                    <input type="checkbox" onClick={() => { booleanListener("can_recieve_email") }}></input>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="sendugcform-checkbox">
                                <label className="container">
                                    <div className="checkbox--text">I am older than 18 years old, or I have permission from an adult</div>
                                    <input type="checkbox" onClick={() => { booleanListener("older_than_18") }}></input>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>


                        <div className="sendugcform-description">
                            <div className="sendugcform-tos" style={{ textAlign: "center" }}>
                                I am the sole rights holder of the content, I am over 18 years old, and I accept the terms. <a className="purple-link" href="https://www.ugcbird.com/tos/" target="_blank">Terms & Conditions</a> </div>
                        </div>
                        <div className="sendugcform-button-container">
                            <div onClick={() => setState(oldstate => ({ ...oldstate, "step": "view" }))} className="sendugcform-button sendugcform-button-light">
                                Back
                            </div>
                            <>

                                {state && state.email && state.older_than_18 ?
                                    <div onClick={() => submitContent()} className="sendugcform-button sendugcform-button-dark">
                                        Apply
                                    </div>
                                    : <div className="sendugcform-button sendugcform-button-grey">
                                        Apply
                                    </div>
                                }
                            </>
                        </div>

                    </div>
                </div>
            </div>
            break;
        case "thankyou":
            viewstep = <div className="sendugcform-thankyou">
                {state && state.brand && state.brand.logo ?
                    <div className='sendugcform-thankyou--logo logo-image'>
                        <span className="app-brand-logo ">
                            <img src={`${process.env.API_URL}${state.brand.logo.substr(1)}`}></img>
                        </span>
                    </div>
                    : null}
                <div className="sendugcform-thankyou--title ralyway-bold">
                    Thank you!
                </div>
                <div className="sendugcform-thankyou--text">
                    We have received your application. The brand will get in contact with you. Thank you for your collaboration.
                </div>
            </div>
            break;
    }

    return (
        <>
            <div className="col-12" style={{ margin: "auto", marginTop: "50px" }}>
                <div className="container">
                    {window.location.href.includes("preview") ?
                        <div style={{ marginBottom: "20px" }}>
                            <a className="" href="/app/job/" style={{ marginBottom: "20px" }}>
                                <span className="">
                                    <Icon
                                        type={"fa"}
                                        icon={"chevron-left"}
                                        icon_size={1}
                                        icon_color_code={"#e3def9"}
                                    ></Icon></span>
                                Back
                            </a>
                        </div>
                        : null}
                    {viewstep}
                    <div className="ugcform-footer row jobugcform-footer">
                        <a href="https://www.ugcbird.com/" target="_blank" style={{ color: "#E3DEF9" }}>
                            Powered By UGCBird
                        </a>
                    </div>
                </div>
            </div>

        </>

    )
}
export default JobUGCForm;
