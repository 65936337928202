import React, { useState, useEffect } from "react"
import Icon from "../general/Icon";
import axios from "axios";
import getCookie from '../auth/Cookie';
var csrftoken = getCookie('csrftoken');
const VideoCarousel = () => {
    const [carouselState, setCarouselState] = useState({
        content: [],
        currentVideo: 0,
        currentVideoUrl: null,
        currentVideoThumbnail: null,
        total: 0
    })



    useEffect(() => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: "get",
            url: `${process.env.API_URL}api/v2/media/`,
        })
            .then(function (response) {
                setCarouselState(oldstate => {
                    let stateData = {
                        ...oldstate,
                        content: response.data.items,
                        currentVideoUrl: response.data.items[0].meta.url,
                        currentVideoThumbnail: response.data.items[0].meta.thumbnail,
                        total: response.data.meta.total_count
                    }
                    return stateData
                })
            })


    }, [])

    useEffect(() => {

    }, [])



    const playVideo = (event) => {
        if (event.target.classList.contains("playing")) {
            event.target.pause()
            event.target.classList.remove("playing")
            if (
                document.querySelector(`[id='${event.target.id}']`) &&
                document.querySelector(`[id='${event.target.id}']`).querySelector("[class='playpause']")
            ) {
                document.querySelector(`[id='${event.target.id}']`).querySelector("[class='playpause']").getElementsByTagName("i")[0].classList.add("fa-circle-play")

            }
        } else {
            event.target.play()
            event.target.classList.add("playing")
            if (
                document.querySelector(`[id='${event.target.id}']`) &&
                document.querySelector(`[id='${event.target.id}']`).querySelector("[class='playpause']")
            ) {
                document.querySelector(`[id='${event.target.id}']`).querySelector("[class='playpause']").getElementsByTagName("i")[0].classList.remove("fa-circle-play")
                document.querySelector(`[id='${event.target.id}']`).querySelector("[class='playpause']").getElementsByTagName("i")[0].classList.remove("fa-circle-pause")
            }
        }
    }

    const playVideoIcon = (id) => {

        if (id === carouselState.currentVideo && document.querySelector(`[id='${id}']`) && document.querySelector(`[id='${id}']`).getElementsByTagName("video") && document.querySelector(`[id='${id}']`).getElementsByTagName("video")[0]) {
            let video = document.querySelector(`[id='${id}']`).getElementsByTagName("video")[0]
            if (video.classList.contains("playing")) {
                video.pause()
                video.classList.remove("playing")
                if (
                    document.querySelector(`[id='${id}']`)
                ) {
                    document.querySelector(`[id='${id}']`).querySelector("[class='playpause']").getElementsByTagName("i")[0].classList.add("fa-circle-play")
                }
            } else {
                video.play()
                video.classList.add("playing")
                if (document.querySelector(`[id='${id}']`)) {
                    document.querySelector(`[id='${id}']`).querySelector("[class='playpause']").getElementsByTagName("i")[0].classList.remove("fa-circle-play")
                    document.querySelector(`[id='${id}']`).querySelector("[class='playpause']").getElementsByTagName("i")[0].classList.remove("fa-circle-pause")
                }
            }
        }
    }

    const updateCarousel = (value) => {
        if (value === "+") {
            setCarouselState(oldstate => ({ ...oldstate, currentVideoUrl: carouselState.content[oldstate.currentVideo + 1].meta.url, currentVideoThumbnail: carouselState.content[oldstate.currentVideo + 1].meta.thumbnail, currentVideo: oldstate.currentVideo + 1 }))
        }

        if (value === "-") {
            setCarouselState(oldstate => ({ ...oldstate, currentVideoUrl: carouselState.content[oldstate.currentVideo - 1].meta.url, currentVideoThumbnail: carouselState.content[oldstate.currentVideo - 1].meta.thumbnail, currentVideo: oldstate.currentVideo - 1 }))
        }
    }
    return (
        <>
            {carouselState && carouselState.content && carouselState.currentVideoUrl ?

                <div className="carousel">
                    <div className="inner-carousel">

                        <div key={carouselState.currentVideo} id={carouselState.currentVideo} className="mobile-video-carousel">
                            <video id={carouselState.currentVideo} onClick={(event) => { playVideo(event) }}
                                className="video"
                                playsInline
                                preload={"auto"}
                                poster={`${carouselState.currentVideoThumbnail}`}
                            >
                                <source src={`${carouselState.currentVideoUrl}`} type="video/mp4" />
                                Your browser does not support the video tags.
                            </video>
                            <div id={carouselState.currentVideo} onClick={() => { playVideoIcon(carouselState.currentVideo) }} className="playpause"> <Icon
                                type={"fa"}
                                icon={"circle-play"}
                                icon_size={5}
                                icon_color_code={"#fff"}
                            ></Icon></div>
                        </div>

                    </div>
                    <div className="button-container-carousel">
                        {carouselState && carouselState.currentVideo > 0 ?

                            <div onClick={() => { updateCarousel("-") }} className="button-carousel button-carousel-left">
                                <Icon
                                    type={"fa"}
                                    icon={"circle-arrow-left"}
                                    icon_size={2}
                                    icon_color_code={"#E3DEF9"}
                                ></Icon>
                            </div> : <div className="button-carousel button-carousel-left">
                                <Icon
                                    type={"fa"}
                                    icon={"circle-arrow-left"}
                                    icon_size={2}
                                    icon_color_code={"#f5f5f5"}
                                ></Icon>
                            </div>}
                        {carouselState && carouselState.currentVideo < 6 ?

                            <div onClick={() => { updateCarousel("+") }} className="button-carousel button-carousel-right">
                                <Icon
                                    type={"fa"}
                                    icon={"circle-arrow-right"}
                                    icon_size={2}
                                    icon_color_code={"#E3DEF9"}
                                ></Icon>
                            </div> : <div className="button-carousel button-carousel-right">
                                <Icon
                                    type={"fa"}
                                    icon={"circle-arrow-right"}
                                    icon_size={2}
                                    icon_color_code={"#f5f5f5"}
                                ></Icon>
                            </div>}
                    </div>
                </div>
                : <div className="carousel-placeholder" style={{ height: "666px" }}></div>}
        </>
    )
}
export default VideoCarousel;