import React, { useEffect } from "react";
import Icon from "../general/Icon";
import FileUpload from "./FileUpload";
import Input from "./Input";
import TextArea from "./TextArea"
import getCookie from '../auth/Cookie';
var csrftoken = getCookie('csrftoken');
import axios from "axios";
const BrandModal = ({ modalState, setModalState, brandState, setBrandState, setSettingsState }) => {

    const FieldListener = (type, event) => {
        event.persist();
        setBrandState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }

    const fileUploadListener = (event: any) => {
        const file_obj = event.target.files[0]

        const image_obj = {
            obj: "",
            url: window.URL.createObjectURL(file_obj),
            new: true

        }
        const new_file = Object.assign(file_obj, image_obj)

        setBrandState(oldstate => ({ ...oldstate, [event.target.name]: new_file }))
        setBrandState(oldstate => ({ ...oldstate, 'logo_update': true }))

    };

    const clearContent = () => {
        setBrandState(oldstate => ({ ...oldstate, "logo": null }))

    }
    const SendApiCall = () => {
        let brand_type = null
        if (modalState.type === "brand_create") {
            brand_type = "create"
        } else if (modalState.type === "brand_update") {
            brand_type = "update"
        }
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken,
                'Content-Type': "multipart/form-data"
            },
            method: 'post',
            data: {
                type: brand_type,
                logo: brandState && brandState.logo_update ? brandState.logo : null,
                name: brandState.name,
                tiktok: brandState.tiktok,
                instagram: brandState.instagram,
                twitter: brandState.twitter,
                website: brandState.website,
                facebook: brandState.facebook,
                id: brandState.id
            },
            url: `${process.env.API_URL}api/brands/`,
        })
            .then(function (response) {
                if (response) {
                    setSettingsState(response.data.data)
                    setModalState({ modalIsOpen: false })
                    setBrandState({ logo: null, name: null, id: null })
                    window.location.href = "/app/settings/"
                }
            })
            .catch(function (response) {
            })


    }

    let view
    switch (modalState.type) {
        case "brand_create":
            view = <>
                <div style={{ fontWeight: "700", fontSize: "20px", marginBottom: "20px" }}>Create Brand</div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <Input
                            onChange={(e) => FieldListener("name", e)}
                            type="text"
                            label="Brand name"
                            name="name"
                            value={brandState.name || ''}
                        />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <Input
                            onChange={(e) => FieldListener("tiktok", e)}
                            type="text"
                            label="TikTok"
                            name="tiktok"
                            value={brandState.tiktok || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <Input
                            onChange={(e) => FieldListener("instagram", e)}
                            type="text"
                            label="Instagram"
                            name="instagram"
                            value={brandState.instagram || ''}
                        />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <Input
                            onChange={(e) => FieldListener("twitter", e)}
                            type="text"
                            label="Twitter"
                            name="twitter"
                            value={brandState.twitter || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

                        <Input
                            onChange={(e) => FieldListener("website", e)}
                            type="text"
                            label="Website"
                            name="website"
                            value={brandState.website || ''}
                        />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <Input
                            onChange={(e) => FieldListener("facebook", e)}
                            type="text"
                            label="Facebook"
                            name="facebook"
                            value={brandState.facebook || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 brand_image_col_6">
                        <div className="full-upload">
                            <FileUpload
                                fileUpload={fileUploadListener}
                                clearContent={clearContent}
                                type="file"
                                name="logo"
                                ariaLabel="logo"
                                image={brandState.logo || null}
                            />
                        </div>
                    </div>
                </div >
                {/* <div className="row">
                    <div className="col-12">
                        <TextArea
                            onChange={(e) => FieldListener("social_media_policy", e)}
                            label="Social media use policy"
                            name="social_media_policy"
                            value={brandState.social_media_policy || ''}
                        />
                    </div>
                </div> */}
                <div className="button-container-center">
                    <div onClick={() => { setModalState({ modalIsOpen: false }), setBrandState({}) }} className="button-red button-100">Cancel</div>
                    {brandState && brandState.logo && brandState.name ?
                        <div onClick={() => { SendApiCall() }} style={{ marginRight: "0px" }} className="button-blue button-100">Create Brand</div>
                        :
                        <div onClick={() => { }} style={{ marginRight: "0px" }} className="button-grey button-100">Create Brand</div>
                    }
                </div>
            </>
            break;

        case "brand_update":
            view = <>
                <div style={{ fontWeight: "700", fontSize: "20px", marginBottom: "20px" }}>Update Brand</div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <Input
                            onChange={(e) => FieldListener("name", e)}
                            type="text"
                            label="Brand name"
                            name="name"
                            value={brandState.name || ''}
                        />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <Input
                            onChange={(e) => FieldListener("tiktok", e)}
                            type="text"
                            label="TikTok"
                            name="tiktok"
                            value={brandState.tiktok || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <Input
                            onChange={(e) => FieldListener("instagram", e)}
                            type="text"
                            label="Instagram"
                            name="instagram"
                            value={brandState.instagram || ''}
                        />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <Input
                            onChange={(e) => FieldListener("twitter", e)}
                            type="text"
                            label="Twitter"
                            name="twitter"
                            value={brandState.twitter || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

                        <Input
                            onChange={(e) => FieldListener("website", e)}
                            type="text"
                            label="Website"
                            name="website"
                            value={brandState.website || ''}
                        />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <Input
                            onChange={(e) => FieldListener("facebook", e)}
                            type="text"
                            label="Facebook"
                            name="facebook"
                            value={brandState.facebook || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 brand_image_col_6">
                        <div className="full-upload">
                            <FileUpload
                                fileUpload={fileUploadListener}
                                clearContent={clearContent}
                                type="file"
                                name="logo"
                                ariaLabel="logo"
                                image={brandState.logo || null}
                            />
                        </div>
                    </div>
                </div >
                {/* <div className="row">
                    <div className="col-12">
                        <TextArea
                            onChange={(e) => FieldListener("social_media_policy", e)}
                            label="Social media use policy"
                            name="social_media_policy"
                            value={brandState.social_media_policy || ''}
                        />
                    </div>
                </div> */}
                <div className="button-container-center">
                    <div onClick={() => { setModalState({ modalIsOpen: false }), setBrandState({ logo: null, name: null, id: null }) }} className="button-red button-100">Cancel</div>
                    {brandState && brandState.logo && brandState.name ?
                        <div onClick={() => { SendApiCall() }} style={{ marginRight: "0px" }} className="button-blue button-100">Update Brand</div>
                        :
                        <div onClick={() => { }} style={{ marginRight: "0px" }} className="button-grey button-100">Update Brand</div>
                    }
                </div>
            </>
            break;

        default:
            view = <>
                <div style={{ fontWeight: "700", fontSize: "20px" }}>Create Brand</div>
                <div className="row">
                    <div className="col-12 brand_image_col_6">
                        <Input
                            onChange={(e) => FieldListener("name", e)}
                            type="text"
                            label="Brand name"
                            name="name"
                            value={brandState.name || ''}
                        />
                        <FileUpload
                            fileUpload={fileUploadListener}
                            clearContent={clearContent}
                            type="file"
                            name="logo"
                            ariaLabel="logo"
                            image={brandState.logo || null}
                        />

                    </div>

                </div >
                <div className="button-container-center">
                    <div onClick={() => { setModalState({ modalIsOpen: false }), setBrandState({}) }} className="button-red button-100">Cancel</div>
                    <div onClick={() => { SendApiCall() }} className="button-blue button-100">{modalState.type} Brand</div>
                </div>
            </>
            break;
    }
    return (
        <>
            <div className='modal-background'>
                <div className='card generalcard'>
                    <div className="container-right-icon">
                        <div onClick={() => { setModalState({ modalIsOpen: false }), setBrandState({ logo: null, name: null, id: null }) }} className="right-icon">
                            <Icon
                                type={"fa"}
                                icon={"xmark"}
                                icon_size={2}

                                icon_color_code={"#6d6f71"}
                            ></Icon>
                        </div>
                    </div>
                    {view}
                </div>
            </div>
        </>
    )
}
export default BrandModal;
