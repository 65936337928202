import React, { useEffect, useState } from "react";
import Icon from "../general/Icon";
const MoveToModal = ({ setModalState, relationState, setRelationState, RelationApi }) => {



    useEffect(() => {

    })


    const youSure = (type) => {
        if (type === "no") {
            setRelationState(oldstate => ({ ...oldstate, display: false, relation: "" }))
            setModalState({
                modalIsOpen: false,
                message: null,
                type: "",
                id: null
            })
        } else if (type === "yes") {
            // setRelationState(oldstate => ({ ...oldstate, "relation": "" }))
            RelationApi()
        }
    }


    return (
        <>
            <div className='modal-background'>
                <div className="job_kanban_menu">
                    <div className="job_kanban_menu--header">
                        Move to
                    </div>
                    <div className="job_kanban_menu-item-list">
                        <div className="job_kanban_menu-item" onClick={() => (

                            setRelationState(oldstate => ({ ...oldstate, display: true, relation: "in_conversation" }))

                            )}>
                            In Conversation
                        </div>
                        <div className="job_kanban_menu-item" onClick={() => (
                            setRelationState(oldstate => ({ ...oldstate, display: true, relation: "accepted" })), setModalState({
                                modalIsOpen: false,
                                message: null,
                                type: "",
                                id: null
                            }))}>
                            Accepted
                        </div>
                        <div className="job_kanban_menu-item" onClick={() => (
                            setRelationState(oldstate => ({ ...oldstate, display: true, relation: "standby" })), setModalState({
                                modalIsOpen: false,
                                message: null,
                                type: "",
                                id: null
                            }))}>
                            Stand By
                        </div>
                        <div className="job_kanban_menu-item--reject" onClick={() => (setModalState({
                            modalIsOpen: false,
                            message: null,
                            type: "",
                            id: null
                        }))}>
                            Delete
                        </div>
                        <div className="job_kanban_menu-item--reject" onClick={() => setModalState({
                            modalIsOpen: false,
                            message: null,
                            type: "",
                            id: null
                        })}>
                            Close
                        </div>
                    </div>
                </div>
                {relationState && relationState.display ?
                    <div className="job_kanban_menu--action">
                        <div className="job_kanban_menu--header" style={{ textAlign: "center" }}>
                            Are you sure?
                        </div>
                        <div className="button-container-center">
                            <div className="button-red button-100" onClick={() => youSure("no")}>No</div>
                            <div className="button-blue button-100" onClick={() => youSure("yes")}>Yes</div>
                        </div>
                    </div>
                    : null}
            </div>
        </>
    )
}
export default MoveToModal;