import React, { FunctionComponent } from "react";


type Props = {
    onChange?: any
    name: any
    value: string
    label: string
    error: string
    placeholder?: string
}

const TextArea: FunctionComponent<Props> = ({ onChange, name, value, label, error, placeholder }) => {
    return (
        <div className="form-group">
            {/* <label className="input-label">{label}</label> */}
            <textarea placeholder={placeholder} onChange={onChange} name={name} value={value} cols={50} rows={10} className="form-control mb-1" />
            {error}
        </div>
    )
}
export default TextArea
