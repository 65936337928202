import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../web/ErrorMessage";
import { Link } from "react-router-dom";
import getCookie from '../auth/Cookie';
import Input from "../web/Input";
import DatePicker from "../web/DatePicker";
import Select from "../web/Select";
import Image from "../../files/ugcbird-long.png";
import Icon from "../general/Icon";
import Video from "../../files/SnapTik.ltd_1705946584.mp4";
import FileUpload from "../web/FileUpload";
var csrftoken = getCookie('csrftoken');
type IErrors = {
    errors?: Array<string>;
}
const CreateAccount = ({ appState, setAppAuth }) => {
    const [registerState, setRegisterState] = useState({
        username: "",
        email: "",
        password: "",
        password_confirm: "",
        country: "AF",
        plan: "free",
        date_of_birth: "1996-05-05"
    })

    const [errorState, setErrorState] = useState<IErrors>({
        errors: []
    });
    const [stepState, setStepState] = useState({
        step: 1
    })
    const navigate = useNavigate()
    const FieldListener = (type, event) => {
        event.persist();
        setRegisterState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });

    }

    const fileUploadListener = (event: any) => {
        const file_obj = event.target.files[0]

        const image_obj = {
            obj: "",
            url: window.URL.createObjectURL(file_obj),
            new: true

        }
        const new_file = Object.assign(file_obj, image_obj)

        setRegisterState(oldstate => ({ ...oldstate, [event.target.name]: new_file }))

    };

    const clearContent = () => {
        setRegisterState(oldstate => ({ ...oldstate, "logo": null }))

    }
    const submit = () => {
        axios({
            headers: {
                'X-CSRFToken': csrftoken,
                'Content-Type': "multipart/form-data"
            },
            method: 'post',
            url: process.env.API_URL + "api/register/",
            data: registerState
        })
            .then(function (response) {
                navigate("/app/register/pre-confirm/")
            })
            .catch(function (error) {
                if (error && error.response && error.response.data && error.response.data.message) {
                    setErrorState({
                        "errors": [error.response.data.message]
                    })
                }
                else {
                    setErrorState({
                        "errors": ["Something went wrong"]
                    })
                }
            });
    }
    useEffect(() => {
        if (appState && appState.isAuth) {
            navigate("/app/")
        }

    }, [appState]);

    const displayErrors = () => {
        if (errorState.errors.length > 0) {
            return <ErrorMessage errors={errorState.errors} setErrorState={setErrorState}></ErrorMessage>;
        } else {
            return null;
        }
    };
    const gender_options = [
        { label: "Select your gender", value: "-" },
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
        { label: "Non-binary", value: "non_binary" },
    ]

    useEffect(() => {
        axios({
            headers: {
                'X-CSRFToken': csrftoken
            },
            method: 'get',
            url: process.env.API_URL + "api/countries/",
        })
            .then(function (response) {
                if (response && response.data && response.data.countries) {
                    setRegisterState(oldstate => ({ ...oldstate, countries: response.data.countries }))
                }

            })
    }, []);

    let view
    switch (stepState.step) {
        case 1:
            view = <>     <div className="Auth-form-content">
                <div className='logo-image'>
                    <span className="app-brand-logo app-brand-logo-app ">
                        <img src={Image}></img>
                    </span>
                </div>
                <div className="registration-steps">
                    <div className="registration-step">
                        <div className="registration-step-icon registration-step-icon-active">
                            <Icon
                                type={"fa"}
                                icon={"home"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                        <div className="registration-step-text">
                            <div className="registration-title">
                                Account
                            </div>
                            <div className="registration-text">
                                Account details
                            </div>
                        </div>
                        <div className="registration-step-arrow">
                            <Icon
                                type={"fa"}
                                icon={"arrow-right"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                    </div>
                    <div className="registration-step">
                        <div className="registration-step-icon ">
                            <Icon
                                type={"fa"}
                                icon={"user"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                        <div className="registration-step-text">
                            <div className="registration-title">
                                Personal
                            </div>
                            <div className="registration-text">
                                Enter information
                            </div>
                        </div>
                        <div className="registration-step-arrow">
                            <Icon
                                type={"fa"}
                                icon={"arrow-right"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                    </div>
                    <div className="registration-step">
                        <div className="registration-step-icon">
                            <Icon
                                type={"fa"}
                                icon={"file-lines"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                        <div className="registration-step-text">
                            <div className="registration-title">
                                Company
                            </div>
                            <div className="registration-text">
                                Company details
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className="auth-registration-title Auth-form-title login-title">Account Information</h3>
                <h6 className="auth-registration-text login-h6 login-title">Enter Your Account Details</h6>
                <div className="row">
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("username", e)}
                            type="text"
                            label="Username"
                            name="username"
                            value={registerState.username || ''}
                        />

                    </div>
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("email", e)}
                            type="email"
                            label="Email"
                            name="email"
                            value={registerState.email || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("password", e)}
                            type="password"
                            label="Password"
                            name="password"
                            value={registerState.password || ''}
                        />

                    </div>
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("password_confirm", e)}
                            type="password"
                            label="Password confirm"
                            name="password_confirm"
                            value={registerState.password_confirm || ''}
                        />
                    </div>
                </div>
                <div className="button-container-center button-auth-container">
                    <div onClick={() => { window.location.href = "/app/login/" }} className="button-grey button-auth">Cancel</div>
                    {registerState && registerState.username && registerState.email.includes("@") && registerState.password && registerState.password_confirm && registerState.password === registerState.password_confirm ?
                        <div onClick={() => (setStepState({ "step": stepState.step + 1 }))} className="button-regular button-auth">Continue</div>
                        : <div onClick={() => ("")} className="button-grey button-auth">Continue</div>}

                </div>
                <div className="login-create-account">
                    <Link to="/app/login/">
                        You have account? Login now
                    </Link>
                </div>
            </div></>
            break;
        case 2:
            view = <>     <div className="Auth-form-content">
                <div className='logo-image'>
                    <span className="app-brand-logo app-brand-logo-app ">
                        <img src={Image}></img>
                    </span>
                </div>
                <div className="registration-steps">
                    <div className="registration-step">
                        <div className="registration-step-icon">
                            <Icon
                                type={"fa"}
                                icon={"home"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                        <div className="registration-step-text">
                            <div className="registration-title">
                                Account
                            </div>
                            <div className="registration-text">
                                Account details
                            </div>
                        </div>
                        <div className="registration-step-arrow">
                            <Icon
                                type={"fa"}
                                icon={"arrow-right"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                    </div>
                    <div className="registration-step">
                        <div className="registration-step-icon registration-step-icon-active">
                            <Icon
                                type={"fa"}
                                icon={"user"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                        <div className="registration-step-text">
                            <div className="registration-title">
                                Personal
                            </div>
                            <div className="registration-text">
                                Enter information
                            </div>
                        </div>
                        <div className="registration-step-arrow">
                            <Icon
                                type={"fa"}
                                icon={"arrow-right"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                    </div>
                    <div className="registration-step">
                        <div className="registration-step-icon">
                            <Icon
                                type={"fa"}
                                icon={"file-lines"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                        <div className="registration-step-text">
                            <div className="registration-title">
                                Company
                            </div>
                            <div className="registration-text">
                                Company details
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className="auth-registration-title Auth-form-title login-title">Peronal Information</h3>
                <h6 className="auth-registration-text login-h6 login-title">Enter Your Peronal information</h6>
                <div className="row">
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("first_name", e)}
                            type="text"
                            label="First name"
                            name="first_name"
                            value={registerState.first_name || ''}
                        />

                    </div>
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("last_name", e)}
                            type="text"
                            label="Last name"
                            name="last_name"
                            value={registerState.last_name || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <DatePicker
                            onChange={(e) => FieldListener("date_of_birth", e)}
                            type="date"
                            label="Date of birth"
                            name="date_of_birth"
                            value={registerState.date_of_birth || '1996-05-05'}
                        />

                    </div>
                    <div className="col-6">
                        <Select
                            onChange={(e) => FieldListener("gender", e)}
                            name="gender"
                            label={"Gender"}
                            options={gender_options || []}
                            selected={registerState.gender || ""}
                            upperLabel={"Gender"}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Select
                            onChange={(e) => FieldListener("country", e)}
                            name="country"
                            label={"Country"}
                            options={registerState.countries || []}
                            selected={registerState.country || ""}
                            upperLabel={"Country"}
                        />

                    </div>
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("state", e)}
                            type="text"
                            label="State"
                            name="state"
                            value={registerState.state || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("address_1", e)}
                            type="text"
                            label="Adress line 1"
                            name="address_1"
                            value={registerState.address_1 || ''}
                        />

                    </div>
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("address_2", e)}
                            type="text"
                            label="address line 2"
                            name="address_2"
                            value={registerState.address_2 || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("city", e)}
                            type="text"
                            label="City"
                            name="city"
                            value={registerState.city || ''}
                        />

                    </div>
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("zipcode", e)}
                            type="text"
                            label="Zipcode"
                            name="zipcode"
                            value={registerState.zipcode || ''}
                        />
                    </div>
                </div>
                <div className="button-container-center button-auth-container">
                    <div onClick={() => (setStepState({ "step": stepState.step - 1 }))} className="button-grey button-auth">Cancel</div>
                    {registerState && registerState.first_name && registerState.last_name && registerState.date_of_birth && registerState.gender && registerState.country && registerState.state && registerState.address_1 && registerState.city && registerState.zipcode ?
                        <div onClick={() => (setStepState({ "step": stepState.step + 1 }))} className="button-regular button-auth">Continue</div>
                        : <div onClick={() => ("")} className="button-grey button-auth">Continue</div>}

                </div>
                <div className="login-create-account">
                    <Link to="/app/login/">
                        You have account? Login now

                    </Link>
                </div>
            </div></>
            break;
        case 3:
            view = <>     <div className="Auth-form-content">
                <div className='logo-image'>
                    <span className="app-brand-logo app-brand-logo-app ">
                        <img src={Image}></img>
                    </span>
                </div>
                <div className="registration-steps">
                    <div className="registration-step">
                        <div className="registration-step-icon">
                            <Icon
                                type={"fa"}
                                icon={"home"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                        <div className="registration-step-text">
                            <div className="registration-title">
                                Account
                            </div>
                            <div className="registration-text">
                                Account details
                            </div>
                        </div>
                        <div className="registration-step-arrow">
                            <Icon
                                type={"fa"}
                                icon={"arrow-right"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                    </div>
                    <div className="registration-step">
                        <div className="registration-step-icon">
                            <Icon
                                type={"fa"}
                                icon={"user"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                        <div className="registration-step-text">
                            <div className="registration-title">
                                Company
                            </div>
                            <div className="registration-text">
                                Company details
                            </div>
                        </div>
                        <div className="registration-step-arrow">
                            <Icon
                                type={"fa"}
                                icon={"arrow-right"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                    </div>
                    <div className="registration-step">
                        <div className="registration-step-icon registration-step-icon-active">
                            <Icon
                                type={"fa"}
                                icon={"file-lines"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                        <div className="registration-step-text">
                            <div className="registration-title">
                                Company
                            </div>
                            <div className="registration-text">
                                Company details
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className="auth-registration-title Auth-form-title login-title">Company Details</h3>
                <h6 className="auth-registration-text login-h6 login-title">Enter Your Company Details</h6>
                <div className="row">
                    <div className="col-12">
                        <Input
                            onChange={(e) => FieldListener("brand_name", e)}
                            type="text"
                            label="Brand name"
                            name="brand_name"
                            value={registerState.brand_name || ''}
                        />

                    </div>
                    {/* <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("vat_number", e)}
                            type="text"
                            label="Vat Number"
                            name="vat_number"
                            value={registerState.vat_number || ''}
                        />
                    </div> */}
                </div>
                <div className="row">
                    <div className="full-upload">
                        <FileUpload
                            fileUpload={fileUploadListener}
                            clearContent={clearContent}
                            type="file"
                            name="logo"
                            ariaLabel="logo"
                            image={registerState.logo || null}
                            inputLabel={"Brand logo"}
                        />
                    </div>
                </div>
                <div className="button-container-center button-auth-container">
                    <div onClick={() => (setStepState({ "step": stepState.step - 1 }))} className="button-grey button-auth">Cancel</div>
                    {registerState && registerState.brand_name && registerState.logo ?
                        <div onClick={() => (submit())} className="button-regular button-auth">Continue</div>
                        : <div onClick={() => ("")} className="button-grey button-auth">Continue</div>}

                </div>
                <div className="login-create-account">
                    <Link to="/app/login/">
                        You have account? Login now

                    </Link>
                </div>

            </div></>
            break;

        default:
            view = <></>
            break;
    }
    return (
        <div className="container login">
            <div className="row login--row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <div className="Auth-form-container">
                        <div className="Auth-form">
                            {view}
                        </div>
                        <br></br>
                        {displayErrors()}
                    </div>

                </div>
                <div className="col-6 img-container login-image-container">
                    <div className="video-mask">
                        <video
                            // autoPlay={true}  
                            // playsInline={true}
                            controls
                            muted={false}
                            autoPlay={false}
                            className="video"
                        >
                            <source src={Video} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CreateAccount;