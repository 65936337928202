import React, { FunctionComponent } from 'react';
import Icon from '../general/Icon';
type ITableHead = {
    columns: any;
    setModalState: number;
    setBrandState: any;

}

const TableRowBrand: FunctionComponent<ITableHead> = ({ columns, setModalState, setBrandState }) => {


    const tableColumn = () => {
        let table_columns = []
        if (columns && columns['logo']) {
            table_columns.push(<td style={{ display: "" }} className="truncate_text"><img style={{ minWidth: "150px", maxWidth: "150px" }} className='' src={`${process.env.API_URL}${columns['logo'].substr(1)}`}></img></td>)
        } else {
            table_columns.push(<td style={{ display: "" }} className="truncate_text"><div className='creator-username'>{columns["name"] || "-"}</div></td>)

        }
        table_columns.push(<td className={`truncate_text`}><div className={`truncate_text`}>
            <ul className='tablerowbrand' style={{ listStyle: "none", paddingLeft: "0px" }}>
                <li style={{ display: "flex" }}>
                    <div style={{ paddingRight: "10px" }}>Active:</div>{columns["active_campaigns"] || 0}
                </li>
                <li style={{ display: "flex" }}>
                    <div>Draft:</div>{columns["draft_campaigns"] || 0}
                </li>
            </ul>
        </div></td>)
        table_columns.push(<td className={`truncate_text`}><div className={`truncate_text`}>
            <ul className='tablerowbrand' style={{ listStyle: "none", paddingLeft: "0px" }}>
                <li style={{ display: "flex" }}>
                    <div style={{ paddingRight: "10px" }}>Open rate: </div>{columns["open_rate"] || 0}
                </li>
                <li style={{ display: "flex" }}>
                    <div style={{ paddingRight: "10px" }}>Sent video rate: </div>{columns["send_video_rate"] || 0}
                </li>
                <li style={{ display: "flex" }}>
                    <div style={{ paddingRight: "10px" }}>Rewarded: </div>{columns["rewarded"] || 0}
                </li>
            </ul>
        </div></td>)

        table_columns.push(
            <td className="truncate_text">
                <div onClick={() => (setModalState({ "modalIsOpen": true, type: "brand_update" }), setBrandState(oldstate => (
                    { ...oldstate, id: columns["id"], name: columns['name'], logo: columns['logo'], twitter: columns['twitter'], instagram: columns['instagram'], tiktok: columns['tiktok'], website: columns['website'], facebook: columns['facebook']  })))} className='truncate_text'>
                    <Icon
                        type={"fa"}
                        icon={"pen-to-square"}
                        icon_size={2}

                        icon_color_code={"#6d6f71"}
                    ></Icon>
                </div>

            </td>
        )


        return table_columns
    }
    return (
        <>
            <tr key={columns["id"]} className='tablerowbrand-tr'>
                {tableColumn()}
            </tr>
        </>
    )
}

export default TableRowBrand;
