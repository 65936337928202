import React, { FunctionComponent } from "react";

const Select = ({ onChange, name, options, selected, label, upperLabel }) => {
    return (
        <div className="mb-3">
            <div className="form-group">
                {upperLabel ?
            <label className="input-label">{upperLabel}</label>: null
            }
                <select placeholder={label} label={label} onChange={onChange} name={name} value={selected} className={`form-select `}>
                    {options.map((option) => <option key={option.value} value={option.value || "null"}>{option.label}</option>)}
            </select>
            </div>
        </div>
    )

}

export default Select;
