import React, { useEffect, useState } from "react"
import Icon from "../general/Icon";
import Input from "../web/Input"
import FileUploadVideo from "../web/FileUploadVideo";
import FileUpload from "../web/FileUpload";
import TextArea from "../web/TextArea";
import Select from "../web/Select";
import axios from "axios";
import getCookie from '../auth/Cookie';
import Video from "../../files/SnapTik.ltd_1705946584.mp4";
import Image from "../../files/favicon.png"
import Image_1 from "../../files/ugcbird-image-1.jpg"

import { Link, useNavigate } from "react-router-dom"

import SocialMediaPolicyModal from "../web/SocialMediaPolicyModal";

var csrftoken = getCookie('csrftoken');
const CampaignEdit = () => {
    const navigate = useNavigate();
    const [campaignEditState, setCampaignEditState] = useState({
        "name": "",
        "brand": null,
        "social_media_use_policy": "",
        "title": "",
        "reward": "",
        "text": "",
    })


    const [modalState, setModalState] = useState({
        modalIsOpen: false,
        id: null
    })

    const [stepState, setStepState] = useState({
        step: 1,
        continue: false
    })

    const [brandsListState, setBrandListState] = useState({

    })

    const [brandsOptionsState, setBrandsOptionsState] = useState({

    })

    const FieldListener = (type, event) => {
        event.persist();
        if (event.target.name === "Brand") {
            let new_brand = null
            for (const brandOption in brandsOptionsState) {
                if (Number(brandsOptionsState[brandOption].id) === Number(event.target.value)) {
                    new_brand = brandsOptionsState[brandOption]
                }
            }
            if (new_brand && event.target.name === "Brand") {
                setCampaignEditState(oldstate => {
                    let state_result = {
                        ...oldstate,
                        brand_id: event.target.value,
                        brand: new_brand

                    };
                    return state_result
                });

            }
        } else {
            setCampaignEditState(oldstate => {
                let state_result = {
                    ...oldstate,
                    [type]: event.target.value,

                };
                return state_result
            });
        }
    }

    const fileUploadListener = (event: any) => {
        const file_obj = event.target.files[0]

        const video_obj = {
            obj: "",
            url: window.URL.createObjectURL(file_obj),
            new: true

        }
        const new_file = Object.assign(file_obj, video_obj)
        setCampaignEditState(oldstate => ({ ...oldstate, video: new_file, new_file: true }))
    };

    const fileImageUploadListener = (event: any) => {
        const file_obj = event.target.files[0]

        const image_obj = {
            obj: "",
            url: window.URL.createObjectURL(file_obj),
            new: true

        }
        const new_file = Object.assign(file_obj, image_obj)
        setCampaignEditState(oldstate => ({ ...oldstate, image: new_file, new_file: true }))

    };


    const clearContent = (type) => {
        if (type === "video") {
            setCampaignEditState(oldstate => ({ ...oldstate, video: null, new_file: false }))
        }
        if (type === "image") {
            setCampaignEditState(oldstate => ({ ...oldstate, image: null, new_file: false }))
        }
    }


    const ObjNum = () => {
        const str = window.location.href
        if (str.split("/")) {
            if (str.endsWith("edit/")) {
                return str.split("/").at(-3)
            }
            else if (str.split("/")) {
                return str.split("/").at(-2)
            }
        }
    }

    const ButtonAction = (type, event) => {
        if (type === "cancel") {
            navigate("/app/")
        }

        if (type === "save") {
            axios({
                headers: {
                    'Authorization': localStorage.auth_token,
                    'X-CSRFToken': csrftoken,
                    "Content-Type": "multipart/form-data"
                },
                method: 'post',
                url: process.env.API_URL + "api/campaign/",
                data: {
                    id: campaignEditState.id,
                    brand: campaignEditState.brand_id,
                    method: "update",
                    name: campaignEditState.name,
                    title: campaignEditState.title,
                    text: campaignEditState.text,
                    reward: campaignEditState.reward,
                    status: campaignEditState.status,
                    steps: campaignEditState.steps ? campaignEditState.steps.length : 0,
                    video_length: campaignEditState.video_length,
                    content_type: campaignEditState.content_type,
                    content_format: campaignEditState.content_format,
                    video_or_image: campaignEditState.video_or_image,
                    video: campaignEditState.video && campaignEditState.video instanceof File ? campaignEditState.video : null,
                    image: campaignEditState.image && campaignEditState.image instanceof File ? campaignEditState.image : null,
                    return_url: campaignEditState.return_url,
                    thankyou_title: campaignEditState.thankyou_title,
                    thankyou_message:campaignEditState.thankyou_message,
                    reject_title: campaignEditState.reject_title,
                    reject_message: campaignEditState.reject_message,
                    social_media_policy: campaignEditState.social_media_policy
                }
            })
                .then(function (response) {
                    navigate("/app/")
                })
                .catch(function (error) {
                    navigate("/app/something-went-wrong/")
                });

        }

    }

    const StatusOptions = [

        {
            "value": 'draft',
            "label": "Draft"
        },
        {
            "value": 'active',
            "label": "Active"
        }
    ]

    useEffect(() => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: 'get',
            url: process.env.API_URL + `api/campaign/${ObjNum()}/`,
        })
            .then(function (response) {
                setCampaignEditState(response.data)
                if (response.data.video) {
                    setCampaignEditState(oldstate => ({ ...oldstate, video_display: `${process.env.API_URL}${response.data.video.substr(1)}` }))
                } else if (response.data.image) {
                    setCampaignEditState(oldstate => ({ ...oldstate, image_display: `${process.env.API_URL}${response.data.image.substr(1)}` }))
                }
            })
            .catch(function (error) {
                console.log(error)
            });

        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: 'get',
            url: `${process.env.API_URL}api/brands/`,
        })
            .then(function (response) {
                if (response && response.data) {
                    let brand_list = []
                    response.data.map((result, i) => {
                        brand_list.push({ "value": result.id, "label": result.name })
                    })
                    setBrandListState({ data: brand_list })
                    setBrandsOptionsState(response.data)
                    setCampaignEditState(oldstate => {
                        let state_result = {
                            ...oldstate,
                            brand_id: response.data[0]['id']

                        };
                        return state_result
                    });
                }
            })
            .catch(function (error) {
            });
    }, [])

    const contentTypeText = () => {
        if ("unboxing_video" === campaignEditState.content_type) {
            return {
                "title": "Unboxing video",
                "text": "Entertaining unboxing video showing the packaging and what comes along with the product.",
                "content": [Video, Video, Video]
            }
        } else if ("how_to_video" === campaignEditState.content_type) {
            return {
                "title": "How to video",
                "text": "Detailed video tutorial to guide customers through all steps of using your product",
                "content": [Video, Video, Video]
            }
        } else if ("product_review" === campaignEditState.content_type) {
            return {
                "title": "Product review",
                "text": "Positive feedback videos where creators spread the word about how awesome your product is.",
                "content": [Video, Video, Video]
            }
        } else if ("product_demo" === campaignEditState.content_type) {
            return {
                "title": "Product demo",
                "text": "Detailed on-camera demonstration of how your product works and looks in real life.",
                "content": [Video, Video]
            }
        } else if ("picture_with_product" === campaignEditState.content_type) {
            return {
                "title": "Picture with product",
                "text": "Content creators creates a picture with the content creator and the product on.",
                "content": [Image_1, Image_1, Image_1]
            }
        } else if ("lifestyle_shot" === campaignEditState.content_type) {
            return {
                "title": "Lifestyle shot",
                "text": "A lifestyle shot is a photograph that captures individuals engaged with the product.",
                "content": [Image_1, Image_1]
            }
        } else if ("picture_of_product" === campaignEditState.content_type) {
            return {
                "title": "Picture of the product only",
                "text": "A product shot features only the product itself.",
                "content": [Image_1, Image_1]
            }
        } else {
            return {
                "title": "-",
                "text": "-",
                "videos": [Video, Video]
            }
        }

    }
    const contentType = () => {
        if (campaignEditState.content_type == "unboxing_video") {
            return "Unboxing video"
        } else if (campaignEditState.content_type == "how_to_video") {
            return "How to video"
        } else if (campaignEditState.content_type == "product_review") {
            return "product Review"
        } else if (campaignEditState.content_type == "product_demo") {
            return "Product Demo"

        } else if (campaignEditState.content_type == "picture_with_product") {
            return "Picture with product"
        } else if (campaignEditState.content_type == "lifestyle_shot") {
            return "Lifestyle shot"

        } else if (campaignEditState.content_type == "picture_of_product") {
            return "Picture of the product"
        } else {
            return "-"
        }

    }

    let viewStep2 = null
    switch (campaignEditState.video_or_image) {
        case "video":
            viewStep2 = <>
                <div className="row">
                    <div className="col-12">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Content type
                            </div>
                            <div className="wizard--sublabel">

                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <div className={`card content-type--card ${campaignEditState && campaignEditState.content_type === "unboxing_video" ? "content-type--card--active" : null}`} onClick={() => { setCampaignEditState(oldstate => ({ ...oldstate, content_type: "unboxing_video" })) }}>
                                        <div className="content-type--inner">
                                            <div className="content-type--title">
                                                Unboxing video
                                            </div>
                                            <div className="content-type--selector">
                                                <Icon
                                                    type={"fa-solid"}
                                                    icon={"circle-check"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`card content-type--card ${campaignEditState && campaignEditState.content_type === "how_to_video" ? "content-type--card--active" : null}`} onClick={() => { setCampaignEditState(oldstate => ({ ...oldstate, content_type: "how_to_video" })) }}>
                                        <div className="content-type--inner">
                                            <div className="content-type--title">
                                                How to video
                                            </div>
                                            <div className="content-type--selector">
                                                <Icon
                                                    type={"fa-solid"}
                                                    icon={"circle-check"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`card content-type--card ${campaignEditState && campaignEditState.content_type === "product_review" ? "content-type--card--active" : null}`} onClick={() => { setCampaignEditState(oldstate => ({ ...oldstate, content_type: "product_review" })) }}>
                                        <div className="content-type--inner">
                                            <div className="content-type--title">
                                                Product review
                                            </div>
                                            <div className="content-type--selector">
                                                <Icon
                                                    type={"fa-solid"}
                                                    icon={"circle-check"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`card content-type--card ${campaignEditState && campaignEditState.content_type === "product_demo" ? "content-type--card--active" : null}`} onClick={() => { setCampaignEditState(oldstate => ({ ...oldstate, content_type: "product_demo" })) }}>
                                        <div className="content-type--inner">
                                            <div className="content-type--title">
                                                Product demo
                                            </div>
                                            <div className="content-type--selector">
                                                <Icon
                                                    type={"fa-solid"}
                                                    icon={"circle-check"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                                    <div className="content-type--explain-short">
                                        {contentTypeText()['title']}
                                    </div>
                                    <div className="content-type--explain-long">
                                        {contentTypeText()['text']}
                                    </div>
                                    <div className="content-type--video--container">
                                        {campaignEditState && campaignEditState.video_or_image === "video" ?
                                            contentTypeText()['content'].map((video, key) => (
                                                <video
                                                    key={key}
                                                    controls
                                                    muted={false}
                                                    autoPlay={false}
                                                    className="video"
                                                >
                                                    <source key={key} src={video} type="video/mp4" />
                                                </video>
                                            ))
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Video time
                            </div>
                            <div className="wizard--sublabel">

                            </div>
                            <div className="wizard--field">
                                <div className="wizard--options row">
                                    <div className={`wizard--option  ${campaignEditState && campaignEditState.video_length === "15" ? "wizard--option--active" : ""}`} onClick={() => { setCampaignEditState(oldstate => ({ ...oldstate, video_length: "15" })) }}>
                                        <Icon
                                            type={"fa"}
                                            icon={"stopwatch"}
                                            icon_size={4}
                                            icon_color_code={"#B390FF"}
                                        ></Icon>
                                        <div className="wizard--title">
                                            15 seconds
                                        </div>
                                    </div>
                                    <div className={`wizard--option  ${campaignEditState && campaignEditState.video_length === "30" ? "wizard--option--active" : ""}`} onClick={() => { setCampaignEditState(oldstate => ({ ...oldstate, video_length: "30" })) }}>
                                        <Icon
                                            type={"fa"}
                                            icon={"stopwatch"}
                                            icon_size={4}
                                            icon_color_code={"#B390FF"}
                                        ></Icon>
                                        <div className="wizard--title">
                                            30 seconds
                                        </div>
                                    </div>
                                    <div className={`wizard--option  ${campaignEditState && campaignEditState.video_length === "45" ? "wizard--option--active" : ""}`} onClick={() => { setCampaignEditState(oldstate => ({ ...oldstate, video_length: "45" })) }}>
                                        <Icon
                                            type={"fa"}
                                            icon={"stopwatch"}
                                            icon_size={4}
                                            icon_color_code={"#B390FF"}
                                        ></Icon>
                                        <div className="wizard--title">
                                            45 seconds
                                        </div>
                                    </div>
                                    <div className={`wizard--option  ${campaignEditState && campaignEditState.video_length === "60" ? "wizard--option--active" : ""}`} onClick={() => { setCampaignEditState(oldstate => ({ ...oldstate, video_length: "60" })) }}>
                                        <Icon
                                            type={"fa"}
                                            icon={"stopwatch"}
                                            icon_size={4}
                                            icon_color_code={"#B390FF"}
                                        ></Icon>
                                        <div className="wizard--title">
                                            60 seconds
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            break;

        case "image":
            viewStep2 = <>
                <div className="row">
                    <div className="col-12">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Content type
                            </div>
                            <div className="wizard--sublabel">

                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <div className={`card content-type--card ${campaignEditState && campaignEditState.content_type === "picture_with_product" ? "content-type--card--active" : null}`} onClick={() => { setCampaignEditState(oldstate => ({ ...oldstate, content_type: "picture_with_product" })) }}>
                                        <div className="content-type--inner">
                                            <div className="content-type--title">
                                                Picture with product
                                            </div>
                                            <div className="content-type--selector">
                                                <Icon
                                                    type={"fa-solid"}
                                                    icon={"circle-check"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`card content-type--card ${campaignEditState && campaignEditState.content_type === "lifestyle_shot" ? "content-type--card--active" : null}`} onClick={() => { setCampaignEditState(oldstate => ({ ...oldstate, content_type: "lifestyle_shot" })) }}>
                                        <div className="content-type--inner">
                                            <div className="content-type--title">
                                                Lifestyle shot
                                            </div>
                                            <div className="content-type--selector">
                                                <Icon
                                                    type={"fa-solid"}
                                                    icon={"circle-check"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`card content-type--card ${campaignEditState && campaignEditState.content_type === "picture_of_product" ? "content-type--card--active" : null}`} onClick={() => { setCampaignEditState(oldstate => ({ ...oldstate, content_type: "picture_of_product" })) }}>
                                        <div className="content-type--inner">
                                            <div className="content-type--title">
                                                Picture of the product
                                            </div>
                                            <div className="content-type--selector">
                                                <Icon
                                                    type={"fa-solid"}
                                                    icon={"circle-check"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                                    <div className="content-type--explain-short">
                                        {contentTypeText()['title']}
                                    </div>
                                    <div className="content-type--explain-long">
                                        {contentTypeText()['text']}
                                    </div>
                                    <div className="content-type--video--container">
                                        {campaignEditState && campaignEditState.video_or_image === "image" ?
                                            contentTypeText()['content'].map((image, key) => (
                                                <img key={key} className='video_or_image--image' src={image}></img>
                                            ))
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Content format
                            </div>
                            <div className="wizard--sublabel">

                            </div>
                            <div className="wizard--field">
                                <div className="wizard--options row">
                                    <div className={`wizard--option  ${campaignEditState && campaignEditState.content_format === "any" ? "wizard--option--active" : ""}`} onClick={() => { setCampaignEditState(oldstate => ({ ...oldstate, content_format: "any" })) }}>
                                        <Icon
                                            type={"fa"}
                                            icon={"image"}
                                            icon_size={4}
                                            icon_color_code={"#B390FF"}
                                        ></Icon>
                                        <div className="wizard--title">
                                            Any
                                        </div>
                                    </div>
                                    <div className={`wizard--option  ${campaignEditState && campaignEditState.content_format === "portrait" ? "wizard--option--active" : ""}`} onClick={() => { setCampaignEditState(oldstate => ({ ...oldstate, content_format: "portrait" })) }}>
                                        <Icon
                                            type={"fa"}
                                            icon={"image-portrait"}
                                            icon_size={4}
                                            icon_color_code={"#B390FF"}
                                        ></Icon>
                                        <div className="wizard--title">
                                            Portrait
                                        </div>
                                    </div>
                                    <div className={`wizard--option  ${campaignEditState && campaignEditState.content_format === "landscape" ? "wizard--option--active" : ""}`} onClick={() => { setCampaignEditState(oldstate => ({ ...oldstate, content_format: "landscape" })) }}>
                                        <Icon
                                            type={"fa"}
                                            icon={"panorama"}
                                            icon_size={4}
                                            icon_color_code={"#B390FF"}
                                        ></Icon>
                                        <div className="wizard--title">
                                            Landscape
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            break;

    }

    let currentPage = null
    switch (stepState.step) {
        case 1:
            currentPage = <>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Campaign name ( Only you are able to see it )
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("name", e)}
                                    type="text"
                                    label=""
                                    name="name"
                                    value={campaignEditState.name || ""}
                                    placeholder="Enter the name of the campaign"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Status
                            </div>
                            <div className="wizard--field">
                                <Select
                                    onChange={(e) => FieldListener("status", e)}
                                    name="Status"
                                    label={"status"}
                                    options={StatusOptions || []}
                                    selected={campaignEditState.status || ""}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Campaign title ( Visible for the end user )
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("title", e)}
                                    type="text"
                                    label=""
                                    name="title"
                                    value={campaignEditState.title || ''}
                                    placeholder="Enter the title of the campaign"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Campaign reward
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("reward", e)}
                                    type="text"
                                    name="reward"
                                    value={campaignEditState.reward || ''}
                                    placeholder="Enter the reward for the campaign"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Social media policy
                            </div>
                            <div className="wizard--field">
                                <TextArea
                                    onChange={(e) => FieldListener("social_media_policy", e)}
                                    label=""
                                    name="social_media_policy"
                                    value={campaignEditState.social_media_policy || ''}
                                    placeholder={"Describe the steps the content creator should follow"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                How it works
                            </div>
                            <div className="wizard--field">
                                <TextArea
                                    onChange={(e) => FieldListener("text", e)}
                                    label=""
                                    name="text"
                                    value={campaignEditState.text || ''}
                                    placeholder={"Describe the steps the content creator should follow"}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Return url
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("return_url", e)}
                                    type="text"
                                    name="return_url"
                                    value={campaignEditState.return_url || ''}
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </div>

                </div>
                {/* <div className="row">
                    <div className="card wizard--card">
                        <div className="wizard--label">
                            Status
                        </div>
                        <div className="wizard--field">
                            <Select
                                onChange={(e) => FieldListener("status", e)}
                                name="Status"
                                label={"status"}
                                options={StatusOptions || []}
                                selected={campaignEditState.status || ""}
                            />
                        </div>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-12 create-col-video">
                        {campaignEditState && campaignEditState.video_or_image === "video" ?
                            <>
                                <div className="example_video">Example video</div>
                                <FileUploadVideo
                                    fileUpload={fileUploadListener}
                                    clearContent={() => { clearContent("video") }}
                                    type="file"
                                    name="ugc_video"
                                    ariaLabel="ugc_video"
                                    label="ugc_video"
                                    video={campaignEditState.video || null}
                                />
                            </>
                            : <>
                                <div className="example_video">Example Image</div>
                                <FileUpload
                                    fileUpload={fileImageUploadListener}
                                    clearContent={() => { clearContent("image") }}
                                    type="file"
                                    name="iamge"
                                    ariaLabel="image"
                                    image={campaignEditState.image || null}
                                    inputLabel={""}
                                />
                            </>}
                    </div>
                </div>

            </>
            break;

        case 2:
            currentPage = <>
                {viewStep2}
            </>
            break;

        case 3:
            currentPage = <>
                <div className="row">

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Thank you title
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("thankyou_title", e)}
                                    type="text"
                                    label=""
                                    name="thankyou_title"
                                    value={campaignEditState.thankyou_title || ''}
                                    placeholder="Enter the title of the thank you message"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Reject title
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("reject_title", e)}
                                    type="text"
                                    name="reject_title"
                                    value={campaignEditState.reject_title || ''}
                                    placeholder="Enter the title of reject message"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Thank you message
                            </div>
                            <div className="wizard--field">
                                <TextArea
                                    onChange={(e) => FieldListener("thankyou_message", e)}
                                    label=""
                                    name="thankyou_message"
                                    value={campaignEditState.thankyou_message || ''}
                                    placeholder={"Describe the steps the content creator should follow"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Reject message
                            </div>
                            <div className="wizard--field">
                                <TextArea
                                    onChange={(e) => FieldListener("reject_message", e)}
                                    label=""
                                    name="reject_message"
                                    value={campaignEditState.reject_message || ''}
                                    placeholder={"Describe the steps the content creator should follow"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
            break;
        case 4:
            currentPage = <>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="sendugcform--card card">
                            <div className="sendugcform--card-header">
                                <div className="row">
                                    <div className="col-6 left-image-con">
                                        {campaignEditState && campaignEditState.brand.logo ?
                                            <div className=''>
                                                <span className="sendugcform--logo-image">
                                                    <img src={`${process.env.API_URL}${campaignEditState.brand.logo.substr(1)}`}></img>
                                                </span>
                                            </div>
                                            : null}
                                        {/* <div className="sendugcform--name">
                                            {campaignEditState && campaignEditState.brand && campaignEditState.brand.logo ? <>{campaignEditState.brand.name}</>
                                                : null}
                                        </div> */}
                                    </div>
                                    <div className="col-6 right-image-con">
                                        <div className='card-user-image' ><img className='circle-image sendugcform--image-header' src={Image}></img></div>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="sendugcform--card-body">
                                <div className="sendugcform-input-container">
                                    <div className="sendugcform-input-title">
                                        Campaign
                                    </div>
                                    <div className="sendugcform-input-value">
                                        <div className="sendugcform-input-value-icon">
                                            {campaignEditState && campaignEditState.video_or_image === "video" ?
                                                <Icon
                                                    type={"fa"}
                                                    icon={"video"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                                : <Icon
                                                    type={"fa"}
                                                    icon={"camera"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>}
                                        </div>
                                        <div className="sendugcform-input-value-text">
                                            {campaignEditState.title}
                                        </div>
                                    </div>
                                </div>
                                <div className="sendugcform-reward">
                                    <div className="sendugcform-input-title">
                                        Reward
                                    </div>
                                    <div className="sendugcform-reward-container">
                                        <div className="sendugcform-reward-icon">
                                            <Icon
                                                type={"fa"}
                                                icon={"gift"}
                                                icon_size={1}
                                                icon_color_code={"#ffffff"}
                                            ></Icon>
                                        </div>
                                        <div className="sendugcform-reward-text">
                                            {campaignEditState.reward}
                                        </div>
                                    </div>
                                </div>
                                <div className="sendugcform-requirements">
                                    <div className="sendugcform-input-title">
                                        Requirements
                                    </div>
                                    <div className="sendugcform-requirement--container">
                                        <div className="sendugcform-requirement">
                                            <div className="sendugcform-requirement-icon">
                                                {campaignEditState && campaignEditState.video_or_image === "video" ?
                                                    <Icon
                                                        type={"fa"}
                                                        icon={"video"}
                                                        icon_size={1}
                                                        icon_color_code={"#4f4f4f"}
                                                    ></Icon>
                                                    : <Icon
                                                        type={"fa"}
                                                        icon={"image"}
                                                        icon_size={1}
                                                        icon_color_code={"#4f4f4f"}
                                                    ></Icon>}
                                            </div>
                                            <div className="sendugcform-requirement-text">
                                                {contentType()}
                                            </div>
                                        </div>
                                        <div className="sendugcform-requirement">
                                            <div className="sendugcform-requirement-icon">

                                                {campaignEditState && campaignEditState.video_or_image === "video" ?
                                                    <Icon
                                                        type={"fa"}
                                                        icon={"stopwatch"}
                                                        icon_size={1}
                                                        icon_color_code={"#4f4f4f"}
                                                    ></Icon>
                                                    : <Icon
                                                        type={"fa"}
                                                        icon={"file-image"}
                                                        icon_size={1}
                                                        icon_color_code={"#4f4f4f"}
                                                    ></Icon>}
                                            </div>
                                            <div className="sendugcform-requirement-text">
                                                {campaignEditState && campaignEditState.video_or_image === "video" ?
                                                    <>Video should be atleast {campaignEditState.video_length} seconds</>
                                                    : <>Content format {campaignEditState.content_format}</>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sendugcform-description">
                                    <div className="sendugcform-input-title">
                                        Description
                                    </div>
                                    <div className="sendugcform-input-description">
                                        {campaignEditState.text}
                                    </div>
                                </div>
                                <div className="sendugcform-button-container">
                                    <div className="sendugcform-button sendugcform-button-light">
                                        Social media policy
                                    </div>
                                    <div className="sendugcform-button sendugcform-button-dark">
                                        Get started now
                                    </div>
                                </div>

                            </div>
                            <div className="sendugcform--card-footer">

                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sendugcform-video--container" style={{ justifyContent: "center" }}>
                        {campaignEditState && campaignEditState.video_or_image === "video" ?
                            <>
                                {campaignEditState && campaignEditState.video && campaignEditState.new_file ?
                                    <div className="brand-media">
                                        <video
                                            controls
                                            loop
                                            muted={false}
                                            autoPlay={false}
                                            className="video"
                                        >
                                            <source src={campaignEditState.video.url} type="video/mp4" />
                                        </video>
                                    </div>
                                    : <div className="brand-media">    <video
                                        controls
                                        loop
                                        muted={false}
                                        autoPlay={false}
                                        className="video"
                                    >
                                        <source src={Video} type="video/mp4" />

                                    </video></div>}
                            </> : <>
                                {campaignEditState && campaignEditState.image && campaignEditState.new_file ?
                                    <div className="brand-media">
                                        <img className='sendugcform--image' src={campaignEditState.image.url}></img>
                                    </div>
                                    : <div className="brand-media">   <img className='sendugcform--image' src={campaignEditState.image_display}></img></div>}
                            </>


                        }
                    </div>
                </div>

            </>
            break;
        case 5:
            currentPage = <>
                <div style={{ textAlign: "center" }}>
                    <Icon
                        type={"fa"}
                        icon={"face-smile"}
                        icon_size={5}

                        icon_color_code={"#e3def9"}
                    ></Icon>
                    <Link to="/app/campaign">
                        <div style={{ margin: "auto", marginTop: "20px", width: "250px" }} className="button-continue">Return to campaigns</div>
                    </Link>
                </div>

            </>
            break;

    }

    return (
        <div className="row">
            <div className="col-12">

                <Link to={`/app/campaign/`} style={{ marginBottom: "20px" }}>
                    <span className="">
                        <Icon
                            type={"fa"}
                            icon={"chevron-left"}
                            icon_size={1}

                            icon_color_code={"#e3def9"}
                        ></Icon></span>
                    Back
                </Link>

                {modalState && modalState.modalIsOpen ? <SocialMediaPolicyModal text={campaignEditState.social_media_policy} setModalState={setModalState}></SocialMediaPolicyModal> : null}
            </div>
            <div className="col-12">
                <div className="module-steps">
                    <div className="module-step">
                        <div className={`module-step-icon ${stepState.step === 1 ? "module-step-icon-active" : ""}`}>
                            <Icon
                                type={"fa"}
                                icon={"flag-checkered"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>

                        <div className="module-step-text">
                            <div className="module-title">
                                Create campaign
                            </div>
                            <div className="module-text">
                                Campaign details
                            </div>
                        </div>
                        <div className="module-step-arrow">
                            <Icon
                                type={"fa"}
                                icon={"arrow-right"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                    </div>
                    <div className="module-step">
                        <div className={`module-step-icon ${stepState.step === 2 ? "module-step-icon-active" : ""}`}>
                            <Icon
                                type={"fa"}
                                icon={"star"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>

                        <div className="module-step-text">
                            <div className="module-title">
                                Add requirements
                            </div>
                            <div className="module-text">
                                requirement details
                            </div>
                        </div>
                        <div className="module-step-arrow">
                            <Icon
                                type={"fa"}
                                icon={"arrow-right"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                    </div>
                    <div className="module-step">
                        <div className={`module-step-icon ${stepState.step === 3 ? "module-step-icon-active" : ""}`}>
                            <Icon
                                type={"fa"}
                                icon={"envelope"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>

                        <div className="module-step-text">
                            <div className="module-title">
                                Email
                            </div>
                            <div className="module-text">
                                Message details
                            </div>
                        </div>
                        <div className="module-step-arrow">
                            <Icon
                                type={"fa"}
                                icon={"arrow-right"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>
                    </div>
                    <div className="module-step">
                        <div className={`module-step-icon ${stepState.step === 4 ? "module-step-icon-active" : ""}`}>
                            <Icon
                                type={"fa"}
                                icon={"magnifying-glass"}
                                icon_size={1}
                                icon_color_code={"#BFC1C3"}
                            ></Icon>
                        </div>

                        <div className="module-step-text">
                            <div className="module-title">
                                Preview
                            </div>
                            <div className="module-text">
                                Review the preview
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </div>
            <div className="col-12" style={{ margin: "auto" }}>
                {currentPage}
            </div>
            {stepState.step !== 5 ?
                <div className="row">
                    <div className="col-8 flexer">
                        <div onClick={() => { setStepState({ "step": stepState.step > 1 ? stepState.step - 1 : stepState.step, "continue": stepState.step > 1 ? true : stepState.continue }) }} className="button-back">back</div>
                        {stepState && stepState.step < 4 ?

                            <div onClick={() => setStepState(oldstate => ({ ...oldstate, "step": stepState.step + 1, "continue": true }))} className={`button-continue`}>Continue</div>
                            : <div onClick={e => { ButtonAction("save", e) }} className={`button-continue`}>Save</div>

                        }
                    </div>

                </div>
                : null
            }
        </div>

    )
}
export default CampaignEdit;