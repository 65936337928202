import React, { useState, useEffect, useRef } from "react";
import Icon from "../general/Icon";
import Select from "../web/Select";
import axios from "axios";
import getCookie from '../auth/Cookie';
var csrftoken = getCookie('csrftoken');
const UploadUGCModal = ({ setModalState, state, setState, contentState, setContentState, uploadContent, displayErrors }) => {
    const [lstState, setLstState] = useState({

    })

    const [stepState, setStepState] = useState({
        step: "step-1"
    })
    const [warningState, setWarningState] = useState({
        message: ""
    })
    let currentStep
    const removeSingleItem = (file_name) => {
        let new_videos = []
        for (const video in contentState.videos) {
            if (video && contentState.videos[video].name != file_name) {
                new_videos.push(contentState.videos[video])
            }
        }
        setContentState(oldstate => ({
            videos: new_videos
        }))

    }
    const FieldListener = (type, event) => {
        event.persist();
        setState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }
    const FieldSelectListener = (type, event) => {
        event.persist();
        if (event.target.value === "null") {
            event.target.value = ""
        }
        setState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }
    const booleanListener = (type) => {
        setState(oldstate => ({ ...oldstate, [type]: !oldstate[type] }))

    };

    useEffect(() => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: "get",
            url: process.env.API_URL + "api/ugc-fks/",

        })
            .then((response) => {
                if (response && response.data) {
                    setLstState(response.data)
                }
            })

    }, [])

    const didMount = useRef(false);
    const anyBoth = ([]) => {


    }
    useEffect(() => {
        if (didMount.current) {
            if (state.campaign && state.job) {
                setWarningState({
                    "message": "Can't select both Campaign and job"
                })

            } else if (state.campaign && state.collab) {
                setWarningState({
                    "message": "Can't select both Campaign and collab"
                })
            } else if (state.job && state.collab) {
                setWarningState({
                    "message": "Can't select both job and collab"
                })
            } else {
                setWarningState({
                    "message": ""
                })
            }

        } else {
            didMount.current = true;
        }
    }, [state])
    switch (stepState.step) {
        case "step-1":
            currentStep = <>
                <div className='modal-background'>
                    <div className='card generalcard' style={{ width: "600px", borderRadius: "20px" }}>
                        <div className="container-right-icon">
                            <div onClick={() => { setModalState({ modalIsOpen: false, type: null, id: null }), setContentState({ videos: [], images: [] }) }} className="right-icon">
                                <Icon
                                    type={"fa"}
                                    icon={"xmark"}
                                    icon_size={2}
                                    icon_color_code={"#6d6f71"}
                                ></Icon>
                            </div>
                        </div>
                        <div className="modal-title" style={{ paddingBottom: "5px", fontSize: "18px" }}>
                            Upload UGC content
                        </div>
                        <div className="modal-description center-div">

                            <div className="row" style={{maxHeight: "500px", overflowY: "scroll"}}>
                                {contentState && contentState.videos ?
                                    <>
                                        {contentState.videos.map((item, i) => (
                                            <div className="col-12">
                                                <div className="card-upload">
                                                    <div className="card-upload-file">
                                                        <Icon
                                                            type={"fa"}
                                                            icon={"file"}
                                                            icon_size={2}
                                                            icon_color_code={"#6d6f71"}
                                                        ></Icon>
                                                    </div>
                                                    <div className="card-upload-text-container">
                                                        <div className="">{item.file_name}</div>
                                                    </div>
                                                    <div className="card-upload-delete" onClick={() => { removeSingleItem(item.file_name) }}>
                                                        <Icon
                                                            type={"fa"}
                                                            icon={"trash"}
                                                            icon_size={2}
                                                            icon_color_code={"#6d6f71"}
                                                        ></Icon>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </>
                                    : null}
                                {contentState && contentState.images ?
                                    <>
                                        {contentState.images.map((item, i) => (
                                            <div className="col-12">
                                                <div className="card-upload">
                                                    <div className="card-upload-file">
                                                        <Icon
                                                            type={"fa"}
                                                            icon={"file"}
                                                            icon_size={2}
                                                            icon_color_code={"#6d6f71"}
                                                        ></Icon>
                                                    </div>
                                                    <div className="card-upload-text-container">
                                                        <div className="">{item.file_name}</div>
                                                    </div>
                                                    <div className="card-upload-delete" onClick={() => { removeSingleItem(item.file_name) }}>
                                                        <Icon
                                                            type={"fa"}
                                                            icon={"trash"}
                                                            icon_size={2}
                                                            icon_color_code={"#6d6f71"}
                                                        ></Icon>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </>
                                    : null}


                            </div>
                            <div className="row">
                                {displayErrors()}
                            </div>
                            <div className="row">
                                <div className="button-container-center">
                                    <div onClick={() => { setModalState({ modalIsOpen: false, type: null, id: null }), setContentState({ videos: [], images: [] }) }} className="button-red button-100">Cancel</div>
                                    <div onClick={() => { setStepState({ "step": "step-2" }) }} className="button-blue button-100">Continue</div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </>
            break;
        case "step-2":
            currentStep = <>
                <div className='modal-background'>
                    <div className='card generalcard' style={{ width: "600px", borderRadius: "20px" }}>
                        <div className="container-right-icon">
                            <div onClick={() => { setModalState({ modalIsOpen: false, type: null, id: null }), setContentState({ videos: [], images: [] }) }} className="right-icon">
                                <Icon
                                    type={"fa"}
                                    icon={"xmark"}
                                    icon_size={2}
                                    icon_color_code={"#6d6f71"}
                                ></Icon>
                            </div>
                        </div>
                        <div className="modal-title" style={{ paddingBottom: "5px", fontSize: "18px" }}>
                            Upload UGC content
                        </div>
                        <div className="modal-description" style={{ paddingBottom: "5px", fontSize: "14px", textAlign: "center", color: "#fe6a68", fontWeight: 700 }}>
                            {warningState.message}
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Select
                                    onChange={(e) => FieldSelectListener("brand", e)}
                                    name="brand"
                                    label={"Brand"}
                                    options={lstState.brands || []}
                                    selected={state.brand || ""}
                                    upperLabel={"Brand"}
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                {/* <Select
                                    onChange={(e) => FieldSelectListener("campaign", e)}
                                    name="campaign"
                                    label={"Campaign"}
                                    options={lstState.campaigns || []}
                                    selected={state.campaign || ""}
                                    upperLabel={"Campaign"}
                                /> */}
                                <Select
                                    onChange={(e) => FieldSelectListener("collab", e)}
                                    name="collab"
                                    label={"Collab"}
                                    options={lstState.collabs || []}
                                    selected={state.collab || ""}
                                    upperLabel={"Collab"}
                                />
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Select
                                    onChange={(e) => FieldSelectListener("job", e)}
                                    name="job"
                                    label={"Job"}
                                    options={lstState.jobs || []}
                                    selected={state.job || ""}
                                    upperLabel={"Job"}
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Select
                                    onChange={(e) => FieldSelectListener("collab", e)}
                                    name="collab"
                                    label={"Collab"}
                                    options={lstState.collabs || []}
                                    selected={state.collab || ""}
                                    upperLabel={"Collab"}
                                />
                            </div>
                        </div> */}
                        {/* <div className="row">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="sendugcform-checkbox">
                                    <label className="container">
                                        <div className="checkbox--text">I accepts gifted jobs</div>
                                        <input type="checkbox" onClick={() => { booleanListener("accept_gifted") }}></input>
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="sendugcform-checkbox">
                                    <label className="container">
                                        <div className="checkbox--text">I accepts barter deals</div>
                                        <input type="checkbox" onClick={() => { booleanListener("accept_barter") }}></input>
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="button-container-center">
                                <div onClick={() => { setStepState({ "step": "step-1" }) }} className="button-red button-100">Cancel</div>
                                {state && state.brand ?
                                    <div onClick={() => { setStepState({ "step": "step-3" }), uploadContent() }} className="button-blue button-100">Upload</div>
                                    :
                                    <div onClick={() => { setStepState({ "step": "step-3" }), uploadContent() }} className="button-grey button-100">Pick a brand</div>
                                }
                            </div>
                        </div>

                    </div>

                </div>
            </>
            break;
        case "step-3":
            currentStep = <>
                <div className='modal-background'>
                    <div className='card generalcard' style={{ width: "600px", borderRadius: "20px" }}>
                        <div className="container-right-icon">
                            <div onClick={() => { setModalState({ modalIsOpen: false, type: null, id: null }), setContentState({ videos: [], images: [] }) }} className="right-icon">
                                <Icon
                                    type={"fa"}
                                    icon={"xmark"}
                                    icon_size={2}
                                    icon_color_code={"#6d6f71"}
                                ></Icon>
                            </div>
                        </div>
                        <div className="modal-title" style={{ paddingBottom: "5px", fontSize: "18px" }}>
                            Your UGC content is currently uploading
                        </div>
                        <div className="modal-description center-div">
                            <div className="row">
                                <div> Please come back in couple minutes to view your content</div>

                            </div>
                        </div>

                    </div>

                </div>
            </>
            break;

    }
    return (
        <>
            {currentStep}
        </>
    )
}
export default UploadUGCModal;