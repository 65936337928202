import React from "react";
import Image from "../../files/ugcbird-long.png";
import Icon from "../general/Icon";
const PreSuccess = () => {

    return (
        <div className="pre-success">
            <div className='pre-success--logo logo-image'>
                <span className="app-brand-logo ">
                    <img src={Image}></img>
                </span>
            </div>
            <div className="pre-success--title ralyway-bold">
                Thank you!
            </div>
            <div className="pre-success--text">
                We'll be in your inbox soon :)
            </div>
            <div className="pre-success--socials">
                <div className="pre-success--follow ralyway-bold">Follow us on:</div>
                <div className="pre-success--icon-containers">
                    <a href="https://www.tiktok.com/@ugcbird/">
                        <Icon
                            type={"fa-brands"}
                            icon={"tiktok"}
                            icon_size={1}
                            icon_color_code={"#ffffff"}
                        ></Icon></a>
                    <a href="https://www.facebook.com/ugcbird">
                        <Icon
                            type={"fa-brands"}
                            icon={"facebook"}
                            icon_size={1}
                            icon_color_code={"#ffffff"}
                        ></Icon></a>
                    <a href="https://www.instagram.com/ugcbird/">
                        <Icon
                            type={"fa-brands"}
                            icon={"instagram"}
                            icon_size={1}
                            icon_color_code={"#ffffff"}
                        ></Icon></a>
                    <a href="https://twitter.com/ugcbird">
                        <Icon
                            type={"fa-brands"}
                            icon={"twitter"}
                            icon_size={1}
                            icon_color_code={"#ffffff"}
                        ></Icon></a>
                </div>
            </div>
        </div>
    )
}

export default PreSuccess;