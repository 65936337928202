import React from "react";
import { Link } from "react-router-dom";
import Image from "../../files/ugcbird-long.png";
import Video from "../../files/SnapTik.ltd_1705946584.mp4";
const BrandOrCreator = ({ appState, setAppAuth }) => {

    return (
        <div className="container login">
            <div className="row login--row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="Auth-form-container">
                        <div className="Auth-form">
                            <div className="Auth-form-content">
                                <div className='logo-image'>
                                    <span className="app-brand-logo ">
                                        <img src={Image}></img>
                                    </span>
                                </div>
                                <h3 className="Auth-form-title login-title">Sign up</h3>
                                <h6 className="login-h6 login-title">Please tell us who you are</h6>
                                <div className="brand_or_creator_button_container">
                                    <Link to="/app/register/brand-onboarding/">
                                        <div className="boc_button boc_button_brand">I'm a Brand</div>
                                    </Link>
                                    <Link to="">
                                        <div className="boc_button boc_button_creator" >I'm a Creator</div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {window && window.innerWidth > 767 ?
                    <div className="col-6 img-container login-image-container">
                        <div className="video-mask">
                            <video
                                // autoPlay={true}  
                                // playsInline={true}
                                controls
                                muted={false}
                                autoPlay={false}
                                className="video"
                            >
                                <source src={Video} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    : null}
            </div>
        </div>
    )
}
export default BrandOrCreator;