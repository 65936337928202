import React, { } from "react";

const LogoutModal = ({ setModalState }) => {


    const LogoutNow = () => {
        localStorage.clear()
        setModalState({ modalIsOpen: false })
        window.location.replace('/app/login/');
    }

    return (
        <>

            <div className='modal-background'>
                <div className='card logoutcard'>
                    <div className="modal-title" style={{ paddingBottom: "5px" }}>
                        Logout
                    </div>
                    <div style={{ textAlign: "center" }}>Your about to leave UGCBird</div>

                    <div className="button-container-center">
                        <div onClick={() => { setModalState({ modalIsOpen: false }) }} className="button-red button-100">Cancel</div>
                        <div onClick={() => { LogoutNow() }} className="button-blue button-100">Continue</div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default LogoutModal;