import React, { FunctionComponent } from 'react';
import Icon from '../general/Icon';
import { Link } from 'react-router-dom';
type ITableHead = {
    columns: any;
    key: number;
    deleteEntry: any;

}

const TableRowJob: FunctionComponent<ITableHead> = ({ columns, openDeleteModal, setModalState }) => {

    const copyToClipBoard = (iframe_url) => {
        navigator.clipboard.writeText(iframe_url)
        setModalState({
            modalIsOpen: true,
            id: null,
            type: "copy_to_clipboard"
        })
    }
    const tableColumn = () => {
        let table_columns = []
        let action_icons = []
        table_columns.push(<td style={{ display: "flex", height: "47px" }} className="truncate_text"><div className='creator-username'>{columns["name"] || "-"}</div></td>)
        table_columns.push(<td className="truncate_text">{columns["brand_info"]["name"] || "-"}</td>)
        table_columns.push(<td className={`truncate_text`}><div className={`truncate_text-${columns["status"]}`}>{columns["status"]}</div></td>)
        table_columns.push(<td className="truncate_text">{columns["job_applications_count"] || "-"}</td>)
        table_columns.push(<td className="truncate_text">{columns["open_rate"] || "-"}</td>)
        if (columns["action"]) {
            action_icons.push(
                <>
                    {columns["action"] && columns["action"]["preview_url"] ?
                        <Link className='a-action'  to={columns["action"]["preview_url"]}>
                            <div className="icon-tooltip-container">
                                <div className="icon-tooltip-text">
                                    Preview your job form
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 24 24" fill="none">
                                <rect width="24" height="24" rx="8" fill="#F5F5F5" />
                                <g clipPath="url(#clip0_1_485)">
                                    <path d="M17.8535 17.1465L14.869 14.162C15.6823 13.1673 16.0822 11.898 15.9859 10.6168C15.8897 9.33552 15.3046 8.14027 14.3518 7.27826C13.399 6.41626 12.1513 5.95345 10.8668 5.98556C9.58236 6.01768 8.35936 6.54226 7.45082 7.45081C6.54227 8.35935 6.01769 9.58235 5.98558 10.8668C5.95346 12.1513 6.41627 13.399 7.27827 14.3518C8.14028 15.3046 9.33553 15.8897 10.6168 15.9859C11.8981 16.0822 13.1673 15.6823 14.162 14.869L17.1465 17.8535C17.2408 17.9446 17.3671 17.995 17.4982 17.9938C17.6293 17.9927 17.7547 17.9401 17.8474 17.8474C17.9401 17.7547 17.9927 17.6293 17.9939 17.4982C17.995 17.3671 17.9446 17.2408 17.8535 17.1465ZM11 15C10.2089 15 9.43553 14.7654 8.77773 14.3259C8.11994 13.8864 7.60725 13.2616 7.30449 12.5307C7.00174 11.7998 6.92253 10.9956 7.07687 10.2196C7.23121 9.44371 7.61218 8.73098 8.17159 8.17157C8.731 7.61216 9.44373 7.2312 10.2197 7.07686C10.9956 6.92252 11.7998 7.00173 12.5307 7.30448C13.2617 7.60723 13.8864 8.11992 14.3259 8.77772C14.7654 9.43552 15 10.2089 15 11C14.9988 12.0605 14.577 13.0772 13.8271 13.8271C13.0772 14.577 12.0605 14.9988 11 15Z" fill="#666666" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1_485">
                                        <rect width="12" height="12" fill="white" transform="translate(6 6)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </Link>
                        : null
                    }
                    {columns["action"] && columns["action"]["url"] ?
                        <Link className='a-action'  to={columns["action"]["url"]}>
                            <div className="icon-tooltip-container">
                                <div className="icon-tooltip-text">
                                Watch the live job ( What the end users see )
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 24 24" fill="none">
                                <rect width="24" height="24" rx="8" fill="#F5F5F5" />
                                <g clipPath="url(#clip0_1_488)">
                                    <path d="M17.6355 10.7095C16.86 9.4465 15.096 7.3275 12 7.3275C8.90403 7.3275 7.14003 9.4465 6.36453 10.7095C6.12479 11.0973 5.9978 11.5441 5.9978 12C5.9978 12.4559 6.12479 12.9027 6.36453 13.2905C7.14003 14.5535 8.90403 16.6725 12 16.6725C15.096 16.6725 16.86 14.5535 17.6355 13.2905C17.8753 12.9027 18.0022 12.4559 18.0022 12C18.0022 11.5441 17.8753 11.0973 17.6355 10.7095ZM16.783 12.767C16.117 13.85 14.6095 15.6725 12 15.6725C9.39053 15.6725 7.88303 13.85 7.21703 12.767C7.07459 12.5365 6.99915 12.2709 6.99915 12C6.99915 11.7291 7.07459 11.4635 7.21703 11.233C7.88303 10.15 9.39053 8.3275 12 8.3275C14.6095 8.3275 16.117 10.148 16.783 11.233C16.9255 11.4635 17.0009 11.7291 17.0009 12C17.0009 12.2709 16.9255 12.5365 16.783 12.767Z" fill="#666666" />
                                    <path d="M12 9.5C11.5055 9.5 11.0222 9.64662 10.6111 9.92133C10.2 10.196 9.87952 10.5865 9.6903 11.0433C9.50108 11.5001 9.45157 12.0028 9.54804 12.4877C9.6445 12.9727 9.8826 13.4181 10.2322 13.7678C10.5819 14.1174 11.0273 14.3555 11.5123 14.452C11.9972 14.5484 12.4999 14.4989 12.9567 14.3097C13.4135 14.1205 13.804 13.8 14.0787 13.3889C14.3534 12.9778 14.5 12.4945 14.5 12C14.4992 11.3372 14.2356 10.7018 13.7669 10.2331C13.2982 9.76444 12.6628 9.50079 12 9.5ZM12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5007 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z" fill="#666666" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1_488">
                                        <rect width="12" height="12" fill="white" transform="translate(6 6)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </Link>
                        : null
                    }
                    {/* {columns["action"] && columns["action"]["iframe_url"] ?
                        <div onClick={() => { copyToClipBoard(columns["action"]["iframe_url"]) }} style={{paddingRight: "15px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 24 24" fill="none">
                                <rect width="24" height="24" rx="8" fill="#F5F5F5" />
                                <g clipPath="url(#clip0_1_492)">
                                    <path d="M17.6457 10.7866C17.4501 10.5911 17.1331 10.5911 16.9376 10.7866L11.4831 16.2646C10.5052 17.2426 8.91978 17.2427 7.9418 16.2649C6.96382 15.2871 6.96372 13.7016 7.94154 12.7236L13.2243 7.41932C13.8157 6.83735 14.7669 6.84498 15.3489 7.43636C15.9242 8.02096 15.9243 8.9589 15.3491 9.54364L10.0664 14.8479C9.86841 15.0375 9.55621 15.0375 9.35827 14.8479C9.16278 14.6524 9.16278 14.3354 9.35827 14.1398L14.0616 9.41495C14.2538 9.21601 14.2483 8.89899 14.0493 8.70686C13.8553 8.51941 13.5476 8.51941 13.3535 8.70686L8.65018 13.4317C8.06342 14.0184 8.06335 14.9696 8.64999 15.5564C8.65006 15.5564 8.6501 15.5565 8.65018 15.5566C9.2448 16.124 10.1804 16.124 10.775 15.5566L16.0572 10.2518C17.0225 9.26157 17.0023 7.67632 16.0121 6.71101C15.0396 5.76292 13.4886 5.76303 12.5162 6.71123L7.23344 12.0155C5.86441 13.3845 5.86441 15.6042 7.23344 16.9732C8.60248 18.3423 10.8221 18.3423 12.1912 16.9732L17.6457 11.4967C17.8418 11.3016 17.8426 10.9846 17.6476 10.7885C17.647 10.7878 17.6463 10.7872 17.6457 10.7866Z" fill="#666666" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1_492">
                                        <rect width="12" height="12" fill="white" transform="translate(6 6)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        : null
                    } */}
                    <Link className='a-action'  to={columns["action"]["content_url"]}>
                        <div className="icon-tooltip-container">
                            <div className="icon-tooltip-text">
                                View all job applications
                            </div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 24 24" fill="none">
                            <rect width="24" height="24" rx="8" fill="#F5F5F5" />
                            <g clipPath="url(#clip0_1_496)">
                                <path d="M17.7465 11.5085C17.503 11.1815 17.1295 10.9935 16.7215 10.9935H16.5V10C16.5 8.6215 15.3785 7.5 14 7.5H11.472C11.395 7.5 11.317 7.4815 11.2485 7.4475L9.671 6.6585C9.464 6.555 9.232 6.5005 9 6.5005H8C6.897 6.5 6 7.397 6 8.5V15C6 16.3785 7.1215 17.5 8.5 17.5H14.779C15.892 17.5 16.854 16.784 17.18 15.6965L17.946 12.6385C18.063 12.2475 17.99 11.836 17.7465 11.5085ZM7 15V8.5C7 7.9485 7.4485 7.5 8 7.5H9C9.077 7.5 9.155 7.5185 9.2235 7.5525L10.801 8.3415C11.008 8.445 11.24 8.4995 11.472 8.4995H14C14.827 8.4995 15.5 9.1725 15.5 9.9995V10.993H10.3075C9.4575 10.993 8.6985 11.5325 8.413 12.353L7.318 15.922C7.119 15.6675 7 15.3475 7 15ZM16.982 12.3735L16.216 15.431C16.024 16.0705 15.4465 16.5 14.779 16.5H8.5C8.396 16.5 8.2945 16.4895 8.1965 16.469L9.3635 12.6645C9.503 12.263 9.883 11.9935 10.308 11.9935H16.722C16.843 11.9935 16.9135 12.0635 16.9445 12.1055C16.9755 12.1475 17.0225 12.235 16.982 12.3735Z" fill="#666666" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_496">
                                    <rect width="12" height="12" fill="white" transform="translate(6 6)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Link>
                    <Link className='a-action'  to={columns["action"]["edit_url"]}>
                        <div className="icon-tooltip-container">
                            <div className="icon-tooltip-text">
                                Edit job
                            </div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 24 24" fill="none">
                            <rect width="24" height="24" rx="8" fill="#F5F5F5" />
                            <g clipPath="url(#clip0_1_499)">
                                <path d="M17.375 10.8465C17.778 11.3035 18 11.8905 18 12.5V15C18 16.3785 16.8785 17.5 15.5 17.5H8.5C7.1215 17.5 6 16.3785 6 15V12.5C6 11.1215 7.1215 10 8.5 10H10.5C10.7765 10 11 10.224 11 10.5C11 10.776 10.7765 11 10.5 11H8.5C7.673 11 7 11.673 7 12.5V15C7 15.827 7.673 16.5 8.5 16.5H15.5C16.327 16.5 17 15.827 17 15V12.5C17 12.1345 16.8665 11.782 16.625 11.508C16.4425 11.301 16.462 10.985 16.6695 10.802C16.876 10.62 17.192 10.6395 17.375 10.8465ZM8.5 13.75C8.5 14.164 8.836 14.5 9.25 14.5C9.664 14.5 10 14.164 10 13.75C10 13.336 9.664 13 9.25 13C8.836 13 8.5 13.336 8.5 13.75ZM11.75 14.5C12.164 14.5 12.5 14.164 12.5 13.75C12.5 13.336 12.164 13 11.75 13C11.336 13 11 13.336 11 13.75C11 14.164 11.336 14.5 11.75 14.5ZM12 11.5V10.707C12 10.173 12.208 9.6705 12.586 9.293L15.4395 6.4395C16.0245 5.8545 16.9755 5.8545 17.5605 6.4395C17.8435 6.7225 18 7.0995 18 7.5C18 7.9005 17.8435 8.2775 17.5605 8.561L14.707 11.4145C14.3295 11.792 13.827 12.0005 13.293 12.0005H12.5C12.2235 12.0005 12 11.7765 12 11.5005V11.5ZM13 11H13.293C13.56 11 13.811 10.896 14 10.707L16.8535 7.8535C16.948 7.759 17 7.6335 17 7.5C17 7.3665 16.948 7.241 16.8535 7.1465C16.658 6.951 16.342 6.9515 16.1465 7.1465L13.293 10C13.107 10.1865 13 10.444 13 10.707V11Z" fill="#666666" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_499">
                                    <rect width="12" height="12" fill="white" transform="translate(6 6)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Link>
                    <div className='a-action'  onClick={() => openDeleteModal(columns["id"])} className='table-delete'>
                        <div className="icon-tooltip-container">
                            <div className="icon-tooltip-text">
                                Delete job
                            </div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 24 24" fill="none">
                            <rect width="24" height="24" rx="8" fill="#FFE9E9" />
                            <path d="M16.5 8H14.95C14.834 7.43571 14.5269 6.92867 14.0806 6.56436C13.6343 6.20005 13.0761 6.00073 12.5 6L11.5 6C10.9239 6.00073 10.3657 6.20005 9.91937 6.56436C9.47309 6.92867 9.16605 7.43571 9.05 8H7.5C7.36739 8 7.24021 8.05268 7.14645 8.14645C7.05268 8.24021 7 8.36739 7 8.5C7 8.63261 7.05268 8.75979 7.14645 8.85355C7.24021 8.94732 7.36739 9 7.5 9H8V15.5C8.00079 16.1628 8.26444 16.7982 8.73311 17.2669C9.20178 17.7356 9.8372 17.9992 10.5 18H13.5C14.1628 17.9992 14.7982 17.7356 15.2669 17.2669C15.7356 16.7982 15.9992 16.1628 16 15.5V9H16.5C16.6326 9 16.7598 8.94732 16.8536 8.85355C16.9473 8.75979 17 8.63261 17 8.5C17 8.36739 16.9473 8.24021 16.8536 8.14645C16.7598 8.05268 16.6326 8 16.5 8ZM11.5 7H12.5C12.8101 7.00038 13.1126 7.09669 13.3658 7.27572C13.619 7.45476 13.8107 7.70775 13.9145 8H10.0855C10.1893 7.70775 10.381 7.45476 10.6342 7.27572C10.8874 7.09669 11.1899 7.00038 11.5 7ZM15 15.5C15 15.8978 14.842 16.2794 14.5607 16.5607C14.2794 16.842 13.8978 17 13.5 17H10.5C10.1022 17 9.72064 16.842 9.43934 16.5607C9.15804 16.2794 9 15.8978 9 15.5V9H15V15.5Z" fill="#FC5A5A" />
                            <path d="M11 15C11.1326 15 11.2598 14.9473 11.3536 14.8536C11.4473 14.7598 11.5 14.6326 11.5 14.5V11.5C11.5 11.3674 11.4473 11.2402 11.3536 11.1464C11.2598 11.0527 11.1326 11 11 11C10.8674 11 10.7402 11.0527 10.6464 11.1464C10.5527 11.2402 10.5 11.3674 10.5 11.5V14.5C10.5 14.6326 10.5527 14.7598 10.6464 14.8536C10.7402 14.9473 10.8674 15 11 15Z" fill="#FC5A5A" />
                            <path d="M13 15C13.1326 15 13.2598 14.9473 13.3536 14.8536C13.4473 14.7598 13.5 14.6326 13.5 14.5V11.5C13.5 11.3674 13.4473 11.2402 13.3536 11.1464C13.2598 11.0527 13.1326 11 13 11C12.8674 11 12.7402 11.0527 12.6464 11.1464C12.5527 11.2402 12.5 11.3674 12.5 11.5V14.5C12.5 14.6326 12.5527 14.7598 12.6464 14.8536C12.7402 14.9473 12.8674 15 13 15Z" fill="#FC5A5A" />
                        </svg>
                    </div>
                </>
            )
        }
        table_columns.push(<td className="truncate_text trunc-action">{action_icons}</td>)

        return table_columns
    }
    return (
        <>
            <tr key={columns["id"]}>
                {tableColumn()}
            </tr>
        </>
    )
}

export default TableRowJob;
