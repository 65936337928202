import 'bootstrap/dist/css/bootstrap.css';
import React from "react";
// import ReactDOM from "react-dom";
import "./scss/main.scss";
import { createRoot } from "react-dom/client";
import AppRender from './js/app/AppRender';
import * as Sentry from "@sentry/react";
import HowItWorks from "./js/web/HowItWorks";
import VideoCarousel from './js/videocarousel/VideoCarousel';
import VideoCarouselMobile from "./js/videocarousel/VideoCarouselMobile";
import SendUGCForm from "./js/form/SendUGCForm";
import disableReactDevTools from "./disableReactDevTools";
if (process.env.NODE_ENV === "production") disableReactDevTools();

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://1f54c49dafacae097b44e0a719aa8697@o4506520262868992.ingest.sentry.io/4506520264179712",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (document.getElementById("app")) {
  const root = createRoot(document.getElementById("app"));
  if (root) {
    root.render(
      <AppRender />
    );
  }
}

if (document.getElementById("ugcform")) {
  const root = createRoot(document.getElementById("ugcform"));
  if (root) {
    root.render(
      <SendUGCForm />
    );
  }
}


if (document.getElementById("slider-content")) {
  const root = createRoot(document.getElementById("slider-content"));
  let viewport = document.getElementsByClassName("header-gradient")[0].offsetWidth
  if (root && viewport < 400) {
    root.render(
      <VideoCarouselMobile />
    );
  } else {
    if (root) {
      root.render(
        <VideoCarousel></VideoCarousel>
      )
    }
  }
}

if (document.getElementById("gather-content")) {
  const root = createRoot(document.getElementById("gather-content"));
  if (root) {
    root.render(
      <HowItWorks />
    );
  }
}


if (document.getElementById('navbarMenu')) {
  document.addEventListener('click', function (event) {
    if (event.target.classList.contains('navbar-toggler') || event.target.classList.contains('navbar-toggler-icon')) {
      let men = document.getElementById("navbarMenu")
      if (!men.classList.contains('show')) {

        men.classList.add('show')
      } else {
        men.classList.remove('show')
      }

    }
    if (!event.target.classList.contains('navbar-toggler-icon')) {
      let men = document.getElementById("navbarMenu")
      if (men.classList.contains('show')) {

        men.classList.remove('show')
      }
    }
  })
}


if (document.getElementById('faq-item')) {
  document.addEventListener('click', function (event) {
    let aria_id = event.target.getAttribute('aria-controls')
    if (aria_id && aria_id.includes('multiCollapse')) {
      let card = document.getElementById(aria_id)
      if (!card.classList.contains('show')) {

        card.classList.add('show')
      } else {
        card.classList.remove('show')
      }
    }
  })
}

if (document.getElementById("header-video")) {
  document.addEventListener('click', function (event) {
    if (event.target.id === "header-video") {
      if (event.target.classList.contains("playing")) {
        event.target.pause()
        event.target.classList.remove("playing")

      } else {
        event.target.play()
        event.target.classList.add("playing")
      }
    }
  })
}