import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../web/ErrorMessage";
import Video from "../../files/SnapTik.ltd_1705946584.mp4";
import { Link } from "react-router-dom";
import getCookie from '../auth/Cookie';
var csrftoken = getCookie('csrftoken');
import Image from "../../files/ugcbird-long.png";
type IErrors = {
    errors?: Array<string>;
}
const Login = ({ appState, setAppAuth }) => {
    const [loginState, setLoginState] = useState({
        username: "",
        password: "",
        access: "",
        refresh: ""
    })

    const [errorState, setErrorState] = useState<IErrors>({
        errors: []
    });
    const navigate = useNavigate()
    const FieldListener = (type, event) => {
        event.persist();
        setLoginState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });

    }
    // const navigate = useNavigate();
    const submit = async e => {
        e.preventDefault();
        const user = {
            username: loginState.username,
            password: loginState.password
        };

        axios({
            headers: {
                'X-CSRFToken': csrftoken
            },
            method: 'post',
            url: process.env.API_URL + "api/token/",
            data: user
        })
            .then(function (response) {
                setAppAuth(oldstate => {
                    let response_data = response.data
                    localStorage.setItem("auth_token", response_data.token)
                    let state_result = {
                        ...oldstate,
                        auth_token: response_data.token,
                        access_token: response_data.access,
                        refresh_token: response_data.refresh,
                        isAuth: true

                    };
                    return state_result
                });
                window.location.href = "/app/"
            })
            .catch(function (error) {
                setErrorState({
                    "errors": ["Password or email incorrect"]
                })
            });
    }
    useEffect(() => {
        if (appState && appState.isAuth) {

        }

    }, [appState]);

    const displayErrors = () => {
        if (errorState.errors.length > 0) {
            return <ErrorMessage errors={errorState.errors} setErrorState={setErrorState}></ErrorMessage>;
        } else {
            return null;
        }
    };

    const DisplayImageTruckMobile = () => {
        if (window.innerWidth < 769) {
            return (
                //     <div className="image-truck-mobile">
                //     <img src={Image} className="" alt="loading..." />
                // </div>
                <video
                    controls
                    muted={false}
                    autoPlay={false}
                    className="video"
                >
                    <source src={Video} type="video/mp4" />
                </video>
            )
        }

    }

    return (
        <div className="container login">
            <div className="row login--row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="Auth-form-container">
                        <div className="Auth-form">
                            <div className="Auth-form-content">
                                <div className='logo-image'>
                                    <span className="app-brand-logo ">
                                        <img src={Image}></img>
                                    </span>
                                </div>
                                <h3 className="Auth-form-title login-title">Login to your Account</h3>
                                <h6 className="login-h6 login-title">Welcome back!</h6>
                                <div className="form-group mt-3">
                                    {/* <label>Email</label> */}
                                    <input className="form-control mt-1"
                                        placeholder="Enter email"
                                        name='username'
                                        type='text' value={loginState.username}
                                        required
                                        onChange={e => FieldListener("username", e)} />
                                </div>
                                <div className="form-group mt-3">
                                    {/* <label>Password</label> */}
                                    <input name='password'
                                        type="password"
                                        className="form-control mt-1"
                                        placeholder="Enter password"
                                        value={loginState.password}
                                        required
                                        onChange={e => FieldListener("password", e)} />
                                </div>
                                {/* <div style={{ textAlign: "center" }}>Forgot Password?</div> */}
                                <div className="d-grid gap-2 mt-3">
                                    <button onClick={e => submit(e)} type="button"
                                        className="btn btn-primary">Submit</button>
                                </div>

                                <div className="login-create-account">
                                    <Link to="/app/register/">


                                        Don't have account? Create an account
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <br></br>
                        {displayErrors()}
                    </div>

                </div>
                {window && window.innerWidth > 767 ?
                    <div className="col-6 img-container login-image-container">
                        <div className="video-mask">
                            <video
                                // autoPlay={true}  
                                // playsInline={true}
                                controls
                                muted={false}
                                autoPlay={false}
                                className="video"
                            >
                                <source src={Video} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    : null}
            </div>
        </div>
    )
}
export default Login