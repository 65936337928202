import React, { FunctionComponent } from 'react';

type IIcon = {
    icon: string;
    icon_size: number;
    type: string;
    icon_color_code: string;
    hover_text?: string;
    id?: string;
}

const Icon: FunctionComponent<IIcon> = ({ type, icon, icon_size, icon_color_code, hover_text, id }) => {

    return (
        <>
            {hover_text ?
                <div className='icon-tooltip-container'>
                    {hover_text ? <div className='icon-tooltip-text'>
                        {hover_text}
                    </div> : ""}
                    <i id={id} style={{ color: icon_color_code }} className={`icon-tooltip ${type || 'fas'} fa-${icon} fa-${icon_size}x `}></i>
                </div>
                :
                <i style={{ color: icon_color_code }} className={` ${type || 'fas'} fa-${icon} fa-${icon_size}x `}></i>

            }
        </>
    )
}

export default Icon;
