import React from 'react';
import FileUploadImage from "../../files/fileupload-UGCBird.png";
const FileUploadVideo = ({
  fileUpload,
  clearContent,
  name,
  ariaLabel,
  type,
  video,
  inputLabel
}) => {
  return (
    <div className="card-body__file_upload card-body media align-items-center">
      <div className="image-cropper image-cropper__relative">
        {video && video.url ?

          <video style={{ width: "100%" }}
            controls
            muted={false}
            autoPlay={true}
            className="video"
          >
            <source src={video.url} type="video/mp4" />
          </video>

          :
          null 

        }
      </div>
      <div className="media-body ml-4">
        {video && video.url ? null
          :
          <div className="form-group form-group__file_upload">
            {inputLabel ?
              <div className='input-label'>{inputLabel}</div>
              : null}
            <input accept=".mp4" id="file" className="inputfile" onChange={fileUpload} type={type} name={name} aria-label={ariaLabel} ></input>
            <label className="sendugcform-video-form" htmlFor="file">
              <img src={FileUploadImage}></img>
              <div className="sendugcform-video-form--button">
                Browse file
              </div>
            </label>

          </div>
        }
        {video && video.url ?
          <div id="clear" className="clear-button" onClick={clearContent}>
            Clear
          </div>
          : null}
        <div className="text-light small mt-1">
          Allowed JPG, GIF or PNG. Max size of 800K
        </div>
      </div>
    </div>
  );
};
export default FileUploadVideo;
