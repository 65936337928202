import React, { useEffect, useState } from "react"
import Input from "../web/Input"
import Select from "../web/Select";
import SocialMediaPolicyModal from "../web/SocialMediaPolicyModal";
import Icon from "../general/Icon"
import axios from "axios"
import getCookie from '../auth/Cookie';


import { Link, useNavigate } from "react-router-dom"
var csrftoken = getCookie('csrftoken');

const ProductCreate = () => {
    let currentPage
    const navigate = useNavigate()
    const [state, setState] = useState({
        "name": "",
        "worth": 0,
        "creator": ""
    })

    const [modalState, setModalState] = useState({
        modalIsOpen: false,
        id: null
    })

    const [stepState, setStepState] = useState({
        step: 1,
        continue: false
    })

    const [creatorListState, setCreatorListState] = useState({

    })



    const FieldListener = (type, event) => {
        event.persist();
        setState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }

    const CanIContinue = () => {
 
        if (stepState.step === 1) {

            axios({
                headers: {
                    'Authorization': localStorage.auth_token,
                    'X-CSRFToken': csrftoken,
                    "Content-Type": "multipart/form-data"
                },
                method: 'post',
                url: process.env.API_URL + "api/creator-product/",
                data: {
                    method: "create",
                    name: state.name,
                    worth: state.worth,
                    creator: state.creator
                }

            })
                .then(function (response) {
                    setStepState(oldstate => ({ ...oldstate, "step": stepState.step + 1, "continue": false }))
                })
                .catch(function (error) {
                    navigate("/app/something-went-wrong/")
                });



        }

    }


    useEffect(() => {
        if (stepState.step === 1) {
            if (state.name && state.worth && state.creator) {
                setStepState(oldstate => ({ ...oldstate, "continue": true }))
            }
        }
    }, [state])

    useEffect(() => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: 'get',
            url: `${process.env.API_URL}api/creator/`,
        })
            .then(function (response) {
                if (response && response.data && response.data.data) {
                    let creator_list = []
                    response.data.data.map((result, i) => {
                        creator_list.push({ "value": result.id, "label": result.name })
                    })
                    setCreatorListState({ data: creator_list })
                    setState(oldstate => {
                        let state_result = {
                            ...oldstate,
                            creator: response.data.data[0]['id'],
                        };
                        return state_result
                    });
                }
            })
            .catch(function (error) {
            });
    }, [])

    switch (stepState.step) {
        case 1:
            currentPage = <>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Product name
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("name", e)}
                                    type="text"
                                    label=""
                                    name="name"
                                    value={state.name || ""}
                                    placeholder="Enter the name of the product"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Creator
                            </div>
                            <div className="wizard--field">
                                <Select
                                    onChange={(e) => FieldListener("creator", e)}
                                    name="Creator"
                                    label={"creator"}
                                    options={creatorListState.data || []}
                                    selected={state.creator || ""}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Product worth ( Buy price of product )
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("worth", e)}
                                    type="number"
                                    label=""
                                    name="worth"
                                    value={state.worth || ''}
                                    placeholder="Enter the worth of the product"
                                />
                            </div>
                        </div>
                    </div>


                </div>

            </>
            break;

        case 2:
            currentPage = <>
                <div style={{ textAlign: "center" }}>
                    <Link to="/app/product">
                        <div style={{ margin: "auto", marginTop: "20px", width: "250px" }} className="button-continue">Return to products</div>
                    </Link>
                </div>

            </>
            break;

    }

    return (
        <>
            <div className="row">
                <div className="col-12">

                    <Link to={`/app/product/`} style={{ marginBottom: "20px" }}>
                        <span className="">
                            <Icon
                                type={"fa"}
                                icon={"chevron-left"}
                                icon_size={1}

                                icon_color_code={"#e3def9"}
                            ></Icon></span>
                        Back
                    </Link>

                    {modalState && modalState.modalIsOpen ? <SocialMediaPolicyModal text={state.social_media_policy} setModalState={setModalState}></SocialMediaPolicyModal> : null}
                </div>
                <div className="col-12">
                    <hr></hr>
                    <div className="row">
                        <div className="col-12">
                            <div className="progress-title">
                                {stepState.step < 4 ?
                                    <>
                                        <div className="progress-header">Create product</div>
                                    </>
                                    :
                                    <>
                                        <div className="progress-header">Congratz you created your product!</div>
                                    </>

                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ margin: "auto" }}>
                            {currentPage}
                        </div>
                    </div>
                    {stepState.step !== 4 ?
                        <div className="row">
                            <div className="col-8 flexer">
                                <div onClick={() => { setStepState({ "step": stepState.step > 1 ? stepState.step - 1 : stepState.step, "continue": stepState.step > 1 ? true : stepState.continue }) }} className="button-back">back</div>

                                <div onClick={() => { CanIContinue() }} className={`button-continue button-continue_${stepState.continue}`}>Continue</div>
                            </div>

                        </div>
                        : null
                    }
                </div>
            </div>
        </>

    )
}
export default ProductCreate;