import React from 'react';
import FileUploadImage from "../../files/fileupload-UGCBird.png";

const FileUpload = ({
  fileUpload,
  clearContent,
  name,
  ariaLabel,
  type,
  image,
  inputLabel
}) => {
  return (
    <div className="card-body__file_upload card-body media align-items-center">

      {image && image.url ?
        <div className="brand-logo-upload">
          <img
            src={image.url}
            alt=""
            className=""
          ></img>
        </div>
        :
        null

      }

      <div className="media-body ml-4">
        {image && image.url ? null
          :
          <div className="form-group form-group__file_upload">
            {inputLabel ?
              <div className='input-label'>{inputLabel}</div>
              : null}
            <input accept=".png, .jpg, .jpeg" id="file" className="inputfile" onChange={fileUpload} type={type} name={name} aria-label={ariaLabel} ></input>
            <label className="sendugcform-video-form" htmlFor="file">
              <img src={FileUploadImage}></img>
              <div className="sendugcform-video-form--button">
                Browse file
              </div>
            </label>

          </div>
        }
        <div id="clear" className="clear-button" onClick={clearContent}>
          Clear
        </div>
        {/* <div className="text-light small mt-1">
          Allowed JPG, GIF or PNG. Max size of 800K
        </div> */}
      </div>
    </div>
  );
};
export default FileUpload;
