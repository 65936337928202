import React, { useState, useEffect } from "react";
import TableHead from "../table/Tablehead";
import TableRowCampaign from "../table/TableRowCampaign";
import axios from "axios";
import Pagination from "../pagination/Pagination";
import Select from "../web/Select";
import Search from "../web/Search";
import Icon from "../general/Icon";
import getCookie from '../auth/Cookie';
var csrftoken = getCookie('csrftoken');
import { Link, useOutletContext } from "react-router-dom";
import DeleteModal from "../web/DeleteModal";
import HitLimitModal from "../web/HitLimitModal";
import CopyToClipBoardModal from "../web/ReasonModal";
const data = [
    { label: "1", value: "1" },
    { label: "5", value: "5" },
    { label: "10", value: "10" },
    { label: "50", value: "50" },
]
const Campaign = () => {
    let api_url = `${process.env.API_URL}api/campaign/`
    let context = useOutletContext()
    const [campaignState, setcampaignState] = useState({
        next: null,
        previous: null,
        count: 0,
        num_pages: [],
        data: [],
        headers: [],
        url: `${process.env.API_URL}api/campaign/`

    })

    const [urlState, setUrlState] = useState({
        url: `${process.env.API_URL}api/campaign/`
    })


    const [orderingState, setOrderingState] = useState({
        order: null,
        sort: null,
    })

    const [showEntriesState, setShowEntriesState] = useState({
        show: 10
    })

    const [searchState, setSearchState] = useState({
        input: null
    })

    const [modalState, setModalState] = useState({
        modalIsOpen: false,
        id: null,
        type: null,
    })


    const showEntries = (event) => {
        setShowEntriesState({ 'show': event.target.value })
        let url = null
        if (!urlState.url.includes("size") && !urlState.url.includes("ordering")) {
            url = urlState.url + '?size=' + event.target.value
        }
        else if (urlState.url && urlState.url.includes("ordering") && !urlState.url.includes("size")) {
            url = urlState.url + "&size=" + event.target.value
        } else if (urlState.url && urlState.url.includes("size")) {
            url = urlState.url.replace(/[0-9]+(?!.*[0-9])/, event.target.value)
        }
        axios({
            method: 'get',
            url: url,
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            }
        })
            .then(function (response) {
                setcampaignState(response.data)
            })
    }


    const onSearch = () => {
        if (searchState && searchState.input) {
            let url = null
            if (urlState.url && !urlState.url.includes("search")) {
                url = urlState.url + '?search=' + searchState.input
            } else if (campaignState && urlState.url.includes("search")) {
                url = urlState.url.replace(/(?:search=)(\w+)/, searchState.input)
            }
            axios({
                method: 'get',
                url: url,
                headers: {
                    'Authorization': localStorage.auth_token,
                    'X-CSRFToken': csrftoken
                }
            })
                .then(function (response) {
                    setcampaignState(response.data)
                })
        } else {
            axios({
                method: 'get',
                url: `${process.env.API_URL}api/campaign/`,
                headers: {
                    'Authorization': localStorage.auth_token,
                    'X-CSRFToken': csrftoken
                }
            })
                .then(function (response) {
                    setcampaignState(response.data)
                })
        }
    }

    const searchListener = (event) => {
        setSearchState({
            "input": event.target.value
        })
    }

    const deleteEntry = () => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: 'post',
            url: `${process.env.API_URL}api/campaign/`,
            data: {
                id: modalState.id,
                method: "delete"
            }
        })
            .then(function (response) {
                setModalState({
                    modalIsOpen: false,
                    id: null,
                    type: null
                })
                setcampaignState(oldstate => {
                    let response_data = response.data
                    let paginated_results = {
                        ...oldstate,
                        next: response_data.next,
                        previous: response_data.previous,
                        count: response_data.count,
                        page_active: response_data.page_active,
                        num_pages: response_data.num_pages,
                        data: response_data.data,
                        headers: [],
                        limit: 5,
                        ugc_count: response_data.ugc_count,
                        send_video_rate: response_data.send_video_rate,
                        open_rate: response_data.open_rate,
                        active_campaigns: response_data.active_campaigns

                    };
                    return paginated_results
                });

            })
            .catch(function (error) {
            });

    }

    const openDeleteModal = (id) => {
        setModalState({
            modalIsOpen: true,
            id: id,
            type: "delete"
        })
    }

    const ImIAllowed = () => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: 'post',
            url: process.env.API_URL + "api/im-i-allowed/",
            data: {
                type: "campaign"
            }
        })
            .then(function (response) {
                window.location.href = "/app/campaign/create/"

            })
            .catch(function (error) {
                setModalState({
                    modalIsOpen: true,
                    id: null,
                    type: "hit_limit",
                    message: error.response.data.message
                })
            })
    }

    useEffect(() => {
        if (!context.appRenderState.userData.google_token) {
            setModalState(oldstate => ({ ...oldstate, modalIsOpen: true }))
        }
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: 'get',
            url: `${process.env.API_URL}api/campaign/`,
        })
            .then(function (response) {
                setcampaignState(oldstate => {
                    let response_data = response.data
                    let paginated_results = {
                        ...oldstate,
                        next: response_data.next,
                        previous: response_data.previous,
                        count: response_data.count,
                        page_active: response_data.page_active,
                        num_pages: response_data.num_pages,
                        data: response_data.data,
                        headers: [],
                        limit: 5,
                        ugc_count: response_data.ugc_count,
                        send_video_rate: response_data.send_video_rate,
                        open_rate: response_data.open_rate,
                        active_campaigns: response_data.active_campaigns

                    };
                    return paginated_results
                });

            })
            .catch(function (error) {
            });
    }, [])

    return (
        <>
            {context.appRenderState && context.appRenderState.userData && context.appRenderState.userData.google_token ?
                <>
                    {modalState && modalState.modalIsOpen && modalState.type === "delete" ? <DeleteModal deleteEntry={deleteEntry} setModalState={setModalState} message={"Are you sure you would like to delete the campaign"}></DeleteModal> : null}
                    {modalState && modalState.modalIsOpen && modalState.type === "hit_limit" ? <HitLimitModal setModalState={setModalState} message={modalState.message}></HitLimitModal> : null}
                    {/* {modalState && modalState.modalIsOpen && modalState.type === "copy_to_clipboard" ? <CopyToClipBoardModal setModalState={setModalState}></CopyToClipBoardModal> : null} */}
                    <div className="row four-blocks">
                        <div className="col-sm-6 col-xl-3">
                            <div className="card" style={{ padding: "20px", borderRadius: "20px" }}>
                                <div className="creatorlist-statistics-content">
                                    <div>
                                        <div style={{ fontWeight: 600 }}>UGC's</div>
                                        <div><span className="creatorlist-count">{campaignState.ugc_count || 0}</span></div>
                                        <div></div>
                                    </div>
                                    <div className="creatorlist-statistics-active-fourth">
                                        <svg className="svg-purple" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <g clipPath="url(#clip0_1_441)">
                                                <path d="M14 7V0.46C14.9251 0.809335 15.7653 1.35131 16.465 2.05L19.949 5.536C20.6485 6.23488 21.1909 7.07489 21.54 8H15C14.7348 8 14.4804 7.89464 14.2929 7.70711C14.1054 7.51957 14 7.26522 14 7ZM22 10.485V19C21.9984 20.3256 21.4711 21.5964 20.5338 22.5338C19.5964 23.4711 18.3256 23.9984 17 24H7C5.67441 23.9984 4.40356 23.4711 3.46622 22.5338C2.52888 21.5964 2.00159 20.3256 2 19V5C2.00159 3.67441 2.52888 2.40356 3.46622 1.46622C4.40356 0.528882 5.67441 0.00158786 7 0L11.515 0C11.678 0 11.839 0.013 12 0.024V7C12 7.79565 12.3161 8.55871 12.8787 9.12132C13.4413 9.68393 14.2044 10 15 10H21.976C21.987 10.161 22 10.322 22 10.485ZM14 19C14 18.7348 13.8946 18.4804 13.7071 18.2929C13.5196 18.1054 13.2652 18 13 18H8C7.73478 18 7.48043 18.1054 7.29289 18.2929C7.10536 18.4804 7 18.7348 7 19C7 19.2652 7.10536 19.5196 7.29289 19.7071C7.48043 19.8946 7.73478 20 8 20H13C13.2652 20 13.5196 19.8946 13.7071 19.7071C13.8946 19.5196 14 19.2652 14 19ZM17 15C17 14.7348 16.8946 14.4804 16.7071 14.2929C16.5196 14.1054 16.2652 14 16 14H8C7.73478 14 7.48043 14.1054 7.29289 14.2929C7.10536 14.4804 7 14.7348 7 15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16H16C16.2652 16 16.5196 15.8946 16.7071 15.7071C16.8946 15.5196 17 15.2652 17 15Z" fill="#867BBA" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1_441">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="card" style={{ padding: "20px", borderRadius: "20px" }}>
                                <div className="creatorlist-statistics-content">
                                    <div>
                                        <div style={{ fontWeight: 600 }}>Open Rate</div>
                                        <div><span className="creatorlist-count">{campaignState.open_rate || 0}</span></div>
                                        <div></div>
                                    </div>
                                    <div className="creatorlist-statistics-active-platform">
                                        <svg className="svg-purple" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M1.32701 12.4L4.88701 15L3.53501 19.187C3.31652 19.8364 3.31375 20.539 3.52712 21.1901C3.74048 21.8412 4.15856 22.4059 4.71901 22.8C5.26986 23.2068 5.9374 23.4247 6.62216 23.4213C7.30693 23.4178 7.97225 23.1933 8.51901 22.781L12 20.219L15.482 22.778C16.0319 23.1824 16.6958 23.4021 17.3784 23.4054C18.061 23.4088 18.727 23.1956 19.2808 22.7965C19.8345 22.3974 20.2475 21.833 20.4603 21.1845C20.6731 20.5359 20.6747 19.8366 20.465 19.187L19.113 15L22.673 12.4C23.2222 11.9985 23.6304 11.4337 23.8394 10.7864C24.0483 10.139 24.0474 9.44217 23.8367 8.79537C23.626 8.14858 23.2162 7.58491 22.666 7.18489C22.1158 6.78487 21.4533 6.56895 20.773 6.56799H16.4L15.073 2.43198C14.8643 1.78094 14.4543 1.213 13.902 0.81005C13.3497 0.407102 12.6837 0.189972 12 0.189972C11.3163 0.189972 10.6503 0.407102 10.098 0.81005C9.54574 1.213 9.13569 1.78094 8.92701 2.43198L7.60001 6.56799H3.23101C2.55075 6.56895 1.8882 6.78487 1.33799 7.18489C0.787778 7.58491 0.378051 8.14858 0.167331 8.79537C-0.0433882 9.44217 -0.0443208 10.139 0.164666 10.7864C0.373654 11.4337 0.781871 11.9985 1.33101 12.4H1.32701Z" fill="#867BBA" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="card" style={{ padding: "20px", borderRadius: "20px" }}>
                                <div className="creatorlist-statistics-content">
                                    <div>
                                        <div style={{ fontWeight: 600 }}>Sent Video Rate</div>
                                        <div><span className="creatorlist-count">{campaignState.send_video_rate || 0}</span></div>
                                        <div></div>
                                    </div>
                                    <div className="creatorlist-statistics-active-third">
                                        <svg className="svg-purple" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <g clipPath="url(#clip0_1_668)">
                                                <path d="M5 20H13C15.757 20 18 17.757 18 15V9C18 6.243 15.757 4 13 4H5C2.243 4 0 6.243 0 9V15C0 17.757 2.243 20 5 20ZM24 8.313V15.632C24 16.389 23.58 17.069 22.903 17.407C22.62 17.548 22.316 17.618 22.014 17.618C21.593 17.618 21.177 17.483 20.825 17.219C20.788 17.191 20 16.409 20 16.409V7.544C20 7.544 20.786 6.755 20.825 6.726C21.429 6.272 22.224 6.199 22.903 6.538C23.582 6.877 24 7.556 24 8.313Z" fill="#867BBA" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1_668">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="card" style={{ padding: "20px", borderRadius: "20px" }}>
                                <div className="creatorlist-statistics-content">
                                    <div>
                                        <div style={{ fontWeight: 600 }}>Active Campaigns</div>
                                        <div><span className="creatorlist-count">{campaignState.active_campaigns || 0}</span></div>
                                        <div></div>
                                    </div>
                                    <div className="creatorlist-statistics-active-creator">
                                        <svg className="svg-purple" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <g clipPath="url(#clip0_1_670)">
                                                <path d="M19 4H17.9C17.6679 2.87141 17.0538 1.85735 16.1613 1.12872C15.2687 0.40009 14.1522 0.00145452 13 0L11 0C9.8478 0.00145452 8.73132 0.40009 7.83875 1.12872C6.94618 1.85735 6.3321 2.87141 6.1 4H5C3.67441 4.00159 2.40356 4.52888 1.46622 5.46622C0.528882 6.40356 0.00158786 7.67441 0 9L0 12H24V9C23.9984 7.67441 23.4711 6.40356 22.5338 5.46622C21.5964 4.52888 20.3256 4.00159 19 4ZM8.184 4C8.39008 3.41709 8.77123 2.91209 9.2753 2.55409C9.77937 2.19608 10.3817 2.00256 11 2H13C13.6183 2.00256 14.2206 2.19608 14.7247 2.55409C15.2288 2.91209 15.6099 3.41709 15.816 4H8.184Z" fill="#867BBA" />
                                                <path d="M13 15C13 15.2652 12.8946 15.5196 12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16C11.7348 16 11.4804 15.8946 11.2929 15.7071C11.1054 15.5196 11 15.2652 11 15V14H0V19C0.00158786 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.67441 23.9984 5 24H19C20.3256 23.9984 21.5964 23.4711 22.5338 22.5338C23.4711 21.5964 23.9984 20.3256 24 19V14H13V15Z" fill="#867BBA" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1_670">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className={`${modalState && modalState.modalIsOpen ? "row no-click" : "row"}`}>
                        <div className="col-creator">
                            <div className="card mb-4 border-top-left-right">
                                <div className="card-header" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h4 style={{
                                        paddingRight: "10px",
                                        marginBottom: "0px",
                                        fontSize: "20px"
                                    }}>Campaigns</h4>
                                    {/* <Link to="/app/campaign/create/"> */}
                                    <div onClick={() => { ImIAllowed() }} className="create-campaign">Create Campaign</div>
                                    {/* </Link> */}
                                </div>
                                <div className="card-body" style={{ padding: '0px' }}>
                                    <div className="row"
                                        style={{ margin: '15px', marginBottom: '0px' }}>
                                        <div className="col-sm-6"
                                            style={{ padding: '0px' }}
                                        >
                                            <div className="row">
                                                {/* <div className="select-container col-sm-2">
                                                    <Select
                                                        onChange={showEntries}
                                                        name="show"
                                                        label={null}
                                                        options={data || []}
                                                        selected={showEntriesState.show}
                                                    />
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-sm-6"
                                            style={{ padding: '0px' }}
                                        >
                                            {/* <div className="row" style={{ justifyContent: 'flex-end' }}>
                                                <div className="search-container" style={{ display: "flex" }}>
                                                    <Search
                                                        placeholder={"Search"}
                                                        aria_label={"search"}
                                                        onChange={(e) => searchListener(e)}
                                                        value={searchState.input || ""}
                                                    >
                                                    </Search>
                                                    <div onClick={onSearch} className="button-regular">Search</div>
                                                </div>
                                            </div> */}
                                        </div>

                                    </div>
                                    <div className="table-responsive">
                                        <table className="table ">
                                            <thead>
                                                <tr>
                                                    {
                                                        [
                                                            { "name": "campaign", "verbose_name": "Campaign" },
                                                            { "name": "status", "verbose_name": "Status" },
                                                            { "name": "open_rate", "verbose_name": "Open rate" },
                                                            { "name": "send_video_rate", "verbose_name": "Sent video rate" },
                                                            {"name": "pending_count", "verbose_name": "Pending UGC's"},
                                                            { "name": "action", "verbose_name": "Action" }
                                                        ].map(
                                                            header => (
                                                                <TableHead
                                                                    key={header.name}
                                                                    head={header.name}
                                                                    verbose_name={header.verbose_name || header.name}
                                                                    onClick={"orderingListener"}
                                                                    sort={orderingState.sort || 'ascend'}
                                                                ></TableHead>
                                                            )
                                                        )}
                                                </tr>
                                            </thead>
                                            <tbody style={{ verticalAlign: "middle" }}>
                                                {
                                                    campaignState && campaignState.data ?

                                                        campaignState.data.map(
                                                            (result, i) => (
                                                                <TableRowCampaign
                                                                    key={i}
                                                                    columns={result}
                                                                    openDeleteModal={openDeleteModal}
                                                                    setModalState={setModalState}
                                                                ></TableRowCampaign>
                                                            )
                                                        )


                                                        : null
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div
                                            className="col-sm-6"
                                            style={{
                                                margin: 'auto',
                                                width: '50%'
                                            }}
                                        >
                                            {/* <span>Showing 1 to {showEntriesState.show} of {campaignState.count} entries</span> */}
                                        </div>
                                        <div className="col-sm-6"
                                            style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Pagination
                                                api_url={api_url}
                                                state={campaignState}
                                                setState={setcampaignState}

                                            ></Pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : <>
                    {modalState && modalState.modalIsOpen ?

                        <div className='modal-background'>
                            <div className='card generalcard' style={{ width: "600px", borderRadius: "20px" }}>
                                <div className="container-right-icon">
                                    <Link to="/app/settings/" onClick={() => { (setModalState({ modalIsOpen: false, type: null, id: null }), context.setAppState(oldstate => ({ ...oldstate, settingTab: "integration" }))) }}>
                                        <div  className="right-icon">
                                            <Icon
                                                type={"fa"}
                                                icon={"xmark"}
                                                icon_size={2}
                                                icon_color_code={"#6d6f71"}
                                            ></Icon>
                                        </div>
                                    </Link>
                                </div>
                                <div className="modal-title" style={{ paddingBottom: "5px", fontSize: "18px" }}>
                                    Looks like you don't have an integration with Google yet.
                                </div>
                                <div className="modal-description center-div">
                                    <Link to="/app/settings/" onClick={() => { (setModalState({ modalIsOpen: false, type: null, id: null }), context.setAppState(oldstate => ({ ...oldstate, settingTab: "integration" }))) }}>
                                        <div style={{ margin: "auto", marginTop: "20px", width: "250px" }} className="button-continue">Connect to google</div>
                                    </Link>
                                </div>
                            </div>
                        </div> : <></>}
                </>}

        </>
    )
}
export default Campaign;