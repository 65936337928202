import React, { useState, useEffect, useRef } from "react";
import TableHead from "../table/Tablehead";
import TableRowJobAplications from "../table/TableRowJobAplications";
import axios from "axios";
import Pagination from "../pagination/Pagination";
import Select from "../web/Select";
import Search from "../web/Search";
import Icon from "../general/Icon";
import getCookie from '../auth/Cookie';
import ReasonModal from "../web/ReasonModal";
import ErrorModal from "../web/ErrorModal";
import CreateRelationModal from "../web/CreateRelationModal";
var csrftoken = getCookie('csrftoken');
import { Link, useOutletContext } from "react-router-dom";
const JobDetail = () => {
    const ObjNum = () => {
        const str = window.location.href
        if (str.split("/")) {
            if (str.endsWith("content/")) {
                return str.split("/").at(-3)
            }
            else if (str.split("/")) {
                return str.split("/").at(-2)
            }
        }
    }
    let api_url = `${process.env.API_URL}api/job/${ObjNum()}/job_application/`
    const [state, setState] = useState({
        next: null,
        previous: null,
        count: 0,
        num_pages: [],
        data: [],
        headers: [],
        url: `${process.env.API_URL}api/job/${ObjNum()}/job-application/`

    })


    const [orderingState, setOrderingState] = useState({
        order: null,
        sort: null,
    })


    const [searchState, setSearchState] = useState({
        input: null,
        accept_gifted: null,
        accept_barter: null

    })

    const [modalState, setModalState] = useState({
        modalIsOpen: false,
        id: null,
        type: null,
    })

    const FieldSelectListener = (type, event) => {
        event.persist();
        if (event.target.value === "null") {
            event.target.value = ""
        }
        setSearchState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }

    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current) {
            let query_url = `${process.env.API_URL}api/job/${ObjNum()}/job-application/?`
            if (!query_url.includes("&accept_gifted") && searchState.accept_gifted) {
                query_url = query_url + `&accept_gifted=${searchState.accept_gifted}`
            }
            if (!query_url.includes("&accept_barter") && searchState.accept_barter) {
                query_url = query_url + `&accept_barter=${searchState.accept_barter}`
            }
            axios({
                headers: {
                    'Authorization': localStorage.auth_token,
                    'X-CSRFToken': csrftoken
                },
                method: 'get',
                url: query_url,
            })
                .then(function (response) {
                    if (response.data) {
                        setState(response.data)
                    }
                })
                .catch(function (error) {
                });
        } else {
            didMount.current = true;
        }
    }, [searchState])

    useEffect(() => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: 'get',
            url: `${process.env.API_URL}api/job/${ObjNum()}/job-application/`,
        })
            .then(function (response) {
                if (response.data) {
                    setState(response.data)
                }
            })
            .catch(function (error) {
            });
    }, [])

    const createRelation = () => {
        let data = null
        if (state && state.data) {
            for (const job_application in state.data) {
                if (state.data[job_application] && state.data[job_application].id === modalState.job_application_id) {
                    data = state.data[job_application]
                }
            }
        }
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: 'post',
            url: `${process.env.API_URL}api/job-application-to-creator/`,
            data: {
                "id": data.id,
                "job": data.job,
                "email": data.email,
                "tiktok": data.tiktok,
                "instagram": data.instagram,
                "facebook": data.facebook,
                "can_recieve_email": data.can_recieve_email,
                "accept_gifted": data.accept_gifted,
                "accept_barter": data.barter,
                "older_then_18": data.older_then_18,
                "relation": true
            }
        })
            .then(function (response) {
                setModalState({ modalIsOpen: false, type: "", id: null })
                axios({
                    headers: {
                        'Authorization': localStorage.auth_token,
                        'X-CSRFToken': csrftoken
                    },
                    method: 'get',
                    url: `${process.env.API_URL}api/job/${ObjNum()}/job-application/`,
                })
                    .then(function (response) {
                        if (response.data) {
                            setState(response.data)
                        }
                    })
                    .catch(function (error) {
                        setModalState({ modalIsOpen: true, type: "error_message", id: null })
                    });
            })
            .catch(function (error) {
                setModalState({ modalIsOpen: true, type: "error_message", id: null })
            });
    }


    const headerIcon = (name) => {
        if (name === "reason") {
            return (
                <div className="table-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16" fill="none">
                        <g clipPath="url(#clip0_39_118)">
                            <path d="M12.6667 0.666626H3.33333C1.49533 0.666626 0 2.16196 0 3.99996V12C0 13.838 1.49533 15.3333 3.33333 15.3333H12.6667C14.5047 15.3333 16 13.838 16 12V3.99996C16 2.16196 14.5047 0.666626 12.6667 0.666626ZM4.33333 12.3333C3.78133 12.3333 3.33333 11.8853 3.33333 11.3333C3.33333 10.7813 3.78133 10.3333 4.33333 10.3333C4.88533 10.3333 5.33333 10.7813 5.33333 11.3333C5.33333 11.8853 4.88533 12.3333 4.33333 12.3333ZM4.33333 8.99996C3.78133 8.99996 3.33333 8.55196 3.33333 7.99996C3.33333 7.44796 3.78133 6.99996 4.33333 6.99996C4.88533 6.99996 5.33333 7.44796 5.33333 7.99996C5.33333 8.55196 4.88533 8.99996 4.33333 8.99996ZM4.33333 5.66663C3.78133 5.66663 3.33333 5.21863 3.33333 4.66663C3.33333 4.11463 3.78133 3.66663 4.33333 3.66663C4.88533 3.66663 5.33333 4.11463 5.33333 4.66663C5.33333 5.21863 4.88533 5.66663 4.33333 5.66663ZM12 12H7.33333C6.96533 12 6.66667 11.7013 6.66667 11.3333C6.66667 10.9653 6.96533 10.6666 7.33333 10.6666H12C12.368 10.6666 12.6667 10.9653 12.6667 11.3333C12.6667 11.7013 12.368 12 12 12ZM12 8.66663H7.33333C6.96533 8.66663 6.66667 8.36796 6.66667 7.99996C6.66667 7.63196 6.96533 7.33329 7.33333 7.33329H12C12.368 7.33329 12.6667 7.63196 12.6667 7.99996C12.6667 8.36796 12.368 8.66663 12 8.66663ZM12 5.33329H7.33333C6.96533 5.33329 6.66667 5.03463 6.66667 4.66663C6.66667 4.29863 6.96533 3.99996 7.33333 3.99996H12C12.368 3.99996 12.6667 4.29863 12.6667 4.66663C12.6667 5.03463 12.368 5.33329 12 5.33329Z" fill="#d1c6e9" />
                        </g>
                        <defs>
                            <clipPath id="clip0_39_118">
                                <rect width="25" height="25" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            )
        } else if (name === "can_recieve_email") {
            return (
                <div className="table-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16" fill="none">
                        <g clipPath="url(#clip0_39_120)">
                            <path d="M15.9693 3.6947L10.3573 9.3067C9.73158 9.93087 8.88383 10.2814 8 10.2814C7.11617 10.2814 6.26842 9.93087 5.64267 9.3067L0.0306667 3.6947C0.0213333 3.80004 0 3.89537 0 4.00004V12C0.00105857 12.8838 0.352588 13.731 0.97748 14.3559C1.60237 14.9808 2.4496 15.3323 3.33333 15.3334H12.6667C13.5504 15.3323 14.3976 14.9808 15.0225 14.3559C15.6474 13.731 15.9989 12.8838 16 12V4.00004C16 3.89537 15.9787 3.80004 15.9693 3.6947Z" fill="#d1c6e9" />
                            <path d="M9.41464 8.36396L15.504 2.27396C15.209 1.78484 14.7929 1.37998 14.296 1.09845C13.799 0.816908 13.2378 0.668186 12.6666 0.666626H3.33331C2.76212 0.668186 2.20097 0.816908 1.70399 1.09845C1.20701 1.37998 0.790957 1.78484 0.495972 2.27396L6.58531 8.36396C6.96102 8.73817 7.46969 8.94827 7.99997 8.94827C8.53025 8.94827 9.03892 8.73817 9.41464 8.36396Z" fill="#d1c6e9" />
                        </g>
                        <defs>
                            <clipPath id="clip0_39_120">
                                <rect width="25" height="25" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            )
        } else if (name === "older_than_18") {
            return (
                <div className="table-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16" fill="none">
                        <g clipPath="url(#clip0_39_123)">
                            <path d="M7.66667 7.16667C7.25333 7.16667 6.91667 6.83 6.91667 6.41667C6.91667 6.00333 7.25333 5.66667 7.66667 5.66667C8.08 5.66667 8.41667 6.00333 8.41667 6.41667C8.41667 6.83 8.08 7.16667 7.66667 7.16667ZM16 8C16 12.4113 12.4113 16 8 16C3.58867 16 0 12.4113 0 8C0 3.58867 3.58867 0 8 0C12.4113 0 16 3.58867 16 8ZM4 4.66667H3.008L1.33067 6.518L2.31867 7.41267L2.666 7.02933V11.3333H3.99933L4 4.66667ZM9.91667 9.41667C9.91667 8.778 9.64667 8.204 9.21733 7.794C9.54467 7.426 9.75 6.94667 9.75 6.41667C9.75 5.268 8.81533 4.33333 7.66667 4.33333C6.518 4.33333 5.58333 5.268 5.58333 6.41667C5.58333 6.94667 5.78867 7.426 6.116 7.794C5.68733 8.204 5.41667 8.778 5.41667 9.41667C5.41667 10.6573 6.426 11.6667 7.66667 11.6667C8.90733 11.6667 9.91667 10.6573 9.91667 9.41667ZM14.6667 8.66667V7.33333H13.3333V6H12V7.33333H10.6667V8.66667H12V10H13.3333V8.66667H14.6667ZM7.66667 8.5C7.16133 8.5 6.75 8.91133 6.75 9.41667C6.75 9.922 7.16133 10.3333 7.66667 10.3333C8.172 10.3333 8.58333 9.922 8.58333 9.41667C8.58333 8.91133 8.172 8.5 7.66667 8.5Z" fill="#d1c6e9" />
                        </g>
                        <defs>
                            <clipPath id="clip0_39_123">
                                <rect width="25" height="25" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            )
        } else if (name === "accept_gifted") {
            return (
                <div className="table-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16" fill="none">
                        <g clipPath="url(#clip0_39_125)">
                            <path d="M1.33333 10H7.33333V16H4.66667C3.78261 16 2.93477 15.6488 2.30964 15.0237C1.68452 14.3986 1.33333 13.5507 1.33333 12.6667V10ZM16 7.33333C16 7.68696 15.8595 8.02609 15.6095 8.27614C15.3594 8.52619 15.0203 8.66667 14.6667 8.66667H8.66667V5.97133C8.44267 5.98867 8.21933 6 8 6C7.78067 6 7.55733 5.98867 7.33333 5.97133V8.66667H1.33333C0.979711 8.66667 0.640573 8.52619 0.390524 8.27614C0.140476 8.02609 0 7.68696 0 7.33333C0 6.62609 0.280952 5.94781 0.781049 5.44772C1.28115 4.94762 1.95942 4.66667 2.66667 4.66667H3.82533C3.4484 4.3344 3.1492 3.92325 2.94896 3.4624C2.74872 3.00154 2.65234 2.50227 2.66667 2C2.66667 1.82319 2.7369 1.65362 2.86193 1.5286C2.98695 1.40357 3.15652 1.33333 3.33333 1.33333C3.51014 1.33333 3.67971 1.40357 3.80474 1.5286C3.92976 1.65362 4 1.82319 4 2C4 3.748 5.58067 4.35333 6.78267 4.56067C6.33901 3.77404 6.07194 2.90025 6 2C6 1.46957 6.21071 0.960859 6.58579 0.585786C6.96086 0.210714 7.46957 0 8 0C8.53043 0 9.03914 0.210714 9.41421 0.585786C9.78929 0.960859 10 1.46957 10 2C9.92806 2.90025 9.66099 3.77404 9.21733 4.56067C10.4193 4.35333 12 3.748 12 2C12 1.82319 12.0702 1.65362 12.1953 1.5286C12.3203 1.40357 12.4899 1.33333 12.6667 1.33333C12.8435 1.33333 13.013 1.40357 13.1381 1.5286C13.2631 1.65362 13.3333 1.82319 13.3333 2C13.3477 2.50227 13.2513 3.00154 13.051 3.4624C12.8508 3.92325 12.5516 4.3344 12.1747 4.66667H13.3333C14.0406 4.66667 14.7189 4.94762 15.219 5.44772C15.719 5.94781 16 6.62609 16 7.33333ZM7.33333 2C7.41381 2.70855 7.64085 3.39261 8 4.00867C8.35915 3.39261 8.58619 2.70855 8.66667 2C8.66667 1.82319 8.59643 1.65362 8.47141 1.5286C8.34638 1.40357 8.17681 1.33333 8 1.33333C7.82319 1.33333 7.65362 1.40357 7.5286 1.5286C7.40357 1.65362 7.33333 1.82319 7.33333 2ZM8.66667 16H11.3333C11.7711 16 12.2045 15.9138 12.6089 15.7463C13.0134 15.5788 13.3808 15.3332 13.6904 15.0237C13.9999 14.7142 14.2454 14.3467 14.4129 13.9423C14.5804 13.5379 14.6667 13.1044 14.6667 12.6667V10H8.66667V16Z" fill="#d1c6e9" />
                        </g>
                        <defs>
                            <clipPath id="clip0_39_125">
                                <rect width="25" height="25" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            )
        } else if (name === "accept_barter") {
            return (
                <div className="table-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16" fill="none">
                        <g clipPath="url(#clip0_39_127)">
                            <path d="M8.03733 7.47404L13.3967 11.9527L9.60067 14.8C8.65267 15.5114 7.34867 15.5114 6.40067 14.8L2.312 11.7334C1.966 11.474 1.54467 11.3334 1.112 11.3334H0.667333C0.298667 11.3334 0 11.0347 0 10.6667V3.29137C0 2.95271 0.252667 2.67071 0.589333 2.63271C1.494 2.53204 2.30867 2.16871 3.142 1.68337C4.34067 1.06671 5.91333 1.28671 6.886 2.19804L7.29733 2.59337L4.626 5.20071C3.91067 5.91537 3.79667 7.04537 4.36067 7.82604C4.70733 8.30804 5.33467 8.66537 5.99 8.66537C6.51867 8.66537 7.026 8.45737 7.39267 8.09004L8.03733 7.47337V7.47404ZM13.3027 1.68337C12.176 1.12004 10.7707 1.26671 9.764 2.04871L5.562 6.15004C5.31467 6.39804 5.25867 6.79471 5.44133 7.04804C5.56133 7.21471 5.73533 7.31471 5.934 7.33137C6.13067 7.34804 6.32133 7.27671 6.45933 7.13804L8.87133 4.85204C9.504 4.25137 10.4207 5.21004 9.79333 5.81537L9.006 6.54671L14.7347 11.334H15.3327C15.7007 11.334 15.9993 11.0354 15.9993 10.6674V3.26871C15.9993 2.94204 15.7607 2.67204 15.4387 2.61404C14.3093 2.41004 13.302 1.68404 13.302 1.68404L13.3027 1.68337Z" fill="#d1c6e9" />
                        </g>
                        <defs>
                            <clipPath id="clip0_39_127">
                                <rect width="25" height="25" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            )
        } else if (name === "created") {
            return (
                <div className="table-icon">
                    <Icon
                        type={"fa"}
                        icon={"clock"}
                        icon_size={1}

                        icon_color_code={"#d1c6e9"}
                    ></Icon>
                </div>
            )
        }
        else if (name === "create_relation") {
            return (
                <div className="table-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16" fill="none">
                        <g clipPath="url(#clip0_39_127)">
                            <path d="M8.03733 7.47404L13.3967 11.9527L9.60067 14.8C8.65267 15.5114 7.34867 15.5114 6.40067 14.8L2.312 11.7334C1.966 11.474 1.54467 11.3334 1.112 11.3334H0.667333C0.298667 11.3334 0 11.0347 0 10.6667V3.29137C0 2.95271 0.252667 2.67071 0.589333 2.63271C1.494 2.53204 2.30867 2.16871 3.142 1.68337C4.34067 1.06671 5.91333 1.28671 6.886 2.19804L7.29733 2.59337L4.626 5.20071C3.91067 5.91537 3.79667 7.04537 4.36067 7.82604C4.70733 8.30804 5.33467 8.66537 5.99 8.66537C6.51867 8.66537 7.026 8.45737 7.39267 8.09004L8.03733 7.47337V7.47404ZM13.3027 1.68337C12.176 1.12004 10.7707 1.26671 9.764 2.04871L5.562 6.15004C5.31467 6.39804 5.25867 6.79471 5.44133 7.04804C5.56133 7.21471 5.73533 7.31471 5.934 7.33137C6.13067 7.34804 6.32133 7.27671 6.45933 7.13804L8.87133 4.85204C9.504 4.25137 10.4207 5.21004 9.79333 5.81537L9.006 6.54671L14.7347 11.334H15.3327C15.7007 11.334 15.9993 11.0354 15.9993 10.6674V3.26871C15.9993 2.94204 15.7607 2.67204 15.4387 2.61404C14.3093 2.41004 13.302 1.68404 13.302 1.68404L13.3027 1.68337Z" fill="#d1c6e9" />
                        </g>
                        <defs>
                            <clipPath id="clip0_39_127">
                                <rect width="25" height="25" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            )
        }

        return "-"
    }

    const retrieveSingleJobData = () => {

        if (modalState.job_application_id) {

            if (state && state.data) {
                for (const job_application in state.data) {
                    if (state.data[job_application] && state.data[job_application].id === modalState.job_application_id) {
                        return state.data[job_application]
                    }
                }
            }
        } else {
            return null;
        }

    }
    const data = [  
        { label: "1", value: "1" },
        { label: "5", value: "5" },
        { label: "10", value: "10" },
        { label: "50", value: "50" },
    ]

    const headerTable = (header) => {
        if (header.name === "user") {
            return (
                <th key={header.name} style={{ width: "250px" }} scope="col" onClick={""}>
                    {header.verbose_name}
                </th>
            )

        } else if (header.name === "socials") {
            return (
                <TableHead
                    key={header.name}
                    head={header.name}
                    verbose_name={header.verbose_name || header.name}
                    onClick={"orderingListener"}
                    sort={orderingState.sort || 'ascend'}
                ></TableHead>
            )
        } else {
            return (
                <th key={header.name} scope="col" onClick={""} style={{ width: "50px" }}>
                    {headerIcon(header.name)}
                </th>
            )
        }
    }
    return (
        <>
            {modalState && modalState.modalIsOpen && modalState.type === "reason" ? <ReasonModal setModalState={setModalState} jobData={retrieveSingleJobData()}></ReasonModal> : null}
            {/* {modalState && modalState.modalIsOpen && modalState.type === "create_relation" ? <CreateRelationModal setModalState={setModalState} jobData={retrieveSingleJobData()} createRelation={createRelation}></CreateRelationModal> : null} */}
            {modalState && modalState.modalIsOpen && modalState.type === "error_message" ? <ErrorModal setModalState={setModalState} jobData={retrieveSingleJobData()}></ErrorModal> : null}
            <>
                {/* <div className="row">
                        <div className="col-4">
                            <div className="" style={{fontWeight: 600, fontSize: "25px"}}>
                                Welcome, {context.appRenderState && context.appRenderState.userData && context.appRenderState.userData.brands ? context.appRenderState.userData.username : null}
                            </div>

                        </div>
                        <div className="8"></div>


                    </div> */}

                <div className="row four-blocks">
                    <div className="col-sm-6 col-xl-3">
                        <div className="card" style={{ padding: "20px", borderRadius: "20px" }}>
                            <div className="creatorlist-statistics-content">
                                <div>
                                    <div style={{ fontWeight: 600 }}>Job applications</div>
                                    <div><span className="creatorlist-count">{state.job_application_count || 0}</span></div>
                                    <div></div>
                                </div>
                                <div className="creatorlist-statistics-active-fourth">
                                    <svg className="svg-purple" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <g clipPath="url(#clip0_1_441)">
                                            <path d="M14 7V0.46C14.9251 0.809335 15.7653 1.35131 16.465 2.05L19.949 5.536C20.6485 6.23488 21.1909 7.07489 21.54 8H15C14.7348 8 14.4804 7.89464 14.2929 7.70711C14.1054 7.51957 14 7.26522 14 7ZM22 10.485V19C21.9984 20.3256 21.4711 21.5964 20.5338 22.5338C19.5964 23.4711 18.3256 23.9984 17 24H7C5.67441 23.9984 4.40356 23.4711 3.46622 22.5338C2.52888 21.5964 2.00159 20.3256 2 19V5C2.00159 3.67441 2.52888 2.40356 3.46622 1.46622C4.40356 0.528882 5.67441 0.00158786 7 0L11.515 0C11.678 0 11.839 0.013 12 0.024V7C12 7.79565 12.3161 8.55871 12.8787 9.12132C13.4413 9.68393 14.2044 10 15 10H21.976C21.987 10.161 22 10.322 22 10.485ZM14 19C14 18.7348 13.8946 18.4804 13.7071 18.2929C13.5196 18.1054 13.2652 18 13 18H8C7.73478 18 7.48043 18.1054 7.29289 18.2929C7.10536 18.4804 7 18.7348 7 19C7 19.2652 7.10536 19.5196 7.29289 19.7071C7.48043 19.8946 7.73478 20 8 20H13C13.2652 20 13.5196 19.8946 13.7071 19.7071C13.8946 19.5196 14 19.2652 14 19ZM17 15C17 14.7348 16.8946 14.4804 16.7071 14.2929C16.5196 14.1054 16.2652 14 16 14H8C7.73478 14 7.48043 14.1054 7.29289 14.2929C7.10536 14.4804 7 14.7348 7 15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16H16C16.2652 16 16.5196 15.8946 16.7071 15.7071C16.8946 15.5196 17 15.2652 17 15Z" fill="#867BBA" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_441">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="card" style={{ padding: "20px", borderRadius: "20px" }}>
                            <div className="creatorlist-statistics-content">
                                <div>
                                    <div style={{ fontWeight: 600 }}>Accept Gifted</div>
                                    <div><span className="creatorlist-count">{state.accept_gifted || 0}</span></div>
                                    <div></div>
                                </div>
                                <div className="creatorlist-statistics-active-platform">
                                    <svg className="svg-purple" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M1.32701 12.4L4.88701 15L3.53501 19.187C3.31652 19.8364 3.31375 20.539 3.52712 21.1901C3.74048 21.8412 4.15856 22.4059 4.71901 22.8C5.26986 23.2068 5.9374 23.4247 6.62216 23.4213C7.30693 23.4178 7.97225 23.1933 8.51901 22.781L12 20.219L15.482 22.778C16.0319 23.1824 16.6958 23.4021 17.3784 23.4054C18.061 23.4088 18.727 23.1956 19.2808 22.7965C19.8345 22.3974 20.2475 21.833 20.4603 21.1845C20.6731 20.5359 20.6747 19.8366 20.465 19.187L19.113 15L22.673 12.4C23.2222 11.9985 23.6304 11.4337 23.8394 10.7864C24.0483 10.139 24.0474 9.44217 23.8367 8.79537C23.626 8.14858 23.2162 7.58491 22.666 7.18489C22.1158 6.78487 21.4533 6.56895 20.773 6.56799H16.4L15.073 2.43198C14.8643 1.78094 14.4543 1.213 13.902 0.81005C13.3497 0.407102 12.6837 0.189972 12 0.189972C11.3163 0.189972 10.6503 0.407102 10.098 0.81005C9.54574 1.213 9.13569 1.78094 8.92701 2.43198L7.60001 6.56799H3.23101C2.55075 6.56895 1.8882 6.78487 1.33799 7.18489C0.787778 7.58491 0.378051 8.14858 0.167331 8.79537C-0.0433882 9.44217 -0.0443208 10.139 0.164666 10.7864C0.373654 11.4337 0.781871 11.9985 1.33101 12.4H1.32701Z" fill="#867BBA" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="card" style={{ padding: "20px", borderRadius: "20px" }}>
                            <div className="creatorlist-statistics-content">
                                <div>
                                    <div style={{ fontWeight: 600 }}>Accept Barter</div>
                                    <div><span className="creatorlist-count">{state.accept_barter || 0}</span></div>
                                    <div></div>
                                </div>
                                <div className="creatorlist-statistics-active-third">
                                    <svg className="svg-purple" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <g clipPath="url(#clip0_1_668)">
                                            <path d="M5 20H13C15.757 20 18 17.757 18 15V9C18 6.243 15.757 4 13 4H5C2.243 4 0 6.243 0 9V15C0 17.757 2.243 20 5 20ZM24 8.313V15.632C24 16.389 23.58 17.069 22.903 17.407C22.62 17.548 22.316 17.618 22.014 17.618C21.593 17.618 21.177 17.483 20.825 17.219C20.788 17.191 20 16.409 20 16.409V7.544C20 7.544 20.786 6.755 20.825 6.726C21.429 6.272 22.224 6.199 22.903 6.538C23.582 6.877 24 7.556 24 8.313Z" fill="#867BBA" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_668">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="card" style={{ padding: "20px", borderRadius: "20px" }}>
                            <div className="creatorlist-statistics-content">
                                <div>
                                    <div style={{ fontWeight: 600 }}>Active Jobs</div>
                                    <div><span className="creatorlist-count">{state.active_campaigns || 0}</span></div>
                                    <div></div>
                                </div>
                                <div className="creatorlist-statistics-active-creator">
                                    <svg className="svg-purple" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <g clipPath="url(#clip0_1_670)">
                                            <path d="M19 4H17.9C17.6679 2.87141 17.0538 1.85735 16.1613 1.12872C15.2687 0.40009 14.1522 0.00145452 13 0L11 0C9.8478 0.00145452 8.73132 0.40009 7.83875 1.12872C6.94618 1.85735 6.3321 2.87141 6.1 4H5C3.67441 4.00159 2.40356 4.52888 1.46622 5.46622C0.528882 6.40356 0.00158786 7.67441 0 9L0 12H24V9C23.9984 7.67441 23.4711 6.40356 22.5338 5.46622C21.5964 4.52888 20.3256 4.00159 19 4ZM8.184 4C8.39008 3.41709 8.77123 2.91209 9.2753 2.55409C9.77937 2.19608 10.3817 2.00256 11 2H13C13.6183 2.00256 14.2206 2.19608 14.7247 2.55409C15.2288 2.91209 15.6099 3.41709 15.816 4H8.184Z" fill="#867BBA" />
                                            <path d="M13 15C13 15.2652 12.8946 15.5196 12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16C11.7348 16 11.4804 15.8946 11.2929 15.7071C11.1054 15.5196 11 15.2652 11 15V14H0V19C0.00158786 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.67441 23.9984 5 24H19C20.3256 23.9984 21.5964 23.4711 22.5338 22.5338C23.4711 21.5964 23.9984 20.3256 24 19V14H13V15Z" fill="#867BBA" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_670">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="row">
                    <div className="col-creator">
                        <div className="card mb-4 border-top-left-right">
                            <div className="card-header" style={{ display: "flex", justifyContent: "space-between" }}>
                                <h4 style={{
                                    paddingRight: "10px",
                                    marginBottom: "0px",
                                    fontSize: "25px"
                                }}>Job applications - {state && state.title ? state.title : null}</h4>
                                {/* <Link to="/app/job/create/">
                                    <div className="create-campaign">Create Job</div>
                                </Link> */}
                            </div>
                            <div className="card-body" style={{ padding: '0px' }}>
                                <div className="row marginRow"
                                    style={{}}>
                                    {/* <div className="col-sm-6"
                                        style={{ padding: '0px' }}
                                    >
                                        <div className="row">
                                            <div className="select-container col-sm-2">
                                                <Select
                                                    onChange={showEntries}
                                                    name="show"
                                                    label={null}
                                                    options={data || []}
                                                    selected={showEntriesState.show}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-sm-6"
                                        style={{ padding: '0px' }}
                                    >
                                        <div className="row" style={{ justifyContent: 'flex-end' }}>
                                            {/* <div className="search-container" style={{ display: "flex" }}>
                                                <Search
                                                    placeholder={"Search"}
                                                    aria_label={"search"}
                                                    onChange={(e) => searchListener(e)}
                                                    value={searchState.input || ""}
                                                >
                                                </Search>
                                                <div onClick={onSearch} className="button-regular">Search</div>
                                            </div> */}
                                            <div className="horizontal__filter--select-options">
                                                <Select
                                                    onChange={(e) => FieldSelectListener("accept_gifted", e)}
                                                    name="accept_gifted"
                                                    label={"Accept gift"}
                                                    options={[{ "label": "-", "value": "null" }, { "label": "Yes", "value": "true" }] || []}
                                                    selected={searchState.accept_gifted || ""}
                                                    upperLabel={"Accept gifted"}
                                                />
                                                <Select
                                                    onChange={(e) => FieldSelectListener("accept_barter", e)}
                                                    name="accept_barter"
                                                    label={"Age"}
                                                    options={[{ "label": "-", "value": "null" }, { "label": "Yes", "value": "true" }] || []}
                                                    selected={searchState.accept_barter || ""}
                                                    upperLabel={"Accept barter"}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="table-responsive">
                                    <table className="table table-fit">
                                        <thead>
                                            <tr>
                                                {
                                                    [
                                                        // { "name": "create_relation", "verbose_name": "Create relation" },
                                                        { "name": "user", "verbose_name": "User" },
                                                        { "name": "socials", "verbose_name": "Socials" },
                                                        { "name": "reason", "verbose_name": "Reason" },
                                                        { "name": "can_recieve_email", "verbose_name": "Can recieve email" },
                                                        { "name": "older_than_18", "verbose_name": "Older than 18" },
                                                        { "name": "accept_gifted", "verbose_name": "Accept gifted" },
                                                        { "name": "accept_barter", "verbose_name": "Accept Barter" },
                                                        { "name": "created", "verbose_name": "Filled in" }
                                                    ].map(
                                                        header => (
                                                            <>
                                                                {headerTable(header)}
                                                            </>
                                                        )
                                                    )}
                                            </tr>
                                        </thead>
                                        <tbody style={{ verticalAlign: "middle" }}>
                                            {
                                                state && state.data ?

                                                    state.data.map(
                                                        (result, i) => (
                                                            <TableRowJobAplications
                                                                key={i}
                                                                columns={result}
                                                                setModalState={setModalState}
                                                            ></TableRowJobAplications>
                                                        )
                                                    )


                                                    : null
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className="card-footer">
                                <div className="row">
                                    <div
                                        className="col-sm-6"
                                        style={{
                                            margin: 'auto',
                                            width: '50%'
                                        }}
                                    >
                                        {/* <span>Showing 1 to {showEntriesState.show} of {state.count} entries</span> */}
                                    </div>
                                    <div className="col-sm-6"
                                        style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Pagination
                                            api_url={api_url}
                                            state={state}
                                            setState={setState}

                                        ></Pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>

        </>
    )
}
export default JobDetail