import React, { useEffect, useState } from "react"
import FileUploadVideo from "../web/FileUploadVideo";
import FileUpload from "../web/FileUpload";
import Input from "../web/Input"
import TextArea from "../web/TextArea"
import Select from "../web/Select";
import SocialMediaPolicyModal from "../web/SocialMediaPolicyModal";
import Icon from "../general/Icon"
import axios from "axios"
import getCookie from '../auth/Cookie';
import Video from "../../files/SnapTik.ltd_1705946584.mp4";
import Image from "../../files/favicon.png"
import Image_1 from "../../files/ugcbird-image-1.jpg"

import { Link, useNavigate } from "react-router-dom"
var csrftoken = getCookie('csrftoken');

const CampaigneCreate = () => {
    let currentPage
    const navigate = useNavigate()
    const [campaignState, setCampaignState] = useState({
        "name": "",
        "brand": null,
        "social_media_policy": "I am the sole rights holder of the Content, I am over 18 years old and I accept the above Terms & Conditions OR I am the parent or legal guardian of the sole rights holder of the Content who is under 18 years old and I accept the above Terms & Conditions on the right’s holder’s behalf as agent or attorney, as if I was the rights holder for these purposes.",
        "title": "",
        "reward": "",
        "text": "",
        "content_type": "unboxing_video",
        "content_format": "any",
        "video_length": "15",
        "video_or_image": "video",
        "thankyou_title": "Congratulations! Your UGC is selected!",
        "thankyou_message": "",
        "reject_title": "Regrettably, your content did not meet our criteria.",
        "reject_message": ""

    })

    const [modalState, setModalState] = useState({
        modalIsOpen: false,
        id: null
    })
    const [videoState, setVideoState] = useState({
    })

    const [imageState, setImageState] = useState({
    })
    const [stepState, setStepState] = useState({
        step: 1,
        continue: false
    })

    const [brandsListState, setBrandListState] = useState({

    })

    const FieldListener = (type, event) => {
        event.persist();
        setCampaignState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }

    const fileUploadListener = (event: any) => {
        const file_obj = event.target.files[0]

        const video_obj = {
            obj: "",
            url: window.URL.createObjectURL(file_obj),
            new: true

        }
        const new_file = Object.assign(file_obj, video_obj)
        setVideoState(oldstate => ({ ...oldstate, video: new_file }))
    };

    const fileImageUploadListener = (event: any) => {
        const file_obj = event.target.files[0]

        const image_obj = {
            obj: "",
            url: window.URL.createObjectURL(file_obj),
            new: true

        }
        const new_file = Object.assign(file_obj, image_obj)

        setImageState(oldstate => ({ ...oldstate, image: new_file }))

    };
    const clearContent = (type) => {
        if (type === "video") {
            setVideoState(oldstate => {
                let state_result = {
                };
                return state_result
            });
        }
        if (type === "image") {
            setImageState(oldstate => {
                let state_result = {
                };
                return state_result
            });
        }
    }


    const CanIContinue = () => {
        if (stepState.continue && stepState.step < 4) {
            setStepState(oldstate => ({ ...oldstate, "step": stepState.step + 1, "continue": true }))

        }
        if (stepState.continue && stepState.step === 3) {
            setStepState(oldstate => ({ ...oldstate, "step": stepState.step + 1, "continue": true }))
        }
        if (stepState.step === 4) {
            let default_brand = null
            axios({
                headers: {
                    'Authorization': localStorage.auth_token,
                    'X-CSRFToken': csrftoken,
                },
                method: 'post',
                url: process.env.API_URL + "api/im-i-allowed/",
                data: {
                    type: "campaign"
                }
            })
                .then(function (response) {
                    axios({
                        headers: {
                            'Authorization': localStorage.auth_token,
                            'X-CSRFToken': csrftoken,
                            "Content-Type": "multipart/form-data"
                        },
                        method: 'post',
                        url: process.env.API_URL + "api/campaign/",
                        data: {
                            method: "create",
                            name: campaignState.name,
                            brand: campaignState.brand || default_brand,
                            social_media_policy: campaignState.social_media_policy,
                            title: campaignState.title,
                            text: campaignState.text,
                            reward: campaignState.reward,
                            video: videoState.video,
                            image: imageState.image,
                            content_type: campaignState.content_type,
                            video_length: campaignState.video_length,
                            content_format: campaignState.content_format,
                            video_or_image: campaignState.video_or_image,
                            thankyou_title: campaignState.thankyou_title,
                            thankyou_message: campaignState.thankyou_message,
                            reject_title: campaignState.reject_title,
                            reject_message: campaignState.reject_message,
                            return_url: campaignState.return_url

                        }
                    })
                        .then(function (response) {
                            setStepState(oldstate => ({ ...oldstate, "step": stepState.step + 1, "continue": false }))
                        })
                        .catch(function (error) {
                            navigate("/app/something-went-wrong/")
                        });
                })
                .catch(function (response) {

                })


        }

    }

    const contentType = () => {
        if (campaignState.content_type == "unboxing_video") {
            return "Unboxing video"
        } else if (campaignState.content_type == "how_to_video") {
            return "How to video"
        } else if (campaignState.content_type == "product_review") {
            return "product Review"
        } else if (campaignState.content_type == "product_demo") {
            return "Product Demo"

        } else if (campaignState.content_type == "picture_with_product") {
            return "Picture with product"
        } else if (campaignState.content_type == "lifestyle_shot") {
            return "Lifestyle shot"

        } else if (campaignState.content_type == "picture_of_product") {
            return "Picture of the product"
        } else {
            return "-"
        }

    }
    useEffect(() => {
        if (stepState.step === 1) {
            if (campaignState.name && campaignState.title && campaignState.social_media_policy && campaignState.reward && campaignState.text) {
                setStepState(oldstate => ({ ...oldstate, "continue": true }))
            }
        }

        if (stepState.step === 2) {
            if (campaignState.content_type && campaignState.video_length) {
                setStepState(oldstate => ({ ...oldstate, "continue": true }))
            }
        }
        if (stepState.step === 3) {
            if (campaignState.thankyou_title && campaignState.thankyou_message && campaignState.reject_title && campaignState.reject_message) {
                setStepState(oldstate => ({ ...oldstate, "continue": true }))
            }
        }
    }, [campaignState])

    useEffect(() => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: 'get',
            url: `${process.env.API_URL}api/brands/`,
        })
            .then(function (response) {
                if (response && response.data) {
                    let brand_list = []
                    response.data.map((result, i) => {
                        brand_list.push({ "value": result.id, "label": result.name })
                    })
                    setBrandListState({ data: brand_list })
                    setCampaignState(oldstate => {
                        let state_result = {
                            ...oldstate,
                            brand: response.data[0]['id'],
                            logo: response.data[0]['logo']

                        };
                        return state_result
                    });
                }
            })
            .catch(function (error) {
            });
    }, [])

    const contentTypeText = () => {
        if ("unboxing_video" === campaignState.content_type) {
            return {
                "title": "Unboxing video",
                "text": "Entertaining unboxing video showing the packaging and what comes along with the product.",
                "content": [Video, Video, Video]
            }
        } else if ("how_to_video" === campaignState.content_type) {
            return {
                "title": "How to video",
                "text": "Detailed video tutorial to guide customers through all steps of using your product",
                "content": [Video, Video, Video]
            }
        } else if ("product_review" === campaignState.content_type) {
            return {
                "title": "Product review",
                "text": "Positive feedback videos where creators spread the word about how awesome your product is.",
                "content": [Video, Video, Video]
            }
        } else if ("product_demo" === campaignState.content_type) {
            return {
                "title": "Product demo",
                "text": "Detailed on-camera demonstration of how your product works and looks in real life.",
                "content": [Video, Video]
            }
        } else if ("picture_with_product" === campaignState.content_type) {
            return {
                "title": "Picture with product",
                "text": "Content creators creates a picture with the content creator and the product on.",
                "content": [Image_1, Image_1, Image_1]
            }
        } else if ("lifestyle_shot" === campaignState.content_type) {
            return {
                "title": "Lifestyle shot",
                "text": "A lifestyle shot is a photograph that captures individuals engaged with the product.",
                "content": [Image_1, Image_1]
            }
        } else if ("picture_of_product" === campaignState.content_type) {
            return {
                "title": "Picture of the product only",
                "text": "A product shot features only the product itself.",
                "content": [Image_1, Image_1]
            }
        }

    }

    let viewStep2 = null
    switch (campaignState.video_or_image) {
        case "video":
            viewStep2 = <>
                <div className="row">
                    <div className="col-12">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Content type
                            </div>
                            <div className="wizard--sublabel">

                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <div className={`card content-type--card ${campaignState && campaignState.content_type === "unboxing_video" ? "content-type--card--active" : null}`} onClick={() => { setCampaignState(oldstate => ({ ...oldstate, content_type: "unboxing_video" })) }}>
                                        <div className="content-type--inner">
                                            <div className="content-type--title">
                                                Unboxing video
                                            </div>
                                            <div className="content-type--selector">
                                                <Icon
                                                    type={"fa-solid"}
                                                    icon={"circle-check"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`card content-type--card ${campaignState && campaignState.content_type === "how_to_video" ? "content-type--card--active" : null}`} onClick={() => { setCampaignState(oldstate => ({ ...oldstate, content_type: "how_to_video" })) }}>
                                        <div className="content-type--inner">
                                            <div className="content-type--title">
                                                How to video
                                            </div>
                                            <div className="content-type--selector">
                                                <Icon
                                                    type={"fa-solid"}
                                                    icon={"circle-check"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`card content-type--card ${campaignState && campaignState.content_type === "product_review" ? "content-type--card--active" : null}`} onClick={() => { setCampaignState(oldstate => ({ ...oldstate, content_type: "product_review" })) }}>
                                        <div className="content-type--inner">
                                            <div className="content-type--title">
                                                Product review
                                            </div>
                                            <div className="content-type--selector">
                                                <Icon
                                                    type={"fa-solid"}
                                                    icon={"circle-check"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`card content-type--card ${campaignState && campaignState.content_type === "product_demo" ? "content-type--card--active" : null}`} onClick={() => { setCampaignState(oldstate => ({ ...oldstate, content_type: "product_demo" })) }}>
                                        <div className="content-type--inner">
                                            <div className="content-type--title">
                                                Product demo
                                            </div>
                                            <div className="content-type--selector">
                                                <Icon
                                                    type={"fa-solid"}
                                                    icon={"circle-check"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                                    <div className="content-type--explain-short">
                                        {contentTypeText()['title']}
                                    </div>
                                    <div className="content-type--explain-long">
                                        {contentTypeText()['text']}
                                    </div>
                                    <div className="content-type--video--container">
                                        {campaignState && campaignState.video_or_image === "video" ?
                                            contentTypeText()['content'].map((video, key) => (
                                                <video
                                                    controls
                                                    muted={false}
                                                    autoPlay={false}
                                                    className="video"
                                                >
                                                    <source src={video} type="video/mp4" />
                                                </video>
                                            ))
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Video time
                            </div>
                            <div className="wizard--sublabel">

                            </div>
                            <div className="wizard--field">
                                <div className="wizard--options row">
                                    <div className={`wizard--option  ${campaignState && campaignState.video_length === "15" ? "wizard--option--active" : ""}`} onClick={() => { setCampaignState(oldstate => ({ ...oldstate, video_length: "15" })) }}>
                                        <Icon
                                            type={"fa"}
                                            icon={"stopwatch"}
                                            icon_size={4}
                                            icon_color_code={"#B390FF"}
                                        ></Icon>
                                        <div className="wizard--title">
                                            15 seconds
                                        </div>
                                    </div>
                                    <div className={`wizard--option  ${campaignState && campaignState.video_length === "30" ? "wizard--option--active" : ""}`} onClick={() => { setCampaignState(oldstate => ({ ...oldstate, video_length: "30" })) }}>
                                        <Icon
                                            type={"fa"}
                                            icon={"stopwatch"}
                                            icon_size={4}
                                            icon_color_code={"#B390FF"}
                                        ></Icon>
                                        <div className="wizard--title">
                                            30 seconds
                                        </div>
                                    </div>
                                    <div className={`wizard--option  ${campaignState && campaignState.video_length === "45" ? "wizard--option--active" : ""}`} onClick={() => { setCampaignState(oldstate => ({ ...oldstate, video_length: "45" })) }}>
                                        <Icon
                                            type={"fa"}
                                            icon={"stopwatch"}
                                            icon_size={4}
                                            icon_color_code={"#B390FF"}
                                        ></Icon>
                                        <div className="wizard--title">
                                            45 seconds
                                        </div>
                                    </div>
                                    <div className={`wizard--option  ${campaignState && campaignState.video_length === "60" ? "wizard--option--active" : ""}`} onClick={() => { setCampaignState(oldstate => ({ ...oldstate, video_length: "60" })) }}>
                                        <Icon
                                            type={"fa"}
                                            icon={"stopwatch"}
                                            icon_size={4}
                                            icon_color_code={"#B390FF"}
                                        ></Icon>
                                        <div className="wizard--title">
                                            60 seconds
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            break;

        case "image":
            viewStep2 = <>
                <div className="row">
                    <div className="col-12">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Content type
                            </div>
                            <div className="wizard--sublabel">

                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <div className={`card content-type--card ${campaignState && campaignState.content_type === "picture_with_product" ? "content-type--card--active" : null}`} onClick={() => { setCampaignState(oldstate => ({ ...oldstate, content_type: "picture_with_product" })) }}>
                                        <div className="content-type--inner">
                                            <div className="content-type--title">
                                                Picture with product
                                            </div>
                                            <div className="content-type--selector">
                                                <Icon
                                                    type={"fa-solid"}
                                                    icon={"circle-check"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`card content-type--card ${campaignState && campaignState.content_type === "lifestyle_shot" ? "content-type--card--active" : null}`} onClick={() => { setCampaignState(oldstate => ({ ...oldstate, content_type: "lifestyle_shot" })) }}>
                                        <div className="content-type--inner">
                                            <div className="content-type--title">
                                                Lifestyle shot
                                            </div>
                                            <div className="content-type--selector">
                                                <Icon
                                                    type={"fa-solid"}
                                                    icon={"circle-check"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`card content-type--card ${campaignState && campaignState.content_type === "picture_of_product" ? "content-type--card--active" : null}`} onClick={() => { setCampaignState(oldstate => ({ ...oldstate, content_type: "picture_of_product" })) }}>
                                        <div className="content-type--inner">
                                            <div className="content-type--title">
                                                Picture of the product
                                            </div>
                                            <div className="content-type--selector">
                                                <Icon
                                                    type={"fa-solid"}
                                                    icon={"circle-check"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                                    <div className="content-type--explain-short">
                                        {contentTypeText()['title']}
                                    </div>
                                    <div className="content-type--explain-long">
                                        {contentTypeText()['text']}
                                    </div>
                                    <div className="content-type--video--container">
                                        {campaignState && campaignState.video_or_image === "image" ?
                                            contentTypeText()['content'].map((image, key) => (
                                                <img className='video_or_image--image' src={image}></img>
                                            ))
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Content format
                            </div>
                            <div className="wizard--sublabel">

                            </div>
                            <div className="wizard--field">
                                <div className="wizard--options row">
                                    <div className={`wizard--option  ${campaignState && campaignState.content_format === "any" ? "wizard--option--active" : ""}`} onClick={() => { setCampaignState(oldstate => ({ ...oldstate, content_format: "any" })) }}>
                                        <Icon
                                            type={"fa"}
                                            icon={"image"}
                                            icon_size={4}
                                            icon_color_code={"#B390FF"}
                                        ></Icon>
                                        <div className="wizard--title">
                                            Any
                                        </div>
                                    </div>
                                    <div className={`wizard--option  ${campaignState && campaignState.content_format === "portrait" ? "wizard--option--active" : ""}`} onClick={() => { setCampaignState(oldstate => ({ ...oldstate, content_format: "portrait" })) }}>
                                        <Icon
                                            type={"fa"}
                                            icon={"image-portrait"}
                                            icon_size={4}
                                            icon_color_code={"#B390FF"}
                                        ></Icon>
                                        <div className="wizard--title">
                                            Portrait
                                        </div>
                                    </div>
                                    <div className={`wizard--option  ${campaignState && campaignState.content_format === "landscape" ? "wizard--option--active" : ""}`} onClick={() => { setCampaignState(oldstate => ({ ...oldstate, content_format: "landscape" })) }}>
                                        <Icon
                                            type={"fa"}
                                            icon={"panorama"}
                                            icon_size={4}
                                            icon_color_code={"#B390FF"}
                                        ></Icon>
                                        <div className="wizard--title">
                                            Landscape
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            break;

    }


    switch (stepState.step) {
        case 1:
            currentPage = <>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Campaign name ( Only you are able to see it )
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("name", e)}
                                    type="text"
                                    label=""
                                    name="name"
                                    value={campaignState.name || ""}
                                    placeholder="Enter the name of the campaign"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Brand
                            </div>
                            <div className="wizard--field">
                                <Select
                                    onChange={(e) => FieldListener("brand", e)}
                                    name="Brand"
                                    label={"brand"}
                                    options={brandsListState.data || []}
                                    selected={campaignState.brand || ""}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Campaign title ( Visible for the end user )
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("title", e)}
                                    type="text"
                                    label=""
                                    name="title"
                                    value={campaignState.title || ''}
                                    placeholder="Enter the title of the campaign"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Campaign reward
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("reward", e)}
                                    type="text"
                                    name="reward"
                                    value={campaignState.reward || ''}
                                    placeholder="Enter the reward for the campaign"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Social media policy
                            </div>
                            <div className="wizard--field">
                                <TextArea
                                    onChange={(e) => FieldListener("social_media_policy", e)}
                                    label=""
                                    name="social_media_policy"
                                    value={campaignState.social_media_policy || ''}
                                    placeholder={"Describe the steps the content creator should follow"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                How it works
                            </div>
                            <div className="wizard--field">
                                <TextArea
                                    onChange={(e) => FieldListener("text", e)}
                                    label=""
                                    name="text"
                                    value={campaignState.text || ''}
                                    placeholder={"Describe the steps the content creator should follow"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card video_or_image--card">
                            <div className="video_or_image--label">Choose between content type</div>
                            <div className="video_or_image--options">
                                <div onClick={() => { setCampaignState(oldstate => ({ ...oldstate, video_or_image: "video", content_type: "unboxing_video" })), clearContent("image") }} className={`card video_or_image--option ${campaignState.video_or_image === "video" ? "video_or_image--option--active" : ""}`}>
                                    <Icon
                                        type={"fa"}
                                        icon={"stopwatch"}
                                        icon_size={4}
                                        icon_color_code={"#B390FF"}
                                    ></Icon>
                                    <div className="video_or_image--title">
                                        Video
                                    </div>
                                </div>
                                {/* <div onClick={() => { setCampaignState(oldstate => ({ ...oldstate, video_or_image: "image", content_type: "picture_with_product" })), clearContent("video") }} className={`card video_or_image--option ${campaignState.video_or_image === "image" ? "video_or_image--option--active" : ""}`}>
                                    <Icon
                                        type={"fa"}
                                        icon={"image"}
                                        icon_size={4}
                                        icon_color_code={"#B390FF"}
                                    ></Icon>
                                    <div className="video_or_image--title">
                                        Image
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                            Return URL (Button that redirects user to preferred page)
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("return_url", e)}
                                    type="text"
                                    label=""
                                    name="return_url"
                                    value={campaignState.return_url || ''}
                                    placeholder="Enter the return url"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 create-col-video">
                        {campaignState && campaignState.video_or_image === "video" ?
                            <>
                                <div className="example_video">Example video</div>
                                <FileUploadVideo
                                    fileUpload={fileUploadListener}
                                    clearContent={() => { clearContent("video") }}
                                    type="file"
                                    name="ugc_video"
                                    ariaLabel="ugc_video"
                                    label="ugc_video"
                                    video={videoState.video || null}
                                />
                            </>
                            : <>
                                <div className="example_video">Example Image</div>
                                <FileUpload
                                    fileUpload={fileImageUploadListener}
                                    clearContent={() => { clearContent("image") }}
                                    type="file"
                                    name="iamge"
                                    ariaLabel="image"
                                    image={imageState.image || null}
                                    inputLabel={""}
                                />
                            </>}
                    </div>
                </div>
            </>
            break;

        case 2:
            currentPage = <>
                {viewStep2}
            </>
            break;

        case 3:
            currentPage = <>
                <div className="row">

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Thank you title
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("thankyou_title", e)}
                                    type="text"
                                    label=""
                                    name="thankyou_title"
                                    value={campaignState.thankyou_title || ''}
                                    placeholder="Enter the title of the thank you message"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Reject title
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("reject_title", e)}
                                    type="text"
                                    name="reject_title"
                                    value={campaignState.reject_title || ''}
                                    placeholder="Enter the title of reject message"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Thank you message
                            </div>
                            <div className="wizard--field">
                                <TextArea
                                    onChange={(e) => FieldListener("thankyou_message", e)}
                                    label=""
                                    name="thankyou_message"
                                    value={campaignState.thankyou_message || ''}
                                    placeholder={"Describe the steps the content creator should follow"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Reject message
                            </div>
                            <div className="wizard--field">
                                <TextArea
                                    onChange={(e) => FieldListener("reject_message", e)}
                                    label=""
                                    name="reject_message"
                                    value={campaignState.reject_message || ''}
                                    placeholder={"Describe the steps the content creator should follow"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>

            break;
        case 4:
            currentPage = <>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="sendugcform--card card">
                            <div className="sendugcform--card-header">
                                <div className="row">
                                    <div className="col-6 left-image-con">
                                        {campaignState && campaignState.logo ?
                                            <div className=''>
                                                <span className="sendugcform--logo-image">
                                                    <img src={`${process.env.API_URL}${campaignState.logo.substr(1)}`}></img>
                                                </span>
                                            </div>
                                            : null}
                                        {/* <div className="sendugcform--name">
                                            {campaignState && campaignState.brand && campaignState.brand.logo ? <>{campaignState.brand.name}</>
                                                : null}
                                        </div> */}
                                    </div>
                                    <div className="col-6 right-image-con">
                                        <div className='card-user-image' ><img className='circle-image sendugcform--image-header' src={Image}></img></div>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="sendugcform--card-body">
                                <div className="sendugcform-input-container">
                                    <div className="sendugcform-input-title">
                                        Campaign
                                    </div>
                                    <div className="sendugcform-input-value">
                                        <div className="sendugcform-input-value-icon">
                                            {campaignState && campaignState.video_or_image === "video" ?
                                                <Icon
                                                    type={"fa"}
                                                    icon={"video"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>
                                                : <Icon
                                                    type={"fa"}
                                                    icon={"camera"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon>}
                                        </div>
                                        <div className="sendugcform-input-value-text">
                                            {campaignState.title}
                                        </div>
                                    </div>
                                </div>
                                <div className="sendugcform-reward">
                                    <div className="sendugcform-input-title">
                                        Reward
                                    </div>
                                    <div className="sendugcform-reward-container">
                                        <div className="sendugcform-reward-icon">
                                            <Icon
                                                type={"fa"}
                                                icon={"gift"}
                                                icon_size={1}
                                                icon_color_code={"#ffffff"}
                                            ></Icon>
                                        </div>
                                        <div className="sendugcform-reward-text">
                                            {campaignState.reward}
                                        </div>
                                    </div>
                                </div>
                                <div className="sendugcform-requirements">
                                    <div className="sendugcform-input-title">
                                        Requirements
                                    </div>
                                    <div className="sendugcform-requirement--container">
                                        <div className="sendugcform-requirement">
                                            <div className="sendugcform-requirement-icon">
                                                {campaignState && campaignState.video_or_image === "video" ?
                                                    <Icon
                                                        type={"fa"}
                                                        icon={"video"}
                                                        icon_size={1}
                                                        icon_color_code={"#4f4f4f"}
                                                    ></Icon>
                                                    : <Icon
                                                        type={"fa"}
                                                        icon={"image"}
                                                        icon_size={1}
                                                        icon_color_code={"#4f4f4f"}
                                                    ></Icon>}
                                            </div>
                                            <div className="sendugcform-requirement-text">
                                                {contentType()}
                                            </div>
                                        </div>
                                        <div className="sendugcform-requirement">
                                            <div className="sendugcform-requirement-icon">

                                                {campaignState && campaignState.video_or_image === "video" ?
                                                    <Icon
                                                        type={"fa"}
                                                        icon={"stopwatch"}
                                                        icon_size={1}
                                                        icon_color_code={"#4f4f4f"}
                                                    ></Icon>
                                                    : <Icon
                                                        type={"fa"}
                                                        icon={"file-image"}
                                                        icon_size={1}
                                                        icon_color_code={"#4f4f4f"}
                                                    ></Icon>}
                                            </div>
                                            <div className="sendugcform-requirement-text">
                                                {campaignState && campaignState.video_or_image === "video" ?
                                                    <>Video should be atleast {campaignState.video_length} seconds</>
                                                    : <>Content format {campaignState.content_format}</>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sendugcform-description">
                                    <div className="sendugcform-input-title">
                                        Description
                                    </div>
                                    <div className="sendugcform-input-description">
                                        {campaignState.text}
                                    </div>
                                </div>
                                <div className="sendugcform-button-container">
                                    <div className="sendugcform-button sendugcform-button-light">
                                        Social media policy
                                    </div>
                                    <div className="sendugcform-button sendugcform-button-dark">
                                        Get started now
                                    </div>
                                </div>

                            </div>
                            <div className="sendugcform--card-footer">

                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sendugcform-video--container" style={{ justifyContent: "center" }}>
                        {campaignState && campaignState.video_or_image === "video" ?
                            <>
                                {videoState && videoState.video ?
                                    <div className="brand-media">
                                        <video
                                            controls
                                            loop
                                            muted={false}
                                            autoPlay={false}
                                            className="video"
                                        >
                                            <source src={videoState.video.url} type="video/mp4" />
                                        </video>
                                    </div>
                                    : <div className="brand-media">    <video
                                        controls
                                        loop
                                        muted={false}
                                        autoPlay={false}
                                        className="video"
                                    >
                                        <source src={Video} type="video/mp4" />

                                    </video></div>}
                            </> : <>
                                {imageState && imageState.image ?
                                    <div className="brand-media">
                                        <img className='sendugcform--image' src={imageState.image.url}></img>
                                    </div>
                                    : <div className="brand-media">   <img className='sendugcform--image' src={Image}></img></div>}
                            </>


                        }
                    </div>
                </div>

            </>
            break;
        case 5:
            currentPage = <>
                <div style={{ textAlign: "center" }}>
                    <Link to="/app/campaign">
                        <div style={{ margin: "auto", marginTop: "20px", width: "250px" }} className="button-continue">Return to campaigns</div>
                    </Link>
                </div>

            </>
            break;

    }

    return (
        <>{brandsListState && brandsListState.data ?
            <>
                {brandsListState && brandsListState.data && brandsListState.data.length >= 1 ?
                    <div className="row">
                        <div className="col-12">

                            <Link to={`/app/campaign/`} style={{ marginBottom: "20px" }}>
                                <span className="">
                                    <Icon
                                        type={"fa"}
                                        icon={"chevron-left"}
                                        icon_size={1}

                                        icon_color_code={"#e3def9"}
                                    ></Icon></span>
                                Back
                            </Link>

                            {modalState && modalState.modalIsOpen ? <SocialMediaPolicyModal text={campaignState.social_media_policy} setModalState={setModalState}></SocialMediaPolicyModal> : null}
                        </div>

                        <div className="col-12">
                            <div className="module-steps">
                                <div className="module-step">
                                    <div className={`module-step-icon ${stepState.step === 1 ? "module-step-icon-active" : ""}`}>
                                        <Icon
                                            type={"fa"}
                                            icon={"flag-checkered"}
                                            icon_size={1}
                                            icon_color_code={"#BFC1C3"}
                                        ></Icon>
                                    </div>

                                    <div className="module-step-text">
                                        <div className="module-title">
                                            Create campaign
                                        </div>
                                        <div className="module-text">
                                            Campaign details
                                        </div>
                                    </div>
                                    <div className="module-step-arrow">
                                        <Icon
                                            type={"fa"}
                                            icon={"arrow-right"}
                                            icon_size={1}
                                            icon_color_code={"#BFC1C3"}
                                        ></Icon>
                                    </div>
                                </div>
                                <div className="module-step">
                                    <div className={`module-step-icon ${stepState.step === 2 ? "module-step-icon-active" : ""}`}>
                                        <Icon
                                            type={"fa"}
                                            icon={"star"}
                                            icon_size={1}
                                            icon_color_code={"#BFC1C3"}
                                        ></Icon>
                                    </div>

                                    <div className="module-step-text">
                                        <div className="module-title">
                                            Add requirements
                                        </div>
                                        <div className="module-text">
                                            requirement details
                                        </div>
                                    </div>
                                    <div className="module-step-arrow">
                                        <Icon
                                            type={"fa"}
                                            icon={"arrow-right"}
                                            icon_size={1}
                                            icon_color_code={"#BFC1C3"}
                                        ></Icon>
                                    </div>
                                </div>
                                <div className="module-step">
                                    <div className={`module-step-icon ${stepState.step === 3 ? "module-step-icon-active" : ""}`}>
                                        <Icon
                                            type={"fa"}
                                            icon={"envelope"}
                                            icon_size={1}
                                            icon_color_code={"#BFC1C3"}
                                        ></Icon>
                                    </div>

                                    <div className="module-step-text">
                                        <div className="module-title">
                                            Email
                                        </div>
                                        <div className="module-text">
                                            Message details
                                        </div>
                                    </div>
                                    <div className="module-step-arrow">
                                        <Icon
                                            type={"fa"}
                                            icon={"arrow-right"}
                                            icon_size={1}
                                            icon_color_code={"#BFC1C3"}
                                        ></Icon>
                                    </div>
                                </div>
                                <div className="module-step">
                                    <div className={`module-step-icon ${stepState.step === 4 ? "module-step-icon-active" : ""}`}>
                                        <Icon
                                            type={"fa"}
                                            icon={"magnifying-glass"}
                                            icon_size={1}
                                            icon_color_code={"#BFC1C3"}
                                        ></Icon>
                                    </div>

                                    <div className="module-step-text">
                                        <div className="module-title">
                                            Preview
                                        </div>
                                        <div className="module-text">
                                            Review the preview
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-12">
                                    <div className="progress-title">
                                        {stepState.step < 4 ?
                                            <>
                                                <div className="progress-header">Ready to create your Campaign?</div>
                                                <div>Follow the three easy steps to finish and get going!</div>
                                            </>
                                            :
                                            <>
                                                <div className="progress-header">Congratz you created your campaign!</div>
                                            </>

                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12" style={{ margin: "auto" }}>
                                    {currentPage}
                                </div>
                            </div>
                            {stepState.step !== 5 ?
                                <div className="row">
                                    <div className="col-8 flexer">
                                        <div onClick={() => { setStepState({ "step": stepState.step > 1 ? stepState.step - 1 : stepState.step, "continue": stepState.step > 1 ? true : stepState.continue }) }} className="button-back">back</div>

                                        <div onClick={() => { CanIContinue() }} className={`button-continue button-continue_${stepState.continue}`}>Continue</div>
                                    </div>

                                </div>
                                : null
                            }
                        </div>
                    </div>
                    : <div className="no-match">
                        <h1>OH NO!</h1>
                        <h2>Looks like you dont have a brand</h2>
                        <div>
                            Please click on the create a brand button to continue creating your first Campaign.
                        </div>
                        <div className="a-button"><a href="/app/settings/">Create a brand</a></div>
                    </div>

                }

            </>
            : <div className="loading-gif"></div>}

        </>
    )
}
export default CampaigneCreate;