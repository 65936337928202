import React, { useState } from "react";
import Image_1 from "../../files/ugcbird-ugc-campaign.png"
import Image_2 from "../../files/ugcbird-ugc-campaign-2.png"
import Image_review from "../../files/ugcbird-ugc-review.png"
import Image_choose from "../../files/ugcbird-ugc-choose.png"

const HowItWorks = () => {
    let current
    const [stepState, setStepState] = useState({
        step: "step-1"
    })
    switch (stepState.step) {
        case "step-1":
            current = <div className="hiw-global-container">

                <div className="row hiw-container hiw-container--left">
                    <h2 className="hiw-h2 ralyway-bold">Create a campaign</h2>
                    <div className="hiw-text">
                        Create or add your existing brand, share some requirements for the campaign, and don't forget to include a reward for your micro-content creator.</div>
                    <div className="hiw-button-text-container">
                        <div className="hiw-button-text">
                            Ready to get started?
                        </div>
                        <a href="https://www.ugcbird.com/app/register/">
                            <div className="hiw-button hiw-button-style">Sign up now</div>
                        </a>
                    </div>

                </div>

                <div className="row hiw-container hiw-container--right">
                    <img src={Image_2} alt="ugc-UGCBird-create-campaign"></img>
                </div>
            </div>
            break;
        case "step-2":
            current = <div className="hiw-global-container">

                <div className="row hiw-container hiw-container--left">
                    <h2 className="hiw-h2 ralyway-bold">Review the preview</h2>
                    <div className="hiw-text">
                        Quick review of the preview on how it would look when it's online.</div>
                    <div className="hiw-button-text-container">
                        <div className="hiw-button-text">
                            Ready to get started?
                        </div>
                        <a href="https://www.ugcbird.com/app/register/">
                            <div className="hiw-button hiw-button-style">Sign up now</div>
                        </a>
                    </div>

                </div>

                <div className="row hiw-container hiw-container--right">
                    <img src={Image_review} alt="ugc-UGCBird-review-campaign"></img>
                </div>
            </div>
            break;

        case "step-3":
            current = <div className="hiw-global-container">

                <div className="row hiw-container hiw-container--left">
                    <h2 className="hiw-h2 ralyway-bold">Gather UGC content</h2>
                    <div className="hiw-text">
                        Gather UGC content from existing clients and microcontent creators.</div>
                    <div className="hiw-button-text-container">
                        <div className="hiw-button-text">
                            Ready to get started?
                        </div>
                        <a href="https://www.ugcbird.com/app/register/">
                            <div className="hiw-button hiw-button-style">Sign up now</div>
                        </a>
                    </div>

                </div>

                <div className="row hiw-container hiw-container--right">
                    <img src={Image_1} alt="ugc-UGCBird-gather-ugc-content"></img>
                </div>
            </div>
            break;

        case "step-4":
            current = <div className="hiw-global-container">

                <div className="row hiw-container hiw-container--left">
                    <h2 className="hiw-h2 ralyway-bold">Choose UGC content</h2>
                    <div className="hiw-text">
                        Easily choose between multiple UGC videos and use them for content on your social media or even in your ads.</div>
                    <div className="hiw-button-text-container">
                        <div className="hiw-button-text">
                            Ready to get started?
                        </div>
                        <a href="https://www.ugcbird.com/app/register/">
                            <div className="hiw-button hiw-button-style">Sign up now</div>
                        </a>
                    </div>

                </div>

                <div className="row hiw-container hiw-container--right">
                    <img src={Image_choose} alt="ugc-UGCBird-choose-ugc-content"></img>
                </div>
            </div>
            break;
        default:
            current = <div className="hiw-global-container">

                <div className="row hiw-container hiw-container--left">
                    <h2 className="hiw-h2 ralyway-bold">Share your campaign with the world</h2>
                    <div className="hiw-text">
                        Create or add your existing brand, share some requirements for the campaign, and don't forget to include a reward for your micro-content creator.</div>
                    <div className="hiw-button-text-container">
                        <div className="hiw-button-text">
                            Ready to get started?
                        </div>
                        <div className="hiw-button hiw-button-style">Sign up now</div>
                    </div>

                </div>

                <div className="row hiw-container hiw-container--right">

                </div>
            </div>
    }
    return (
        <div className="no-padding container container--custom">
            <h3 className="hiw-title ralyway-bold">Launch your first campaign</h3>
            <div className="row">

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">

                    <div className="hiw-buttons">
                        <div onClick={() => { setStepState({ step: "step-1" }) }} className="hiw-button">
                            <span>1. Create a campaign</span>

                        </div>
                        <div onClick={() => { setStepState({ step: "step-2" }) }} className="hiw-button">
                            <span>2. Review the preview</span>

                        </div>
                        <div onClick={() => { setStepState({ step: "step-3" }) }} className="hiw-button">
                            <span>3. Gather UGC content</span>
                        </div>
                        <div onClick={() => { setStepState({ step: "step-4" }) }} className="hiw-button">
                            <span>4. Choose UGC content</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                    {current}
                </div>
            </div>
        </div>
    )
}
export default HowItWorks;
