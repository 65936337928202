import React, { FunctionComponent } from "react";


type Props = {
    onChange?: any
    name?: any
    type?: string
    value?: any
    label?: string
    icon?: string;
    back_icon?: string;
    front_icon?: string;
    error?: string;
    tooltip?: string;
    placeholder?: string;
    min?: any;
    max?: any;
}

const Input: FunctionComponent<Props> = ({ onChange, name, type, value, label, icon, back_icon, front_icon, error, tooltip, placeholder, min, max }) => {

    const renderIcon = icon => {

        if (icon) {
            return "fas fa-" + icon
        } else {
            return ''
        }
    }

    const displayValue = (value) => {
        if (value !== "null") {
            return value
        } else {
            return ""
        }
    }
    return (
        <div>
            {label ?
                <label className="input-label">{label}</label>
                : null}
            {tooltip ?
                <div className="tooltip--container">
                    <div className="tooltip--text">
                    </div>
                </div>
                : null}
            <div className="input-group mb-3">
                {front_icon ?
                    <div className="input-group-prepend">

                        <span className="input-group-text bg-white" id="basic-addon1"><i className={renderIcon(icon)} style={{ color: '#b4b4b4' }}></i></span>

                    </div>
                    : null
                }
                {/* <label className="input-label">{label}</label> */}
                <input placeholder={placeholder} onChange={onChange} id={name} name={name} type={type} min={min} max={max} value={displayValue(value)} className="form-control" />

                {back_icon ?
                    <div className="input-group-append">
                        <span className="input-group-text bg-white" id="basic-addon1"><i className={renderIcon(icon)} style={{ color: '#b4b4b4' }}></i></span>
                    </div>
                    : null
                }
                {error}
            </div>
        </div>
    )
}
export default Input
