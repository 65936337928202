import React, { useEffect, useState } from "react"
import FileUpload from "../web/FileUpload";
import Input from "../web/Input"
import TextArea from "../web/TextArea"
import Select from "../web/Select";
import SocialMediaPolicyModal from "../web/SocialMediaPolicyModal";
import Icon from "../general/Icon"
import axios from "axios"
import getCookie from '../auth/Cookie';
import { Link, useNavigate } from "react-router-dom"
import Video from "../../files/SnapTik.ltd_1705946584.mp4";
import Image from "../../files/favicon.png"
import Image_1 from "../../files/ugcbird-image-1.jpg"
var csrftoken = getCookie('csrftoken');

const JobCreate = () => {
    let currentPage
    const navigate = useNavigate()
    const [jobState, setJobState] = useState({
        "name": "",
        "title": "",
        "tell_us": "",
        "briefing": "",
        "content_type": "unboxing_video",
        "video_or_image": "video",
        "media_type": "video",
        "reward": "paid",
        "content_format": "any",
        "product_name": "",
        "product_description": "",
        "min_price": 0,
        "max_price": 0,
        "min_duration": 1,
        "max_duration": 1,
        "brand": null,
        "image": null,
        "instructions": {
            "dont_1": "",
            "dont_2": "",
            "dont_3": "",
            "dont_4": "",
            "do_1": "",
            "do_2": "",
            "do_3": "",
            "do_4": "",
        },
        "country": "AF",
        "language": "af"
    })
    const [brandsListState, setBrandListState] = useState({

    })
    const [modalState, setModalState] = useState({
        modalIsOpen: false,
        id: null
    })

    const [stepState, setStepState] = useState({
        step: 1,
        continue: false
    })
    const fileImageUploadListener = (event: any) => {
        const file_obj = event.target.files[0]

        const image_obj = {
            obj: "",
            url: window.URL.createObjectURL(file_obj),
            new: true

        }
        const new_file = Object.assign(file_obj, image_obj)

        setJobState(oldstate => ({ ...oldstate, image: new_file }))

    };
    const clearContent = (type) => {
        setJobState(oldstate => ({ ...oldstate, image: null }))
    }
    const FieldListener = (type, event) => {
        event.persist();
        setJobState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }
    const FieldInstructionListener = (type, event) => {
        event.persist();
        setJobState(oldstate => ({
            ...oldstate, ["instructions"]: {
                ...oldstate.instructions,
                [type]: event.target.value
            }
        }))
    }
    useEffect(() => {
        if (stepState.step === 1) {
            if (jobState.name && jobState.title && jobState.tell_us) {
                setStepState(oldstate => ({ ...oldstate, "continue": true }))
            }
        }

        if (stepState.step === 2) {
            if (jobState.video_or_image && jobState.video_length) {
                setStepState(oldstate => ({ ...oldstate, "continue": true }))
            }
        }
    }, [jobState])
    
    const CanIContinue = () => {
        if (stepState.continue && stepState.step < 3) {
            setStepState(oldstate => ({ ...oldstate, "step": stepState.step + 1, "continue": true }))

        }
        if (stepState.continue && stepState.step === 2) {
            setStepState(oldstate => ({ ...oldstate, "step": stepState.step + 1, "continue": true }))
        }
        if (stepState.step === 3) {
            let default_brand = null
            axios({
                headers: {
                    'Authorization': localStorage.auth_token,
                    'X-CSRFToken': csrftoken,
                },
                method: 'post',
                url: process.env.API_URL + "api/im-i-allowed/",
                data: {
                    type: "job"
                }
            })
                .then(function (response) {
                    const _product = Object.assign({
                        product_name: jobState.product_name,
                        product_description: jobState.product_description
                    });
                    axios({
                        headers: {
                            'Authorization': localStorage.auth_token,
                            'X-CSRFToken': csrftoken,
                            "Content-Type": "multipart/form-data"
                        },
                        method: 'post',
                        url: process.env.API_URL + "api/job/",
                        data: {
                            method: "create",
                            name: jobState.name,
                            title: jobState.title,
                            tell_us: jobState.tell_us,
                            briefing: jobState.briefing,
                            product: _product,
                            media_type: jobState.video_or_image,
                            content_type: jobState.content_type,
                            content_format: jobState.content_format,
                            content_information: {
                                min_price: jobState.min_price,
                                max_price: jobState.max_price,
                                min_duration: jobState.min_duration,
                                max_duration: jobState.max_duration
                            },
                            country: jobState.country,
                            language: jobState.language,
                            reward: jobState.reward,
                            brand: jobState.brand || default_brand,
                            image: jobState.image,
                            instructions: {
                                dont_1: jobState.instructions.dont_1,
                                dont_2: jobState.instructions.dont_2,
                                dont_3: jobState.instructions.dont_3,
                                dont_4: jobState.instructions.dont_4,
                                do_1: jobState.instructions.do_1,
                                do_2: jobState.instructions.do_2,
                                do_3: jobState.instructions.do_3,
                                do_4: jobState.instructions.do_4
                            }
                        }

                    })
                        .then(function (response) {
                            setStepState(oldstate => ({ ...oldstate, "step": stepState.step + 1, "continue": false }))
                        })
                        .catch(function (error) {
                            navigate("/app/something-went-wrong/")
                        });
                })
                .catch(function (response) {

                })


        }

    }

    useEffect(() => {
        axios({
            headers: {
                'X-CSRFToken': csrftoken
            },
            method: 'get',
            url: process.env.API_URL + "api/countries/",
        })
            .then(function (response) {
                if (response && response.data && response.data.countries) {
                    setJobState(oldstate => ({ ...oldstate, countries: response.data.countries }))
                    setJobState(oldstate => ({ ...oldstate, languages: response.data.languages }))
                }

            })
    }, []);
    useEffect(() => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: 'get',
            url: `${process.env.API_URL}api/brands/`,
        })
            .then(function (response) {
                if (response && response.data) {
                    let brand_list = []
                    response.data.map((result, i) => {
                        brand_list.push({ "value": result.id, "label": result.name })
                    })
                    setBrandListState({ data: brand_list })
                    setJobState(oldstate => {
                        let state_result = {
                            ...oldstate,
                            brand: response.data[0]['id'],
                            logo: response.data[0]['logo']

                        };
                        return state_result
                    });
                }
            })
            .catch(function (error) {
            });
    }, [])
    const contentTypeText = () => {
        if ("unboxing_video" === jobState.content_type) {
            return {
                "title": "Unboxing video",
                "text": "Entertaining unboxing video showing the packaging and what comes along with the product.",
                "content": [Video, Video, Video]
            }
        } else if ("how_to_video" === jobState.content_type) {
            return {
                "title": "How to video",
                "text": "Detailed video tutorial to guide customers through all steps of using your product",
                "content": [Video, Video, Video]
            }
        } else if ("product_review" === jobState.content_type) {
            return {
                "title": "Product review",
                "text": "Positive feedback videos where creators spread the word about how awesome your product is.",
                "content": [Video, Video, Video]
            }
        } else if ("product_demo" === jobState.content_type) {
            return {
                "title": "Product demo",
                "text": "Detailed on-camera demonstration of how your product works and looks in real life.",
                "content": [Video, Video]
            }
        } else if ("picture_with_product" === jobState.content_type) {
            return {
                "title": "Picture with product",
                "text": "Content creators creates a picture with the content creator and the product on.",
                "content": [Image_1, Image_1, Image_1]
            }
        } else if ("lifestyle_shot" === jobState.content_type) {
            return {
                "title": "Lifestyle shot",
                "text": "A lifestyle shot is a photograph that captures individuals engaged with the product.",
                "content": [Image_1, Image_1]
            }
        } else if ("picture_of_product" === jobState.content_type) {
            return {
                "title": "Picture of the product only",
                "text": "A product shot features only the product itself.",
                "content": [Image_1, Image_1]
            }
        }

    }
    const contentType = () => {
        if (jobState.content_type == "unboxing_video") {
            return "Unboxing video"
        } else if (jobState.content_type == "how_to_video") {
            return "How to video"
        } else if (jobState.content_type == "product_review") {
            return "product Review"
        } else if (jobState.content_type == "product_demo") {
            return "Product Demo"

        } else if (jobState.content_type == "picture_with_product") {
            return "Picture with product"
        } else if (jobState.content_type == "lifestyle_shot") {
            return "Lifestyle shot"

        } else if (jobState.content_type == "picture_of_product") {
            return "Picture of the product"
        } else {
            return "-"
        }

    }
    let viewStep2 = null
    switch (jobState.video_or_image) {
        case "video":
            viewStep2 = <>

                <div className="col-12">
                    <div className="card wizard--card">
                        <div className="wizard--label">
                            Content type
                        </div>
                        <div className="wizard--sublabel">

                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                <div className={`card content-type--card ${jobState && jobState.content_type === "unboxing_video" ? "content-type--card--active" : null}`} onClick={() => { setJobState(oldstate => ({ ...oldstate, content_type: "unboxing_video" })) }}>
                                    <div className="content-type--inner">
                                        <div className="content-type--title">
                                            Unboxing video
                                        </div>
                                        <div className="content-type--selector">
                                            <Icon
                                                type={"fa-solid"}
                                                icon={"circle-check"}
                                                icon_size={1}
                                                icon_color_code={"#B390FF"}
                                            ></Icon>
                                        </div>
                                    </div>
                                </div>
                                <div className={`card content-type--card ${jobState && jobState.content_type === "how_to_video" ? "content-type--card--active" : null}`} onClick={() => { setJobState(oldstate => ({ ...oldstate, content_type: "how_to_video" })) }}>
                                    <div className="content-type--inner">
                                        <div className="content-type--title">
                                            How to video
                                        </div>
                                        <div className="content-type--selector">
                                            <Icon
                                                type={"fa-solid"}
                                                icon={"circle-check"}
                                                icon_size={1}
                                                icon_color_code={"#B390FF"}
                                            ></Icon>
                                        </div>
                                    </div>
                                </div>
                                <div className={`card content-type--card ${jobState && jobState.content_type === "product_review" ? "content-type--card--active" : null}`} onClick={() => { setJobState(oldstate => ({ ...oldstate, content_type: "product_review" })) }}>
                                    <div className="content-type--inner">
                                        <div className="content-type--title">
                                            Product review
                                        </div>
                                        <div className="content-type--selector">
                                            <Icon
                                                type={"fa-solid"}
                                                icon={"circle-check"}
                                                icon_size={1}
                                                icon_color_code={"#B390FF"}
                                            ></Icon>
                                        </div>
                                    </div>
                                </div>
                                <div className={`card content-type--card ${jobState && jobState.content_type === "product_demo" ? "content-type--card--active" : null}`} onClick={() => { setJobState(oldstate => ({ ...oldstate, content_type: "product_demo" })) }}>
                                    <div className="content-type--inner">
                                        <div className="content-type--title">
                                            Product demo
                                        </div>
                                        <div className="content-type--selector">
                                            <Icon
                                                type={"fa-solid"}
                                                icon={"circle-check"}
                                                icon_size={1}
                                                icon_color_code={"#B390FF"}
                                            ></Icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                                <div className="content-type--explain-short">
                                    {contentTypeText()['title']}
                                </div>
                                <div className="content-type--explain-long">
                                    {contentTypeText()['text']}
                                </div>
                                <div className="content-type--video--container">
                                    {jobState && jobState.video_or_image === "video" ?
                                        contentTypeText()['content'].map((video, key) => (
                                            <video
                                                controls
                                                muted={false}
                                                autoPlay={false}
                                                className="video"
                                            >
                                                <source src={video} type="video/mp4" />
                                            </video>
                                        ))
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </>
            break;

        case "image":
            viewStep2 = <>
                <div className="col-12">
                    <div className="card wizard--card">
                        <div className="wizard--label">
                            Content type
                        </div>
                        <div className="wizard--sublabel">

                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                <div className={`card content-type--card ${jobState && jobState.content_type === "picture_with_product" ? "content-type--card--active" : null}`} onClick={() => { setJobState(oldstate => ({ ...oldstate, content_type: "picture_with_product" })) }}>
                                    <div className="content-type--inner">
                                        <div className="content-type--title">
                                            Picture with product
                                        </div>
                                        <div className="content-type--selector">
                                            <Icon
                                                type={"fa-solid"}
                                                icon={"circle-check"}
                                                icon_size={1}
                                                icon_color_code={"#B390FF"}
                                            ></Icon>
                                        </div>
                                    </div>
                                </div>
                                <div className={`card content-type--card ${jobState && jobState.content_type === "lifestyle_shot" ? "content-type--card--active" : null}`} onClick={() => { setJobState(oldstate => ({ ...oldstate, content_type: "lifestyle_shot" })) }}>
                                    <div className="content-type--inner">
                                        <div className="content-type--title">
                                            Lifestyle shot
                                        </div>
                                        <div className="content-type--selector">
                                            <Icon
                                                type={"fa-solid"}
                                                icon={"circle-check"}
                                                icon_size={1}
                                                icon_color_code={"#B390FF"}
                                            ></Icon>
                                        </div>
                                    </div>
                                </div>
                                <div className={`card content-type--card ${jobState && jobState.content_type === "picture_of_product" ? "content-type--card--active" : null}`} onClick={() => { setJobState(oldstate => ({ ...oldstate, content_type: "picture_of_product" })) }}>
                                    <div className="content-type--inner">
                                        <div className="content-type--title">
                                            Picture of the product
                                        </div>
                                        <div className="content-type--selector">
                                            <Icon
                                                type={"fa-solid"}
                                                icon={"circle-check"}
                                                icon_size={1}
                                                icon_color_code={"#B390FF"}
                                            ></Icon>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                                <div className="content-type--explain-short">
                                    {contentTypeText()['title']}
                                </div>
                                <div className="content-type--explain-long">
                                    {contentTypeText()['text']}
                                </div>
                                <div className="content-type--video--container">
                                    {jobState && jobState.video_or_image === "image" ?
                                        contentTypeText()['content'].map((image, key) => (
                                            <img className='video_or_image--image' src={image}></img>
                                        ))
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card wizard--card">
                        <div className="wizard--label">
                            Content format
                        </div>
                        <div className="wizard--sublabel">

                        </div>
                        <div className="wizard--field">
                            <div className="wizard--options row">
                                <div className={`wizard--option  ${jobState && jobState.content_format === "any" ? "wizard--option--active" : ""}`} onClick={() => { setJobState(oldstate => ({ ...oldstate, content_format: "any" })) }}>
                                    <Icon
                                        type={"fa"}
                                        icon={"image"}
                                        icon_size={4}
                                        icon_color_code={"#B390FF"}
                                    ></Icon>
                                    <div className="wizard--title">
                                        Any
                                    </div>
                                </div>
                                <div className={`wizard--option  ${jobState && jobState.content_format === "portrait" ? "wizard--option--active" : ""}`} onClick={() => { setJobState(oldstate => ({ ...oldstate, content_format: "portrait" })) }}>
                                    <Icon
                                        type={"fa"}
                                        icon={"image-portrait"}
                                        icon_size={4}
                                        icon_color_code={"#B390FF"}
                                    ></Icon>
                                    <div className="wizard--title">
                                        Portrait
                                    </div>
                                </div>
                                <div className={`wizard--option  ${jobState && jobState.content_format === "landscape" ? "wizard--option--active" : ""}`} onClick={() => { setJobState(oldstate => ({ ...oldstate, content_format: "landscape" })) }}>
                                    <Icon
                                        type={"fa"}
                                        icon={"panorama"}
                                        icon_size={4}
                                        icon_color_code={"#B390FF"}
                                    ></Icon>
                                    <div className="wizard--title">
                                        Landscape
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            break;

    }

    switch (stepState.step) {
        case 1:
            currentPage = <>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Brand
                            </div>
                            <div className="wizard--field">
                                {/* <Select
                                    onChange={(e) => FieldListener("media_format", e)}
                                    name="Media Format"
                                    label={"media_format"}
                                    options={[
                                        { label: "Any", value: "any" },
                                        { label: "Portrait", value: "portrait" },
                                        { label: "Landscape", value: "landscape" }
                                    ] || []}
                                    selected={"Any" || ""}
                                /> */}
                                <Select
                                    onChange={(e) => FieldListener("brand", e)}
                                    name="Brand"
                                    label={"brand"}
                                    options={brandsListState.data || []}
                                    selected={jobState.brand || ""}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Job name ( Only you are able to see it )
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("name", e)}
                                    type="text"
                                    label=""
                                    name="name"
                                    value={jobState.name || ""}
                                    placeholder="Enter the name of the job"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Job title ( Visible for the end user )
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("title", e)}
                                    type="text"
                                    label=""
                                    name="title"
                                    value={jobState.title || ''}
                                    placeholder="Enter the title of the job"
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Tell more about your Company
                            </div>
                            <div className="wizard--field">
                                <TextArea
                                    onChange={(e) => FieldListener("tell_us", e)}
                                    label=""
                                    name="tell_us"
                                    value={jobState.tell_us || ''}
                                    placeholder={"Describe the steps the content creator should follow"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Job briefing
                            </div>
                            <div className="wizard--field">
                                <TextArea
                                    onChange={(e) => FieldListener("briefing", e)}
                                    label=""
                                    name="text"
                                    value={jobState.briefing || ''}
                                    placeholder={"Describe the steps the content creator should follow"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Country
                            </div>
                            <div className="wizard--field">
                                <Select
                                    onChange={(e) => FieldListener("country", e)}
                                    name="country"
                                    label={"Country"}
                                    options={jobState.countries || []}
                                    selected={jobState.country || ""}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Language
                            </div>
                            <div className="wizard--field">
                                <Select
                                    onChange={(e) => FieldListener("language", e)}
                                    name="language"
                                    label={"Language"}
                                    options={jobState.languages || []}
                                    selected={jobState.language || ""}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="card video_or_image--card">
                            <div className="video_or_image--label">Choose between content type</div>
                            <div className="video_or_image--options">
                                <div onClick={() => { setJobState(oldstate => ({ ...oldstate, video_or_image: "video", content_type: "unboxing_video" })), clearContent("image") }} className={`card video_or_image--option ${jobState.video_or_image === "video" ? "video_or_image--option--active" : ""}`}>
                                    <Icon
                                        type={"fa"}
                                        icon={"stopwatch"}
                                        icon_size={4}
                                        icon_color_code={"#B390FF"}
                                    ></Icon>
                                    <div className="video_or_image--title">
                                        Video
                                    </div>
                                </div>
                                {/* <div onClick={() => { setJobState(oldstate => ({ ...oldstate, video_or_image: "image", content_type: "picture_with_product" })), clearContent("video") }} className={`card video_or_image--option ${jobState.video_or_image === "image" ? "video_or_image--option--active" : ""}`}>
                                    <Icon
                                        type={"fa"}
                                        icon={"image"}
                                        icon_size={4}
                                        icon_color_code={"#B390FF"}
                                    ></Icon>
                                    <div className="video_or_image--title">
                                        Image
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">

                    {viewStep2}
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Reward
                            </div>
                            <div className="wizard--field">
                                {/* <Select
                                    onChange={(e) => FieldListener("media_format", e)}
                                    name="Media Format"
                                    label={"media_format"}
                                    options={[
                                        { label: "Any", value: "any" },
                                        { label: "Portrait", value: "portrait" },
                                        { label: "Landscape", value: "landscape" }
                                    ] || []}
                                    selected={"Any" || ""}
                                /> */}
                                <Select
                                    onChange={(e) => FieldListener("reward", e)}
                                    name="Reward"
                                    label={"reward"}
                                    options={[
                                        { label: "Paid", value: "paid" },
                                        { label: "Gifted", value: "gifted" }
                                    ] || []}
                                    selected={jobState.reward || "paid"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        {jobState && jobState.reward === "paid" ?
                            <div className="card wizard--card">
                                <div className="wizard--label">
                                    Price Range
                                </div>
                                <div className="wizard--field">
                                    <Input
                                        onChange={(e) => FieldListener("min_price", e)}
                                        type="text"
                                        label=""
                                        name="min_price"
                                        value={jobState.min_price || "1"}
                                        placeholder="Minimum price"

                                    />
                                </div>
                                <div className="wizard--field">
                                    <Input
                                        onChange={(e) => FieldListener("max_price", e)}
                                        type="text"
                                        label=""
                                        name="max_price"
                                        value={jobState.max_price || "1"}
                                        placeholder="Maximum price"

                                    />
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        {jobState && jobState.video_or_image == "video" ?

                            <div className="card wizard--card">
                                <div className="wizard--label">
                                    Duration ( In seconds )
                                </div>
                                <div className="wizard--field">
                                    <Input
                                        onChange={(e) => FieldListener("min_duration", e)}
                                        type="text"
                                        label=""
                                        name="min_duration"
                                        value={jobState.min_duration || "1"}
                                        placeholder="Minimum duration"
                                    />
                                </div>
                                <div className="wizard--field">
                                    <Input
                                        onChange={(e) => FieldListener("max_duration", e)}
                                        type="text"
                                        label=""
                                        name="max_duration"
                                        value={jobState.max_duration || '1'}
                                        placeholder="Maximum duration"
                                    />
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </>
            break;

        case 2:
            currentPage = <>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Product Name
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldListener("product_name", e)}
                                    type="text"
                                    label=""
                                    name="product_name"
                                    value={jobState.product_name || ''}
                                    placeholder="Product"
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Product Description
                            </div>
                            <div className="wizard--field">
                                <TextArea
                                    onChange={(e) => FieldListener("product_description", e)}
                                    label=""
                                    name="text"
                                    value={jobState.product_description || ''}
                                    placeholder={"Product description"}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Dont's
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldInstructionListener("dont_1", e)}
                                    type="text"
                                    label=""
                                    name="dont_1"
                                    value={jobState.instructions.dont_1 || ''}
                                    placeholder="Dont's"
                                />
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldInstructionListener("dont_2", e)}
                                    type="text"
                                    label=""
                                    name="dont_2"
                                    value={jobState.instructions.dont_2 || ''}
                                    placeholder="Dont's"
                                />
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldInstructionListener("dont_3", e)}
                                    type="text"
                                    label=""
                                    name="dont_3"
                                    value={jobState.instructions.dont_3 || ''}
                                    placeholder="Dont's"
                                />
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldInstructionListener("dont_4", e)}
                                    type="text"
                                    label=""
                                    name="dont_4"
                                    value={jobState.instructions.dont_4 || ''}
                                    placeholder="Dont's"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card wizard--card">
                            <div className="wizard--label">
                                Do's
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldInstructionListener("do_1", e)}
                                    type="text"
                                    label=""
                                    name="do_1"
                                    value={jobState.instructions.do_1 || ''}
                                    placeholder="Do's"
                                />
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldInstructionListener("do_2", e)}
                                    type="text"
                                    label=""
                                    name="do_2"
                                    value={jobState.instructions.do_2 || ''}
                                    placeholder="Do's"
                                />
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldInstructionListener("do_3", e)}
                                    type="text"
                                    label=""
                                    name="do_3"
                                    value={jobState.instructions.do_3 || ''}
                                    placeholder="Do's"
                                />
                            </div>
                            <div className="wizard--field">
                                <Input
                                    onChange={(e) => FieldInstructionListener("do_4", e)}
                                    type="text"
                                    label=""
                                    name="do_4"
                                    value={jobState.instructions.do_4 || ''}
                                    placeholder="Do's"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 create-col-video">
                        <div className="example_video">Product image</div>
                        <FileUpload
                            fileUpload={fileImageUploadListener}
                            clearContent={() => { clearContent("image") }}
                            type="file"
                            name="iamge"
                            ariaLabel="image"
                            image={jobState.image || null}
                            inputLabel={""}
                        />
                    </div>
                </div>
            </>
            break;
        case 3:
            currentPage = <>
                <div className="jubugcform__container">
                    <div className="jobugcform__card">
                        <div className="jobugcform__card--top">
                            <div className="jobugcform__map--title">
                                Job Overview
                            </div>
                        </div>
                        <div className="jobugcform__card--body card">
                            <div className="job__ugcform__overview--top row">
                                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-job-overview" style={{ display: "flex" }}>

                                    {jobState && jobState.logo ?

                                        <div className="job__ugcform__logo" style={{ paddingRight: "25px" }}>
                                            <div className='card-user-image' ><img className='circle-image sendugcform--image-header' src={`${process.env.API_URL}${jobState.logo.substr(1)}`}></img></div>
                                        </div>
                                        : null}

                                    <div className="job__ugcform__overview_info">
                                        <div className="">
                                            {jobState && jobState.brand && jobState.brand.name ? jobState.brand.name : null}
                                        </div>
                                        <div className="job__ugcform__overview_info_job_title">
                                            {jobState ? jobState.title : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 apply_now">
                                    <div className="job__ugcform__overview_info_button">
                                        Apply now
                                    </div>
                                </div>
                            </div>
                            <div className="job__ugcform__atc__container" style={{ display: "flex" }}>
                                {jobState && jobState.brand && jobState.brand.website ? <div className="job__ugcform__overview_info_button--outline"> <a target="_blank" href={jobState.brand.website}>View Website</a></div> : null}

                                <div className="job__ugcform__socials">
                                    {jobState && jobState.brand && jobState.brand.tiktok ?
                                        <div className="social--container">
                                            <a href={`https://tiktok.com/@${jobState.brand.tiktok}/`} target="_blank">
                                                <div className="job__ugcform__socials--icon">
                                                    <Icon
                                                        type={"fa-brands"}
                                                        icon={"tiktok"}
                                                        icon_size={1}
                                                        icon_color_code={"#ffffff"}
                                                    ></Icon></div></a>
                                        </div>
                                        : null}

                                    {jobState && jobState.brand && jobState.brand.instagram ?
                                        <div className="social--container">
                                            <a href={`https://www.instagram.com/${jobState.brand.instagram}/`} target="_blank">
                                                <div className="job__ugcform__socials--icon">
                                                    <Icon
                                                        type={"fa-brands"}
                                                        icon={"instagram"}
                                                        icon_size={1}
                                                        icon_color_code={"#ffffff"}
                                                    ></Icon></div></a>
                                        </div>
                                        : null}
                                    {jobState && jobState.brand && jobState.brand.facebook ?
                                        <div className="social--container">
                                            <a href={`https://www.facebook.com/${jobState.brand.facebook}/`} target="_blank">
                                                <div className="job__ugcform__socials--icon">
                                                    <Icon
                                                        type={"fa-brands"}
                                                        icon={"facebook"}
                                                        icon_size={1}
                                                        icon_color_code={"#ffffff"}
                                                    ></Icon></div></a>
                                        </div>
                                        : null}
                                </div>
                                {/* <div className="job__ugcform__overview_end">
                                <div className="calendar">
                                    <Icon
                                        type={"fa-regular"}
                                        icon={"calendar-xmark"}
                                        icon_size={1}
                                        icon_color_code={"#ffffff"}
                                    ></Icon>
                                </div>
                                <div>
                                    <div style={{ fontSize: "14px", fontWeight: "600" }}>Job End</div>
                                    <div style={{ fontSize: "14px" }}>Tue, Mar 5, 2024</div>
                                </div>
                            </div> */}
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="job__ugcform__second_title">About Company</div>
                                <div className="job__ugcform__description">{jobState.tell_us}</div>
                            </div>
                            <div className="row">
                                <div className="job__ugcform__second_title">Content information</div>
                                <div className="job__ugcform__description row" style={{ display: "flex" }}>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }}>
                                        <div className="job__ugcform__container" style={{ marginRight: "80px" }}>
                                            <div>Type</div>
                                            <div>Media Format</div>
                                            <div>Duration</div>
                                            <div>Price range</div>
                                        </div>
                                        <div className="job__ugcform__container">
                                            {jobState && jobState.media_type === "video" ?
                                                <div>  <Icon
                                                    type={"fa-regular"}
                                                    icon={"video"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon> Video</div>
                                                :
                                                <div>  <Icon
                                                    type={"fa-regular"}
                                                    icon={"camera"}
                                                    icon_size={1}
                                                    icon_color_code={"#B390FF"}
                                                ></Icon> Camera</div>
                                            }
                                            <div>{jobState && jobState.content_format ? jobState.content_format : null}</div>
                                            <div>{jobState && jobState.min_duration ? jobState.min_duration : 0} - {jobState && jobState.max_duration ? jobState.max_duration : 0} seconds</div>

                                            {jobState && jobState.reward === "paid" ?
                                                <div>{jobState && jobState.min_price ? jobState.min_price : 0} - {jobState && jobState.max_price ? jobState.max_price : 0}</div>
                                                : <div>Gifted</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }}>
                                        <div className="job__ugcform__container" style={{ marginRight: "80px" }}>
                                            <div>Country</div>
                                            <div>Language</div>
                                            <div>Content type</div>
                                        </div>
                                        <div className="job__ugcform__container">
                                            <div>Netherlands</div>
                                            <div>Dutch</div>
                                            <div>{contentType()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="jobugcform__card" style={{ marginTop: "50px" }}>
                        <div className="jobugcform__card--top">
                            <div className="jobugcform__map--title">
                                Job Details
                            </div>
                        </div>
                        <div className="jobugcform__card--body card" style={{ marginBottom: "75px" }}>
                            <div className="job__ugcform__overview--top">
                                {jobState && jobState.image ?
                                    <div className="job__ugcform__logo" style={{ paddingRight: "25px" }}>
                                        <div className='' ><img className='' src={jobState.image.url}></img></div>
                                    </div>
                                    : null}
                                <div className="job__ugcform__overview_info">
                                    <div className="">
                                        {jobState && jobState && jobState.product_name ? jobState.product_name : null}
                                    </div>
                                    <div className="job__ugcform__overview_info_job_title">
                                        UGCBird - March 2024
                                    </div>
                                </div>
                            </div>

                            <hr></hr>
                            <div className="row">
                                <div className="job__ugcform__second_title">Briefing</div>
                                <div className="job__ugcform__description">{jobState && jobState.briefing ? jobState.briefing : null}</div>
                            </div>
                            <div className="row">
                                <div className="job__ugcform__second_title">Instructions</div>
                                <div className="row" style={{ marginTop: "15px", margin: "auto", padding: "0px" }}>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-to-do">
                                        <div className="job__ugcform__icon_card" style={{ borderLeftColor: "#16AF92", borderLeftWidth: "5px" }}>
                                            {/* <div className="job__ugcform__positive_icon">
                                            <Icon
                                                type={"fa-solid"}
                                                icon={"check"}
                                                icon_size={1}
                                                icon_color_code={"#ffffff"}
                                            ></Icon>
                                        </div> */}
                                            <div className="job__ugcform__instructions__title">
                                                To Do
                                            </div>
                                            <div className="instructions__list">
                                                {jobState && jobState.instructions && jobState.instructions.do_1 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M6.00241 8.14759L4.6375 9.5125L9.025 13.9L18.775 4.15L17.4101 2.78509L9.025 11.1212L6.00241 8.14759ZM17.8 10C17.8 14.2899 14.2899 17.8 10 17.8C5.71014 17.8 2.2 14.2899 2.2 10C2.2 5.71009 5.71009 2.2 10 2.2C10.7313 2.2 11.4625 2.29759 12.1452 2.49255L13.6562 0.98125C12.5351 0.49375 11.3163 0.25 10 0.25C4.6375 0.25 0.25 4.6375 0.25 10C0.25 15.3625 4.6375 19.75 10 19.75C15.3625 19.75 19.75 15.3625 19.75 10H17.8Z" fill="#16AF92" />
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {jobState.instructions.do_1}
                                                        </span>
                                                    </div>
                                                    : null}
                                                {jobState && jobState.instructions && jobState.instructions.do_2 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M6.00241 8.14759L4.6375 9.5125L9.025 13.9L18.775 4.15L17.4101 2.78509L9.025 11.1212L6.00241 8.14759ZM17.8 10C17.8 14.2899 14.2899 17.8 10 17.8C5.71014 17.8 2.2 14.2899 2.2 10C2.2 5.71009 5.71009 2.2 10 2.2C10.7313 2.2 11.4625 2.29759 12.1452 2.49255L13.6562 0.98125C12.5351 0.49375 11.3163 0.25 10 0.25C4.6375 0.25 0.25 4.6375 0.25 10C0.25 15.3625 4.6375 19.75 10 19.75C15.3625 19.75 19.75 15.3625 19.75 10H17.8Z" fill="#16AF92" />
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {jobState.instructions.do_2}
                                                        </span>
                                                    </div>
                                                    : null}
                                                {jobState && jobState.instructions && jobState.instructions.do_3 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M6.00241 8.14759L4.6375 9.5125L9.025 13.9L18.775 4.15L17.4101 2.78509L9.025 11.1212L6.00241 8.14759ZM17.8 10C17.8 14.2899 14.2899 17.8 10 17.8C5.71014 17.8 2.2 14.2899 2.2 10C2.2 5.71009 5.71009 2.2 10 2.2C10.7313 2.2 11.4625 2.29759 12.1452 2.49255L13.6562 0.98125C12.5351 0.49375 11.3163 0.25 10 0.25C4.6375 0.25 0.25 4.6375 0.25 10C0.25 15.3625 4.6375 19.75 10 19.75C15.3625 19.75 19.75 15.3625 19.75 10H17.8Z" fill="#16AF92" />
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {jobState.instructions.do_3}
                                                        </span>
                                                    </div>
                                                    : null}
                                                {jobState && jobState.instructions && jobState.instructions.do_4 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M6.00241 8.14759L4.6375 9.5125L9.025 13.9L18.775 4.15L17.4101 2.78509L9.025 11.1212L6.00241 8.14759ZM17.8 10C17.8 14.2899 14.2899 17.8 10 17.8C5.71014 17.8 2.2 14.2899 2.2 10C2.2 5.71009 5.71009 2.2 10 2.2C10.7313 2.2 11.4625 2.29759 12.1452 2.49255L13.6562 0.98125C12.5351 0.49375 11.3163 0.25 10 0.25C4.6375 0.25 0.25 4.6375 0.25 10C0.25 15.3625 4.6375 19.75 10 19.75C15.3625 19.75 19.75 15.3625 19.75 10H17.8Z" fill="#16AF92" />
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {jobState.instructions.do_4}
                                                        </span>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="job__ugcform__icon_card" style={{ borderLeftColor: "#af1616", borderLeftWidth: "5px" }}>
                                            {/* <div className="job__ugcform__negative_icon">
                                        <Icon
                                            type={"fa-solid"}
                                            icon={"check"}
                                            icon_size={1}
                                            icon_color_code={"#ffffff"}
                                        ></Icon>
                                    </div> */}
                                            <div className="job__ugcform__instructions__title_negative">
                                                Dont's
                                            </div>
                                            <div className="instructions__list">
                                                {jobState && jobState.instructions && jobState.instructions.dont_1 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                                <g clipPath="url(#clip0_870_244)">
                                                                    <path d="M9.5 19C4.275 19 0 14.725 0 9.5C0 4.275 4.275 0 9.5 0C14.725 0 19 4.275 19 9.5C19 14.725 14.725 19 9.5 19ZM9.5 1.58333C5.14583 1.58333 1.58333 5.14583 1.58333 9.5C1.58333 13.8542 5.14583 17.4167 9.5 17.4167C13.8542 17.4167 17.4167 13.8542 17.4167 9.5C17.4167 5.14583 13.8542 1.58333 9.5 1.58333Z" fill="#FC5A5A" />
                                                                    <path d="M6.33366 13.4583C6.09616 13.4583 5.93783 13.3791 5.77949 13.2208C5.46283 12.9041 5.46283 12.4291 5.77949 12.1125L12.1128 5.77913C12.4295 5.46246 12.9045 5.46246 13.2212 5.77913C13.5378 6.09579 13.5378 6.57079 13.2212 6.88746L6.88783 13.2208C6.72949 13.3791 6.57116 13.4583 6.33366 13.4583Z" fill="#FC5A5A" />
                                                                    <path d="M12.667 13.4583C12.4295 13.4583 12.2712 13.3791 12.1128 13.2208L5.77949 6.88746C5.46283 6.57079 5.46283 6.09579 5.77949 5.77913C6.09616 5.46246 6.57116 5.46246 6.88783 5.77913L13.2212 12.1125C13.5378 12.4291 13.5378 12.9041 13.2212 13.2208C13.0628 13.3791 12.9045 13.4583 12.667 13.4583Z" fill="#FC5A5A" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_870_244">
                                                                        <rect width="19" height="19" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {jobState.instructions.dont_1}
                                                        </span>
                                                    </div>
                                                    : null}
                                                {jobState && jobState.instructions && jobState.instructions.dont_2 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                                <g clipPath="url(#clip0_870_244)">
                                                                    <path d="M9.5 19C4.275 19 0 14.725 0 9.5C0 4.275 4.275 0 9.5 0C14.725 0 19 4.275 19 9.5C19 14.725 14.725 19 9.5 19ZM9.5 1.58333C5.14583 1.58333 1.58333 5.14583 1.58333 9.5C1.58333 13.8542 5.14583 17.4167 9.5 17.4167C13.8542 17.4167 17.4167 13.8542 17.4167 9.5C17.4167 5.14583 13.8542 1.58333 9.5 1.58333Z" fill="#FC5A5A" />
                                                                    <path d="M6.33366 13.4583C6.09616 13.4583 5.93783 13.3791 5.77949 13.2208C5.46283 12.9041 5.46283 12.4291 5.77949 12.1125L12.1128 5.77913C12.4295 5.46246 12.9045 5.46246 13.2212 5.77913C13.5378 6.09579 13.5378 6.57079 13.2212 6.88746L6.88783 13.2208C6.72949 13.3791 6.57116 13.4583 6.33366 13.4583Z" fill="#FC5A5A" />
                                                                    <path d="M12.667 13.4583C12.4295 13.4583 12.2712 13.3791 12.1128 13.2208L5.77949 6.88746C5.46283 6.57079 5.46283 6.09579 5.77949 5.77913C6.09616 5.46246 6.57116 5.46246 6.88783 5.77913L13.2212 12.1125C13.5378 12.4291 13.5378 12.9041 13.2212 13.2208C13.0628 13.3791 12.9045 13.4583 12.667 13.4583Z" fill="#FC5A5A" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_870_244">
                                                                        <rect width="19" height="19" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {jobState.instructions.dont_2}
                                                        </span>
                                                    </div>
                                                    : null}
                                                {jobState && jobState.instructions && jobState.instructions.dont_3 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                                <g clipPath="url(#clip0_870_244)">
                                                                    <path d="M9.5 19C4.275 19 0 14.725 0 9.5C0 4.275 4.275 0 9.5 0C14.725 0 19 4.275 19 9.5C19 14.725 14.725 19 9.5 19ZM9.5 1.58333C5.14583 1.58333 1.58333 5.14583 1.58333 9.5C1.58333 13.8542 5.14583 17.4167 9.5 17.4167C13.8542 17.4167 17.4167 13.8542 17.4167 9.5C17.4167 5.14583 13.8542 1.58333 9.5 1.58333Z" fill="#FC5A5A" />
                                                                    <path d="M6.33366 13.4583C6.09616 13.4583 5.93783 13.3791 5.77949 13.2208C5.46283 12.9041 5.46283 12.4291 5.77949 12.1125L12.1128 5.77913C12.4295 5.46246 12.9045 5.46246 13.2212 5.77913C13.5378 6.09579 13.5378 6.57079 13.2212 6.88746L6.88783 13.2208C6.72949 13.3791 6.57116 13.4583 6.33366 13.4583Z" fill="#FC5A5A" />
                                                                    <path d="M12.667 13.4583C12.4295 13.4583 12.2712 13.3791 12.1128 13.2208L5.77949 6.88746C5.46283 6.57079 5.46283 6.09579 5.77949 5.77913C6.09616 5.46246 6.57116 5.46246 6.88783 5.77913L13.2212 12.1125C13.5378 12.4291 13.5378 12.9041 13.2212 13.2208C13.0628 13.3791 12.9045 13.4583 12.667 13.4583Z" fill="#FC5A5A" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_870_244">
                                                                        <rect width="19" height="19" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {jobState.instructions.dont_3}
                                                        </span>
                                                    </div>
                                                    : null}
                                                {jobState && jobState.instructions && jobState.instructions.dont_4 ?
                                                    <div className="instructions__item">
                                                        <div className="instructions__items--svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                                <g clipPath="url(#clip0_870_244)">
                                                                    <path d="M9.5 19C4.275 19 0 14.725 0 9.5C0 4.275 4.275 0 9.5 0C14.725 0 19 4.275 19 9.5C19 14.725 14.725 19 9.5 19ZM9.5 1.58333C5.14583 1.58333 1.58333 5.14583 1.58333 9.5C1.58333 13.8542 5.14583 17.4167 9.5 17.4167C13.8542 17.4167 17.4167 13.8542 17.4167 9.5C17.4167 5.14583 13.8542 1.58333 9.5 1.58333Z" fill="#FC5A5A" />
                                                                    <path d="M6.33366 13.4583C6.09616 13.4583 5.93783 13.3791 5.77949 13.2208C5.46283 12.9041 5.46283 12.4291 5.77949 12.1125L12.1128 5.77913C12.4295 5.46246 12.9045 5.46246 13.2212 5.77913C13.5378 6.09579 13.5378 6.57079 13.2212 6.88746L6.88783 13.2208C6.72949 13.3791 6.57116 13.4583 6.33366 13.4583Z" fill="#FC5A5A" />
                                                                    <path d="M12.667 13.4583C12.4295 13.4583 12.2712 13.3791 12.1128 13.2208L5.77949 6.88746C5.46283 6.57079 5.46283 6.09579 5.77949 5.77913C6.09616 5.46246 6.57116 5.46246 6.88783 5.77913L13.2212 12.1125C13.5378 12.4291 13.5378 12.9041 13.2212 13.2208C13.0628 13.3791 12.9045 13.4583 12.667 13.4583Z" fill="#FC5A5A" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_870_244">
                                                                        <rect width="19" height="19" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                        <span>
                                                            {jobState.instructions.dont_4}
                                                        </span>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
            break;
        case 4:
            currentPage = <>
                <div style={{ textAlign: "center" }}>
                    <Link to="/app/job">
                        <div style={{ margin: "auto", marginTop: "20px", width: "250px" }} className="button-continue">Return to Jobs</div>
                    </Link>
                </div>

            </>
            break;

    }
    return (
        <>

            <div className="row">
                <div className="col-12">

                    <Link to={`/app/job/`} style={{ marginBottom: "20px" }}>
                        <span className="">
                            <Icon
                                type={"fa"}
                                icon={"chevron-left"}
                                icon_size={1}

                                icon_color_code={"#e3def9"}
                            ></Icon></span>
                        Back
                    </Link>

                    {modalState && modalState.modalIsOpen ? <SocialMediaPolicyModal text={jobState.social_media_policy} setModalState={setModalState}></SocialMediaPolicyModal> : null}
                </div>

                <div className="col-12">
                    <div className="module-steps">
                        <div className="module-step">
                            <div className={`module-step-icon ${stepState.step === 1 ? "module-step-icon-active" : ""}`}>
                                <Icon
                                    type={"fa"}
                                    icon={"flag-checkered"}
                                    icon_size={1}
                                    icon_color_code={"#BFC1C3"}
                                ></Icon>
                            </div>

                            <div className="module-step-text">
                                <div className="module-title">
                                    Create Job
                                </div>
                                <div className="module-text">
                                    Job details
                                </div>
                            </div>
                            <div className="module-step-arrow">
                                <Icon
                                    type={"fa"}
                                    icon={"arrow-right"}
                                    icon_size={1}
                                    icon_color_code={"#BFC1C3"}
                                ></Icon>
                            </div>
                        </div>
                        <div className="module-step">
                            <div className={`module-step-icon ${stepState.step === 2 ? "module-step-icon-active" : ""}`}>
                                <Icon
                                    type={"fa"}
                                    icon={"star"}
                                    icon_size={1}
                                    icon_color_code={"#BFC1C3"}
                                ></Icon>
                            </div>

                            <div className="module-step-text">
                                <div className="module-title">
                                    Add instructions
                                </div>
                                <div className="module-text">
                                    instructions details
                                </div>
                            </div>
                            <div className="module-step-arrow">
                                <Icon
                                    type={"fa"}
                                    icon={"arrow-right"}
                                    icon_size={1}
                                    icon_color_code={"#BFC1C3"}
                                ></Icon>
                            </div>
                        </div>
                        <div className="module-step">
                            <div className={`module-step-icon ${stepState.step === 3 ? "module-step-icon-active" : ""}`}>
                                <Icon
                                    type={"fa"}
                                    icon={"magnifying-glass"}
                                    icon_size={1}
                                    icon_color_code={"#BFC1C3"}
                                ></Icon>
                            </div>

                            <div className="module-step-text">
                                <div className="module-title">
                                    Preview
                                </div>
                                <div className="module-text">
                                    Review the preview
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-12">
                            <div className="progress-title">
                                {stepState.step < 4 ?
                                    <>
                                        <div className="progress-header">Ready to create your Job?</div>
                                        <div>Follow the three easy steps to finish and get going!</div>
                                    </>
                                    :
                                    <>
                                        <div className="progress-header">Congratz you created your Job!</div>
                                    </>

                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ margin: "auto" }}>
                            {currentPage}
                        </div>
                    </div>
                    {stepState.step !== 4 ?
                        <div className="row">
                            <div className="col-8 flexer">
                                <div onClick={() => { setStepState({ "step": stepState.step > 1 ? stepState.step - 1 : stepState.step, "continue": stepState.step > 1 ? true : stepState.continue }) }} className="button-back">back</div>

                                <div onClick={() => { CanIContinue() }} className={`button-continue button-continue_${stepState.continue}`}>Continue</div>
                            </div>

                        </div>
                        : null
                    }
                </div>
            </div>


        </>
    )
}
export default JobCreate;