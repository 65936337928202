import React, { useEffect, useState } from "react";
import axios from "axios";
import AppManager from "./AppManager";
import LazyLoadScreen from "../general/LazyLoadScreen";
import getCookie from '../auth/Cookie';
var csrftoken = getCookie('csrftoken');
const AppRender = ({ }) => {
    const [appRenderState, setAppRenderState] = useState({
        isAuth: false,
        access_token: "",
        refresh_token: "",
        auth_token: "",
        isLoaded: false,
        previous_path: "/"

    })
    useEffect(() => {
        // Check if Auth token is empty if not check if token is valid.
        if (localStorage && localStorage.auth_token && !appRenderState.isAuth) {
            axios({
                headers: {
                    'Authorization': localStorage.auth_token,
                    'X-CSRFToken': csrftoken
                },
                method: 'post',
                url: process.env.API_URL + "api/token/auth/",
                data: { "auth_token": localStorage.auth_token }
            })
                .then(function (response) {
                    if (response && response.data && response.data.auth_token) {
                        setAppRenderState(oldstate => {
                            let stateResult = {
                                ...oldstate,
                                isAuth: true,
                                auth_token: response.data.auth_token,
                                isLoaded: true,
                                isOwner: response.data.isOwner,
                                userType: response.data.user_type,
                        userData: response.data.user_data
                            };
                            return stateResult
                        });


                    }
                })
                .catch(function (error) {
                    localStorage.clear();
                    window.location.replace('/app/login/');
                });
        } else if (localStorage && localStorage.auth_token) {
            setAppRenderState(oldstate => {
                let stateResult = {
                    ...oldstate,
                    isAuth: true,
                    auth_token: localStorage.auth_token,
                    isLoaded: true
                };
                return stateResult
            });
        } else {
            setAppRenderState(oldstate => {
                let stateResult = {
                    ...oldstate,
                    isLoaded: true
                };
                return stateResult
            });
        }
    }, [appRenderState.isAuth]);

    return (
        <>
            {appRenderState && appRenderState.isLoaded ? <AppManager appRenderState={appRenderState} setAppRenderState={setAppRenderState}></AppManager> : <LazyLoadScreen></LazyLoadScreen>}
        </>
    )
}
export default AppRender;