import React from "react";
import Icon from "../general/Icon";
const ErrorModal = ({ setModalState, jobData }) => {
    return (
        <>
            <div className='modal-background'>
                <div className='card generalcard' style={{ width: "600px", borderRadius: "20px" }}>
                    <div className="container-right-icon">
                        <div onClick={() => { setModalState({ modalIsOpen: false, type: null, id: null }) }} className="right-icon">
                            <Icon
                                type={"fa"}
                                icon={"xmark"}
                                icon_size={2}
                                icon_color_code={"#6d6f71"}
                            ></Icon>
                        </div>
                    </div>
                    <div className="modal-title" style={{ paddingBottom: "5px", fontSize: "18px" }}>
                        Something went wrong
                    </div>
                    <div className="modal-description center-div">
                        Looks like something went wrong try again on a later moment.
                    </div>
                </div>
            </div>
        </>
    )
}
export default ErrorModal;