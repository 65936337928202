import React, { useState } from "react";
import ThreeStepModalSwitch from "./ThreeStepModalSwitch";
import Select from "./Select";
import Icon from "../general/Icon";
import TextArea from "./TextArea";
import Input from "./Input"
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import getCookie from '../auth/Cookie';
import Spinner from "../../files/spinningugcbird.gif";
var csrftoken = getCookie('csrftoken');
const UGCRejectModal = ({ refreshData }) => {
    let currentPage
    const context = useOutletContext();
    const [stepState, setStepState] = useState({
        step: "start"
    })
    const [state, setState] = useState({
    })

    const [prepareState, setPrepareState] = useState({
        step: "loading"

    })
    const FieldListener = (type, event) => {
        event.persist();
        setState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });

    }
    const updateUGCStatus = () => {
        if (context.modalState && context.modalState.obj_id && context.modalState.ugc_reward_reject) {
            axios({
                headers: {
                    'Authorization': localStorage.auth_token,
                    'X-CSRFToken': csrftoken
                },
                method: "patch",
                url: process.env.API_URL + "api/ugc/",
                data: {
                    id: context.modalState.obj_id,
                    status: "rejected",
                    // thankyou_title:state.thankyou_title,
                    // thankyou_message: state.thankyou_message,
                    // reject_title:state.reject_title,
                    // reject_message:state.reject_message,
                    // reward: state.reward
                }
            })
                .then(() => {
                    setPrepareState(oldstate => {
                        let results = {
                            ...oldstate,
                            step: "ok"
                        };
                        return results
                    })
                    refreshData()
                })
                .catch((error) => {
                    setPrepareState(oldstate => {
                        let results = {
                            ...oldstate,
                            step: "error"
                        };
                        return results
                    })
                })
        }

    }
    let currentPrepare = null
    switch (prepareState.step) {
        case "loading":
            currentPrepare = <>
                <div className="modal-title" style={{ paddingBottom: "5px", marginTop: "20px" }}>
                    Sending the email
                </div>
                <div className="modal-body">
                    <img src={Spinner}></img>
                </div>
            </>
            break;
        case "ok":
            currentPrepare = <>
                <div className="modal-title" style={{ paddingBottom: "5px", marginTop: "20px" }}>
                    Success
                </div>
            </>
            break;

        case "error":
            currentPrepare = <>
                <div className="modal-title" style={{ paddingBottom: "5px", marginTop: "20px" }}>
                    Something went wrong
                </div>
            </>
            break;
    }

    let page = null
    switch (stepState.step) {
        case "start":
            page = <>
                <div className="modal-title" style={{ paddingBottom: "5px", marginTop: "20px" }}>
                    Send email about content not being selected
                </div>
                <div className="modal-description">
                    {/* Note if you want to use the default email message you can skip this step. */}
                </div>
                <div className="modal-body">
                    <div className="ugc-button-container">
                        <div onClick={() => context.setModalState(oldstate => {
                            let results = {
                                ...oldstate,
                                ugc_reward_reject: null,
                                modalIsOpen: false,
                                type: null

                            };
                            return results
                        })} className="ugc-reject-button">Back</div>
                        <div onClick={() => (setStepState({ step: "end" }), setPrepareState({ step: "loading" }), updateUGCStatus())} className="ugc-reward-button">Continue</div>
                    </div>
                </div>
            </>
            break;

        case "end":
            page = <>
                {currentPrepare}
            </>

    }

    return (
        <>
            <div className='modal-background'>
                <div className='card generalcard-big'>
                    <div className="container-right-icon">
                        <div onClick={() => context.setModalState(oldstate => {
                            let results = {
                                ...oldstate,
                                ugc_reward_reject: null,
                                modalIsOpen: false,
                                type: null

                            };
                            return results
                        })} className="right-icon">
                            <Icon
                                type={"fa"}
                                icon={"xmark"}
                                icon_size={2}

                                icon_color_code={"#6d6f71"}
                            ></Icon>
                        </div>
                    </div>
                    <>{page}</>
                </div>

            </div>
        </>
    )
}
export default UGCRejectModal;