import React from "react"
import Icon from "../general/Icon";
import Input from "../web/Input"
import FileUpload from "../web/FileUpload";
import { useOutletContext } from 'react-router-dom';
import SettingsBrand from "../settings/SettingsBrand"
import SettingsIntegration from "../settings/SettingsIntegration";
const SettingsSwitch = ({ activeMenu, settingsState, setSettingsState, setBrandState, fileUploadListener, clearContent, brandImageState, saveSettings }) => {
    const context = useOutletContext();
    let currentPage;

    const FieldListener = (type, event) => {
        event.persist();
        setSettingsState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }
    switch (activeMenu) {
        case "brands":
            currentPage = <div className="row">

                <div className="card card-dashboard">
                    <SettingsBrand settingsState={settingsState} setSettingsState={setSettingsState} setBrandState={setBrandState}></SettingsBrand>
                </div>
            </div>
            break;

        case "integrations":
            currentPage = <SettingsIntegration settingsState={settingsState} setSettingsState={setSettingsState}></SettingsIntegration>
            break;
        case "billing":
            currentPage = <div className="row">

                <div className="card card-dashboard">
                    <h3>Current Plan</h3>
                    <div>Your current Plan is {settingsState.plans.current_plan.name}</div>
                    <div>A simple start for everyone</div>

                    <div>Active until 10-10-2024</div>
                    <div>We will send you a notifcation upon Subscription expiration</div>

                    <div className="button-container">
                        <div onClick={() => {
                            // context.setModalState({
                            //     modalIsOpen: true,
                            //     type: "upgrade_plan"
                            // })
                        }} className="button-grey">Upgrade Plan</div>
                        <div onClick={() => {
                            // context.setModalState({
                            //     modalIsOpen: true,
                            //     type: "upgrade_plan"
                            // })
                        }} className="button-grey">Cancel Subscription</div>
                    </div>
                </div>
                {/* <div className="card card-dashboard">
                    <div>
                        <h3>Payment Method</h3>
                    </div>
                    <div className="payment-card-container">
                        <div className="">
                            <Icon
                                type={"fa-brands"}
                                icon={"ideal"}
                                icon_size={2}
                                
                                icon_color_code={"#e3def9"}
                            ></Icon>
                            <div>Roberto crimzon</div>
                            <div>∗∗∗∗ ∗∗∗∗ ∗∗∗∗ 9856</div>
                        </div>
                        <div>
                            <div className="button-container button-end">
                                <div className="button-regular">Edit</div>
                                <div className="button-cancel">Delete</div>
                            </div>
                            <div className="card-expired">Card expires at 10/24</div>
                        </div>
                    </div>
                </div> */}
                <div className="card card-dashboard">
                    <div className="flex-between">
                        <h3>Billing address</h3>
                        <div onClick={() => {
                            context.setModalState({
                                modalIsOpen: true,
                                type: "change_billing"
                            })
                        }} className="button-regular">Change billing</div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="row">
                                <div className="col-4">Name:</div>
                                <div className="col-6">{settingsState && settingsState ? settingsState.name : ""}</div>
                                <div className="col-4">VAT Number:</div>
                                <div className="col-6">{settingsState && settingsState ? settingsState.vat_number : ""}</div>
                                <div className="col-4">Billing Address:</div>
                                <div className="col-6">{settingsState && settingsState ? settingsState.address : ""}</div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="row">
                                <div className="col-4">Country:</div>
                                <div className="col-6">{settingsState && settingsState ? settingsState.country : ""}</div>
                                <div className="col-4">City:</div>
                                <div className="col-6">{settingsState && settingsState ? settingsState.city : ""}</div>
                                <div className="col-4">Zipcode:</div>
                                <div className="col-6">{settingsState && settingsState ? settingsState.zipcode : ""}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            break;

        default:
            currentPage = <div>test</div>
            break;
    }
    return currentPage
}
export default SettingsSwitch;