import React, { useState, useEffect } from 'react';
import Icon from "../general/Icon";
import SettingsSwitch from './SettingsSwitch';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import getCookie from '../auth/Cookie';
var csrftoken = getCookie('csrftoken');
import EditDetailsModal from '../web/EditDetailsModal';
import UpgradePlanModal from '../web/UpgradePlanModal';
import ChangeBillingModal from '../web/ChangeBillingModal';
import ChangeProfileImageModal from "../web/ChangeProfileImageModal";
import BrandModal from '../web/BrandModal';
const Settings = () => {
    const context = useOutletContext();
    const [settingState, setSettingState] = useState({
        activeMenu: "billing"
    })
    const [settingsState, setSettingsState] = useState({

    })

    const [imageState, setImageState] = useState({

    })
    const [brandState, setBrandState] = useState({
        logo: null,
        name: null,
        id: null,
        tiktok: null,
    })
    const [brandImageState, setBrandImageState] = useState({

    })

    const [tabState, setTabState] = useState({
        tab: "billing"
    })


    const onClickMenu = (action) => {
        setSettingState({ "activeMenu": action })
    }

    const saveSettings = () => {
        let type = "create"
        if (settingsState && settingsState.logo) {
            type = "update"
        }
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken,
                "Content-Type": "multipart/form-data"
            },
            method: "post",
            url: `${process.env.API_URL}api/settings/`,
            data: {
                brand_name: settingsState.brand_name,
                logo: brandImageState.brand_logo,
                type: type

            }
        })
        // .then(function (response) {
        // axios({
        //     headers: {
        //         'Authorization': localStorage.auth_token,
        //         'X-CSRFToken': csrftoken
        //     },
        //     method: "get",
        //     url: `${process.env.API_URL}api/settings/`,
        // })
        //     .then(function (response) {
        //         setSettingsState(response.data)
        //     })
        // })
    }

    const fileUploadListener = (event: any) => {
        const file_obj = event.target.files[0]

        const image_obj = {
            obj: "",
            url: window.URL.createObjectURL(file_obj),
            new: true

        }
        const new_file = Object.assign(file_obj, image_obj)
        if (event.target.name === "brand_logo") {
            setBrandImageState(oldstate => ({ ...oldstate, [event.target.name]: new_file }))

        } else {
            setImageState(oldstate => ({ ...oldstate, [event.target.name]: new_file }))
        }
    };
    const clearContent = (name) => {
        if (name === "brand_logo") {
            setBrandImageState(oldstate => {
                let state_result = {


                };
                return state_result
            });
        } else {
            setImageState(oldstate => {
                let state_result = {


                };
                return state_result
            });
        }
    }

    useEffect(() => {
        if (context && context.appState && context.appState.settingTab === "integration") {
            setSettingState(oldstate => ({ ...oldstate, activeMenu: "integrations" }))
        }
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: "get",
            url: `${process.env.API_URL}api/settings/`,
        })
            .then(function (response) {
                setSettingsState(response.data)
                if (response.data.profile_image) {
                    const image_obj = {
                        obj: "",
                        url: response.data.profile_image,
                        new: true

                    }
                    setImageState({ "profile_image": image_obj })
                }

                if (response.data.logo) {
                    const image_brand_obj = {
                        obj: "",
                        url: response.data.logo,
                        new: true

                    }
                    setBrandImageState({ "brand_logo": image_brand_obj })
                }

            })
    }, [context.modalState.modalIsOpen])
    const modalType = () => {
        if (context.modalState.type === "upgrade_plan" && context.modalState.modalIsOpen) {
            return <UpgradePlanModal settingsState={settingsState}></UpgradePlanModal>
        } else if (context.modalState.type === "edit_detail" && context.modalState.modalIsOpen) {
            return <EditDetailsModal settingsState={settingsState}></EditDetailsModal>
        } else if (context.modalState.type === "change_billing" && context.modalState.modalIsOpen) {
            return <ChangeBillingModal settingsState={settingsState}></ChangeBillingModal>
        } else if (context.modalState.type === "change_profile_image" && context.modalState.modalIsOpen) {
            return <ChangeProfileImageModal imageState={imageState} settingsState={settingsState} fileUploadListener={fileUploadListener} clearContent={clearContent} ></ChangeProfileImageModal>
        } else if (context.modalState.type && context.modalState.type.includes("brand") && context.modalState.modalIsOpen) {
            return <BrandModal modalState={context.modalState} setModalState={context.setModalState} brandState={brandState} setBrandState={setBrandState} setSettingsState={setSettingsState}></BrandModal>
        }
        else {
            return null
        }
    }


    return (
        <>
            {context.modalState && context.modalState.modalIsOpen ?
                modalType()
                : null
            }
            {settingsState && settingsState.email ?
                <div className="">
                    {/* <div className="row">
                    <Link to={`/app/influencer/`} style={{ marginBottom: "20px" }}>
                        <span className="">
                            <Icon
                                type={"fa"}
                                icon={"chevron-left"}
                                icon_size={1}
                                
                                icon_color_code={"#e3def9"}
                            ></Icon></span>
                        Back
                    </Link>
                </div> */}
                    <div className="row">
                        <div className="col-xl-3 col-l-3 col-xl-3">
                            <div className="card card-dashboard">
                                {/* {imageState && imageState.profile_image ?
                                    <div className='card-user-image' ><img onClick={() => context.setModalState({
                                        modalIsOpen: true,
                                        type: "change_profile_image"
                                    })} className='circle-image rounded-circle-dashboard' src={imageState.profile_image.url}></img></div>

                                    : <div className='card-user-image' ><img onClick={() => context.setModalState({
                                        modalIsOpen: true,
                                        type: "change_profile_image"
                                    })} className='circle-image rounded-circle-dashboard' src={"https://picsum.photos/id/237/200/300"}></img></div>} */}
                                {/* <div className="card-user-name">{settingsState.name}</div> */}
                                {/* <div className="card-user-type"><p>Influencer</p></div> */}
                                {/* <div className="card-user-tasks">

                                    <p>Email: {settingsState.email}</p>
                                </div> */}
                                <div className="card-user-details">
                                    <div>
                                        {/* <p>details</p> */}
                                        {settingsState.social_username ?
                                            <p>Username: {settingsState.social_username}</p>
                                            : null}
                                        <p>Email: {settingsState.email}</p>
                                        {/* <p>Languages: Netherlands, English</p> */}
                                        <p>Country: {settingsState.country}</p>
                                        <p>City: {settingsState.city}</p>
                                    </div>
                                </div>
                                {/* <div onClick={() => {
                                    context.setModalState({
                                        modalIsOpen: true,
                                        type: "edit_detail"
                                    })
                                }} className='button-regular' style={{ margin: "auto" }}>
                                    Edit details
                                </div> */}
                            </div>
                            <div className="card card-dashboard">
                                {settingsState && settingsState.plans && settingsState.plans.current_plan ?
                                    <div className='plan-label'>{settingsState.plans.current_plan.name}</div>
                                    : null}
                                <ul>
                                    {settingsState && settingsState.plans && settingsState.plans.current_plan ?
                                        <>

                                            <li>Create up to {[settingsState.plans.current_plan.features.brand]} brands</li>
                                            <li>Create up to {[settingsState.plans.current_plan.features.campaign]} campaigns</li>
                                            <li>Collect up to {[settingsState.plans.current_plan.features.collect]} videos</li>
                                            <li>Create up to {[settingsState.plans.current_plan.features.job]} Jobs</li>
                                        </>
                                        : <p>no!</p>}
                                </ul>
                                <div onClick={() => {
                                    // context.setModalState({
                                    //     modalIsOpen: true,
                                    //     type: "upgrade_plan"
                                    // })
                                }} className='button-grey' style={{ margin: "auto" }}>Upgrade plan</div>
                            </div>
                        </div>
                        <div className="col-mobile col col-xl-9 col-lg-9 col-xl-9" style={{ width: "67%" }}>

                            <div className="row card" style={{ marginBottom: "20px", paddingBottom: "10px", paddingTop: "10px" }}>
                                <div className='' style={{ display: "flex" }}>
                                    <div className={`user-account-nav ${settingState && settingState.activeMenu === "billing" ? "user-account-nav--active" : ""}`} onClick={() => onClickMenu("billing")}>
                                        <p><span className="user-account-nav-icon-container">
                                            <Icon
                                                type={"fa"}
                                                icon={"user"}
                                                icon_size={1}
                                                icon_color_code={"#E3DEF9"}
                                            ></Icon></span>Billing</p>
                                    </div>
                                    <div className={`user-account-nav ${settingState && settingState.activeMenu === "brands" ? "user-account-nav--active" : ""}`} onClick={() => onClickMenu("brands")}>
                                        <p><span className="user-account-nav-icon-container">
                                            <Icon
                                                type={"fa"}
                                                icon={"circle-check"}
                                                icon_size={1}
                                                icon_color_code={"#E3DEF9"}
                                            ></Icon></span>Brands</p>
                                    </div>
                                    <div className={`user-account-nav ${settingState && settingState.activeMenu === "integrations" ? "user-account-nav--active" : ""}`} onClick={() => onClickMenu("integrations")}>
                                        <p><span className="user-account-nav-icon-container">
                                            <Icon
                                                type={"fa"}
                                                icon={"circle-check"}
                                                icon_size={1}
                                                icon_color_code={"#E3DEF9"}
                                            ></Icon></span>Integrations</p>
                                    </div>
                                </div>
                            </div>
                            <SettingsSwitch

                                activeMenu={settingState.activeMenu}
                                settingsState={settingsState}
                                setSettingsState={setSettingsState}
                                fileUploadListener={fileUploadListener}
                                clearContent={clearContent}
                                brandImageState={brandImageState}
                                setBrandState={setBrandState}
                                saveSettings={saveSettings}
                            ></SettingsSwitch>

                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}
export default Settings;