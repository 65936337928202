import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../web/ErrorMessage";
import { Link } from "react-router-dom";
import getCookie from '../auth/Cookie';
import Input from "../web/Input";
import DatePicker from "../web/DatePicker";
import Select from "../web/Select";
var csrftoken = getCookie('csrftoken');
type IErrors = {
    errors?: Array<string>;
}
const CreateAccountCreator = ({ appState, setAppAuth }) => {
    const [registerState, setRegisterState] = useState({
        username: "",
        email: "",
        password: "",
        password_confirm: "",
        country: "AF",
        can_use_promotion: false
    })

    const [errorState, setErrorState] = useState<IErrors>({
        errors: []
    });
    const [stepState, setStepState] = useState({
        step: 1
    })
    const navigate = useNavigate()
    const FieldListener = (type, event) => {
        event.persist();
        setRegisterState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });

    }
    const booleanListener = () => {
        setRegisterState(oldstate => ({ ...oldstate, can_use_promotion: !oldstate.can_use_promotion }))

    };
    const fileUploadListener = (event: any) => {
        const file_obj = event.target.files[0]

        const image_obj = {
            obj: "",
            url: window.URL.createObjectURL(file_obj),
            new: true

        }
        const new_file = Object.assign(file_obj, image_obj)

        setRegisterState(oldstate => ({ ...oldstate, [event.target.name]: new_file }))

    };
    const submit = () => {
        axios({
            headers: {
                'X-CSRFToken': csrftoken
            },
            method: 'post',
            url: process.env.API_URL + "api/register/",
            data: registerState
        })
            .then(function (response) { 
                navigate("/app/register/pre-confirm/")
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    setErrorState({
                        "errors": [error.response.data.message]
                    })
                }
                else {
                    setErrorState({
                        "errors": ["Something went wrong"]
                    })
                }
            });
    }
    useEffect(() => {
        if (appState && appState.isAuth) {
            navigate("/app/")
        }

    }, [appState]);

    const displayErrors = () => {
        if (errorState.errors.length > 0) {
            return <ErrorMessage errors={errorState.errors} setErrorState={setErrorState}></ErrorMessage>;
        } else {
            return null;
        }
    };
    const gender_options = [
        { label: "Select your gender", value: "-" },
        { label: "Men", value: "men" },
        { label: "Female", value: "female" },
        { label: "Non-binary", value: "non_binary" },
    ]

    useEffect(() => {
        axios({
            headers: {
                'X-CSRFToken': csrftoken
            },
            method: 'get',
            url: process.env.API_URL + "api/countries/",
        })
            .then(function (response) {
                if (response && response.data && response.data.countries) {
                    setRegisterState(oldstate => ({ ...oldstate, countries: response.data.countries }))
                }

            })
    }, []);

    let view
    switch (stepState.step) {
        case 1:
            view = <>     <div className="Auth-form-content">
                <h3 className="Auth-form-title login-title">Create your account</h3>
                <h6 className="login-h6 login-title">Unlock all your features</h6>
                <div className="row">
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("username", e)}
                            type="text"
                            label="Username"
                            name="username"
                            value={registerState.username || ''}
                        />

                    </div>
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("email", e)}
                            type="email"
                            label="email"
                            name="email"
                            value={registerState.email || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("password", e)}
                            type="password"
                            label="Password"
                            name="password"
                            value={registerState.password || ''}
                        />

                    </div>
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("password_confirm", e)}
                            type="password"
                            label="Password confirm"
                            name="password_confirm"
                            value={registerState.password_confirm || ''}
                        />
                    </div>
                </div>
                <div className="button-container-center button-auth-container">
                    <div onClick={() => { window.location.href = "/app/login/" }} className="button-grey button-auth">Cancel</div>
                    {registerState && registerState.username && registerState.email.includes("@") && registerState.password && registerState.password_confirm && registerState.password === registerState.password_confirm ?
                        <div onClick={() => (setStepState({ "step": stepState.step + 1 }))} className="button-regular button-auth">Continue</div>
                        : <div onClick={() => ("")} className="button-grey button-auth">Continue</div>}

                </div>
                <Link to="/app/login/">
                    <div className="login-create-account">You have account? Login now</div>
                </Link>
            </div></>
            break;
        case 2:
            view = <>     <div className="Auth-form-content">
                <h3 className="Auth-form-title login-title">Enter your personal information</h3>
                <h6 className="login-h6 login-title">Enter your personal info for billing</h6>
                <div className="row">
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("first_name", e)}
                            type="text"
                            label="First name"
                            name="first_name"
                            value={registerState.first_name || ''}
                        />

                    </div>
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("last_name", e)}
                            type="text"
                            label="Last name"
                            name="last_name"
                            value={registerState.last_name || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <DatePicker
                            onChange={(e) => FieldListener("date_of_birth", e)}
                            type="date"
                            label="Date of birth"
                            name="date_of_birth"
                            value={registerState.date_of_birth || ''}
                        />

                    </div>
                    <div className="col-6">
                        <Select
                            onChange={(e) => FieldListener("gender", e)}
                            name="gender"
                            label={"Gender"}
                            options={gender_options || []}
                            selected={registerState.gender || ""}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Select
                            onChange={(e) => FieldListener("country", e)}
                            name="country"
                            label={"Country"}
                            options={registerState.countries || []}
                            selected={registerState.country || ""}
                        />

                    </div>
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("state", e)}
                            type="text"
                            label="State"
                            name="state"
                            value={registerState.state || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("address_1", e)}
                            type="text"
                            label="Adress line 1"
                            name="address_1"
                            value={registerState.address_1 || ''}
                        />

                    </div>
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("address_2", e)}
                            type="text"
                            label="address line 2"
                            name="address_2"
                            value={registerState.address_2 || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("city", e)}
                            type="text"
                            label="City"
                            name="city"
                            value={registerState.city || ''}
                        />

                    </div>
                    <div className="col-6">
                        <Input
                            onChange={(e) => FieldListener("zipcode", e)}
                            type="text"
                            label="Zipcode"
                            name="zipcode"
                            value={registerState.zipcode || ''}
                        />
                    </div>
                </div>
                <div className="button-container-center button-auth-container">
                    <div onClick={() => (setStepState({ "step": stepState.step - 1 }))} className="button-grey button-auth">Cancel</div>
                    {registerState && registerState.first_name && registerState.last_name && registerState.date_of_birth && registerState.gender && registerState.country && registerState.state && registerState.address_1 && registerState.city && registerState.zipcode ?
                        <div onClick={() => (setStepState({ "step": stepState.step + 1 }))} className="button-regular button-auth">Continue</div>
                        : <div onClick={() => ("")} className="button-grey button-auth">Continue</div>}

                </div>
                <Link to="/app/login/">
                    <div className="login-create-account">You have account? Login now</div>
                </Link>
            </div></>
            break;
        case 3:
            view = <>     <div className="Auth-form-content">
                <h3 className="Auth-form-title login-title">Enter your Social information</h3>
                <h6 className="login-h6 login-title"></h6>
                <div className="row">
                    <div className="col-12">
                        <Input
                            onChange={(e) => FieldListener("social_username", e)}
                            type="text"
                            label="Tik Tok username"
                            name="social_username"
                            value={registerState.social_username || ''}
                        />

                    </div>

                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label className="form-label">{"Can use content for marketing purpose"}</label>
                            <input
                                onClick={() => (booleanListener())}
                                className="switch-checkbox"
                                id={1}
                                type="checkbox"
                                name={"can_use_promotion"}
                                defaultChecked={registerState.can_use_promotion}
                            />
                            <label
                                style={{ background: registerState.can_use_promotion ? "#06D6A0" : "" }}
                                className="switch-label"
                                htmlFor={1}
                            >
                                <span className={"switch-button"} />
                            </label>
                            <div></div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label className="form-label">{"Can recieve promotion emails"}</label>
                            <input
                                onClick={() => (booleanListener())}
                                className="switch-checkbox"
                                id={2}
                                type="checkbox"
                                name={"can_recieve_promotion_email"}
                                defaultChecked={registerState.can_recieve_promotion_email}
                            />
                            <label
                                style={{ background: registerState.can_recieve_promotion_email ? "#06D6A0" : "" }}
                                className="switch-label"
                                htmlFor={2}
                            >
                                <span className={"switch-button"} />
                            </label>
                            <div></div>
                        </div>
                    </div>
                </div>
                <div className="button-container-center button-auth-container">
                    <div onClick={() => (setStepState({ "step": stepState.step - 1 }))} className="button-grey button-auth">Cancel</div>
                    {registerState && registerState.social_username ?
                        <div onClick={() => (submit())} className="button-regular button-auth">Continue</div>
                        : <div onClick={() => ("")} className="button-grey button-auth">Continue</div>}

                </div>
                <Link to="/app/login/">
                    <div className="login-create-account">You have account? Login now</div>
                </Link>
            </div></>
            break;

        default:
            view = <></>
            break;
    }
    return (
        <div className="container login">
            <div className="row login--row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-12">
                    <div className="Auth-form-container">
                        <div className="Auth-form">
                            {view}
                        </div>
                        <br></br>
                        {displayErrors()}
                    </div>

                </div>
            </div>
        </div>
    )
}
export default CreateAccountCreator;