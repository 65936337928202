import React, { } from "react";

import TableHead from "../table/Tablehead"
import TableRowBrand from "../table/TableRowBrand"
import { useOutletContext } from 'react-router-dom';

const SettingsBrand = ({ settingsState, setSettingsState, setBrandState }) => {
    const context = useOutletContext();






    return (
        <>
            <div className="row">
                <div className="col-creator" style={{ paddingTop: "0px" }}>
                    <div className="card mb-4">
                        <div className="card-header" style={{ display: "flex", justifyContent: "space-between", paddingLeft: "0px" }}>
                            <h4 style={{
                                paddingRight: "10px",
                                marginBottom: "0px",
                                fontSize: "20px"
                            }}>Brands</h4>
                            <div onClick={() => { (context.setModalState({ modalIsOpen: true, type: 'brand_create' })) }} className="button-regular">Create Brand</div>
                        </div>
                        <div className="card-body" style={{ padding: '0px' }}>
                            <div className="row"
                                style={{ margin: '15px', marginBottom: '0px' }}>
                                <div className="col-sm-6"
                                    style={{ padding: '0px' }}
                                >
                                    <div className="row">
                                        <div className="select-container col-sm-2">
                                            {/* <Select
                                                onChange={showEntries}
                                                name="show"
                                                label={null}
                                                options={data || []}
                                                selected={showEntriesState.show}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6"
                                    style={{ padding: '0px' }}
                                >
                                    {/* <div className="row" style={{ justifyContent: 'flex-end' }}>
                                        <div className="search-container" style={{ display: "flex" }}>
                                            <Search
                                                placeholder={"Search"}
                                                aria_label={"search"}
                                                onChange={(e) => searchListener(e)}
                                                value={searchState.input || ""}
                                            >
                                            </Search>
                                            <div onClick={onSearch} className="button-regular">Search</div>
                                        </div>
                                    </div> */}
                                </div>

                            </div>
                            <div className="table-responsive">
                                <table className="table ">
                                    <thead>
                                        <tr>
                                            {
                                                [
                                                    { "name": "brand", "verbose_name": "Brand" },
                                                    { "name": "Campaigns", "verbose_name": "Campaigns" },
                                                    { "name": "content", "verbose_name": "Content" },
                                                    { "name": "action", "verbose_name": "Action" }
                                                ].map(
                                                    header => (
                                                        <TableHead
                                                            key={header.name}
                                                            head={header.name}
                                                            verbose_name={header.verbose_name || header.name}
                                                            onClick={"orderingListener"}
                                                            sort={'ascend'}
                                                        ></TableHead>
                                                    )
                                                )}
                                        </tr>
                                    </thead>
                                    <tbody style={{ verticalAlign: "middle" }}>
                                        {
                                            settingsState && settingsState.brands && settingsState.brands.length >= 1 ?

                                                settingsState.brands.map(
                                                    (result, i) => (
                                                        <TableRowBrand
                                                            key={result.id}
                                                            columns={result}
                                                            setModalState={context.setModalState}
                                                            setBrandState={setBrandState}
                                                        ></TableRowBrand>
                                                    )
                                                )


                                                : null
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div className="card-footer">
                            <div className="row">
                                <div
                                    className="col-sm-6"
                                    style={{
                                        margin: 'auto',
                                        width: '50%'
                                    }}
                                >
                                    {/* <span>Showing 1 to {showEntriesState.show} of {campaignState.count} entries</span> */}
                                </div>
                                <div className="col-sm-6"
                                    style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {/* <Pagination
                                        api_url={api_url}
                                        state={campaignState}
                                        setState={setcampaignState}

                                    ></Pagination> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default SettingsBrand;