import React, { useState, useEffect } from "react"
import Icon from "../general/Icon";
import axios from "axios";
import getCookie from '../auth/Cookie';
var csrftoken = getCookie('csrftoken');
const VideoCarousel = () => {
    const [carouselState, setCarouselState] = useState({
        content: [],
        max_width: 0,
        min_width: 0,
        transformx: 30,
        viewport: 0,
        defaultVideoWidth: 320,
        maxMoveToRight: 0,
        maxMoveToLeft: 0,
        hitRightLimit: false,
        viewVersion: 0
    })


    const moveCarousel = (whichWay) => {
        if (whichWay === "right") {
            if (carouselState.viewVersion + carouselState.transformx > carouselState.maxMoveToRight) {
                setCarouselState(oldstate => {
                    let stateData = {
                        ...oldstate,
                        transformx: carouselState.transformx + -320
                    }
                    return stateData
                })
                document.getElementsByClassName("inner-carousel")[0].style.transform = `translateX(${carouselState.transformx + -320}px)`
                if (document.getElementsByClassName("fa-circle-arrow-left")[0].style.color === 'rgb(245, 245, 245)') {
                    document.getElementsByClassName("fa-circle-arrow-left")[0].style.color = "#E3DEF9"
                }
            }
            else {
                document.getElementsByClassName("fa-circle-arrow-right")[0].style.color = "#f5f5f5"
            }
        }
        if (whichWay === "left") {
            if (carouselState.transformx < carouselState.maxMoveToLeft) {
                setCarouselState(oldstate => {
                    let stateData = {
                        ...oldstate,
                        transformx: carouselState.transformx - -320
                    }
                    return stateData
                })
                document.getElementsByClassName("inner-carousel")[0].style.transform = `translateX(${carouselState.transformx - -320}px)`
                if (document.getElementsByClassName("fa-circle-arrow-right")[0].style.color === 'rgb(245, 245, 245)') {
                    document.getElementsByClassName("fa-circle-arrow-right")[0].style.color = "#E3DEF9"
                }
            }
            else {
                document.getElementsByClassName("fa-circle-arrow-left")[0].style.color = "#f5f5f5"
            }
        }

    }

    useEffect(() => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: "get",
            url: `${process.env.API_URL}api/v2/media/`,
        })
            .then(function (response) {
                setCarouselState(oldstate => {
                    let stateData = {
                        ...oldstate,
                        content: response.data.items
                    }
                    return stateData
                })
                if (response.data && response.data.items) {
                    if (response.data.items.length > 1) {
                        let max_width = response.data.items.length * carouselState.defaultVideoWidth
                        let viewport = 0
                        if (document.getElementsByClassName("header-gradient") && document.getElementsByClassName("header-gradient").length >= 1) {
                            viewport = document.getElementsByClassName("header-gradient")[0].offsetWidth
                        }
                        let viewVersion = 0
                        if (viewport < 400) {
                            viewVersion = 0
                        } else if (viewport > 401) {
                            viewVersion = -320
                        }
                        setCarouselState(oldstate => {
                            let stateData = {
                                ...oldstate,
                                max_width: max_width,
                                viewport: viewport,
                                maxMoveToRight: viewport - max_width,
                                viewVersion: viewVersion
                            }
                            return stateData
                        })
                    }
                }
            })


    }, [])

    useEffect(() => {
        window.addEventListener("resize", function (event) {
            let viewport = 0
            if (document.getElementsByClassName("header-gradient") && document.getElementsByClassName("header-gradient").length >= 1) {
                viewport = document.getElementsByClassName("header-gradient")[0].offsetWidth
            }
            let viewVersion = 0
            if (viewport < 400) {
                viewVersion = 0
            } else if (viewport > 401) {
                viewVersion = -320
            }
            setCarouselState(oldstate => {
                let stateData = {
                    ...oldstate,
                    viewport: viewport,
                    viewVersion: viewVersion
                }
                return stateData
            })
        });
    }, []);

    const playVideo = (event) => {
        if (event.target.classList.contains("playing")) {
            event.target.pause()
            event.target.classList.remove("playing")
            if (
                document.querySelector(`[id='${event.target.id}']`)
            ) {
                document.querySelector(`[id='${event.target.id}']`).querySelector("[class='playpause']").getElementsByTagName("i")[0].classList.add("fa-circle-play")
            }
        } else {
            event.target.play()
            event.target.classList.add("playing")
            if (document.querySelector(`[id='${event.target.id}']`)) {
                document.querySelector(`[id='${event.target.id}']`).querySelector("[class='playpause']").getElementsByTagName("i")[0].classList.remove("fa-circle-play")
                document.querySelector(`[id='${event.target.id}']`).querySelector("[class='playpause']").getElementsByTagName("i")[0].classList.remove("fa-circle-pause")
            }
        }
    }

    const playVideoIcon = (id) => {
        if (id && document.querySelector(`[id='${id}']`) && document.querySelector(`[id='${id}']`).getElementsByTagName("video") && document.querySelector(`[id='${id}']`).getElementsByTagName("video")[0]) {
            let video = document.querySelector(`[id='${id}']`).getElementsByTagName("video")[0]
            if (video.classList.contains("playing")) {
                video.pause()
                video.classList.remove("playing")
                if (
                    document.querySelector(`[id='${id}']`)
                ) {
                    document.querySelector(`[id='${id}']`).querySelector("[class='playpause']").getElementsByTagName("i")[0].classList.add("fa-circle-play")
                }
            } else {
                video.play()
                video.classList.add("playing")
                if (document.querySelector(`[id='${id}']`)) {
                    document.querySelector(`[id='${id}']`).querySelector("[class='playpause']").getElementsByTagName("i")[0].classList.remove("fa-circle-play")
                    document.querySelector(`[id='${id}']`).querySelector("[class='playpause']").getElementsByTagName("i")[0].classList.remove("fa-circle-pause")
                }
            }
        }
    }

    return (
        <>
            {carouselState && carouselState.content ?
                <div className="carousel">
                    <div className="inner-carousel" style={{ transform: `translateX(0px)` }}>
                        {carouselState.content.map((item, i) => (
                            <div key={i} id={i} className="video-carousel">
                                <video id={i} onClick={(event) => { playVideo(event) }}
                                    muted={false}
                                    playsInline
                                    autoPlay={false}
                                    className="video"
                                    preload={'auto'}
                                >
                                    <source src={`${process.env.API_URL}${item.meta.url}`} type="video/mp4" />
                                </video>
                                <div id={i} onClick={() => { playVideoIcon(i) }} className="playpause"> <Icon
                                    id={i}
                                    type={"fa"}
                                    icon={"circle-play"}
                                    icon_size={5}
                                    icon_color_code={"#fff"}
                                ></Icon></div>
                            </div>
                        )
                        )}
                    </div>
                    <div className="button-container-carousel">
                        <div onClick={() => { moveCarousel("left") }} className="button-carousel button-carousel-left">
                            <Icon
                                type={"fa"}
                                icon={"circle-arrow-left"}
                                icon_size={2}
                                icon_color_code={"#f5f5f5"}
                            ></Icon>
                        </div>
                        <div onClick={() => { moveCarousel("right") }} className="button-carousel button-carousel-right">
                            <Icon
                                type={"fa"}
                                icon={"circle-arrow-right"}
                                icon_size={2}
                                icon_color_code={"#E3DEF9"}
                            ></Icon>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}
export default VideoCarousel;