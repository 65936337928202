import React, { FunctionComponent } from 'react';

type ITableHead = {
    head: string;
    onClick?: any;
    sort?: string;
    verbose_name?: string;

}

const TableHead: FunctionComponent<ITableHead> = ({ head, onClick, verbose_name }) => {
    return (
        <th key={head} scope="col" onClick={e => onClick(e, head)}>
            <div>
                {verbose_name}
            </div>
        </th>
    )
}

export default TableHead;
