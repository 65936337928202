import React from "react";
import Icon from "../general/Icon";
const RelationItem = ({ data, setModalState }) => {
    const acceptOffer = () => {
        if (data.creator.accept_gifted) {
            return "active"
        }
        if (data.creator.accept_barter) {
            return "active"

        }
    }
    return (
        <>
            <div className={`job_kanban__list__item job_kanban__list__item--${data.relation}`}>
                <div className="job_kanban__list__item--header">
                    {/* <div className="job_kanban__list__item--header--image">
                        <div className="">
                            <img className="round-circle-image" src="https://picsum.photos/200/300"></img>
                        </div>
                    </div> */}
                <div className="job_kanban__list__item--header--info">
                        <div style={{ fontWeight: 800 }} className="">{data.creator.name}</div>
                        <div className="">{data.creator.email}</div>
                    </div>
                    <div className="job_kanban__list__item--header--menu" onClick={() => {
                        setModalState({
                            modalIsOpen: true,
                            message: null,
                            type: "move_to",
                            id: data.id
                        })
                    }}>
                        <Icon
                            type={"fa"}
                            icon={"ellipsis-vertical"}
                            icon_size={1}
                            icon_color_code={"#e3def9"}
                        ></Icon>
                    </div>
                </div>

                <div className="job_kanban__list__item--body">
                    <div className="job_kanban__list__item--socials">
                        <div className="job_kanban__list__item--social">
                            {data && data.creator.tiktok ?
                                <a  target="_blank" href={`https://www.tiktok.com/@${data.creator.tiktok}/`}>
                                    <Icon
                                        type={"fa-brands"}
                                        icon={"tiktok"}
                                        icon_size={1}
                                        icon_color_code={"#B390FF"}
                                    ></Icon></a>
                                : null}

                            {data && data.creator.facebook ?
                                <a  target="_blank" href={`https://www.facebook.com/${data.creator.facebook}`}>
                                    <Icon
                                        type={"fa-brands"}
                                        icon={"facebook"}
                                        icon_size={1}
                                        icon_color_code={"#B390FF"}
                                    ></Icon></a>
                                : null}

                            {data && data.creator.instagram ?
                                <a  target="_blank" href={`https://www.instagram.com/${data.creator.instagram}/`}>
                                    <Icon
                                        type={"fa-brands"}
                                        icon={"instagram"}
                                        icon_size={1}
                                        icon_color_code={"#B390FF"}
                                    ></Icon></a>
                                : null}

                            {data && data.creator.website ?
                                < a  target="_blank" href={`${data.creator.website}`}>
                                    <Icon
                                        type={"fa"}
                                        icon={"home"}
                                        icon_size={1}
                                        icon_color_code={"#B390FF"}
                                    ></Icon></a>
                                : null}

                        </div>
                        <div className="job_kanban__list__item--accept">
                            <div className={`accept-icon  accept-icon--${acceptOffer()}`}>
                                <Icon
                                    hover_text={"Creator accepts gifted deals"}
                                    type={"fa"}
                                    icon={"gift"}
                                    icon_size={1}
                                    icon_color_code={"#a3ff92"}
                                ></Icon>
                            </div>
                            <div className={`accept-icon  accept-icon--${acceptOffer()}`}>
                                <Icon
                                    hover_text={"Creator accepts barter deals"}
                                    type={"fa"}
                                    icon={"handshake"}
                                    icon_size={1}
                                    icon_color_code={"#92c3ff"}
                                ></Icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default RelationItem;