import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import getCookie from '../auth/Cookie';
var csrftoken = getCookie('csrftoken');
import JobCard from "./JobCard"
import Select from "../web/Select";
import RequestPartnerschipBusinessModal from "../web/RequestPartnerschipBusinessModal";
import { useOutletContext, useNavigate } from 'react-router-dom';
import HitLimitModal from "../web/HitLimitModal";
import MoveToModal from "../web/MoveToModal";
import CreateRelation from "../web/CreateRelation";
import Icon from "../general/Icon";
import Pagination from "../pagination/Pagination";
import RelationItem from "./RelationItem";
const Relations = () => {
    const mountedRef = useRef()
    let context = useOutletContext()
    let api_url = `${process.env.API_URL}api/relation/`
    const [state, setState] = useState({
        id: 0,
        relation: "",
        display: false

    })
    const [modalState, setModalState] = useState({
        modalIsOpen: false,
        id: null,
        type: "",
        message: null
    })

    const [searchState, setSearchState] = useState({
        relation_query: "",
        gender: "",
        age: "",
        language: "",
        country: "",
        query_url: "",
        relation: ""
    })

    const data = [
        { label: "1", value: "1" },
        { label: "5", value: "5" },
        { label: "10", value: "10" },
        { label: "50", value: "50" },
    ]

    const gender_options = [
        { label: "Select your gender", value: null },
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
        { label: "Non-binary", value: "non_binary" },
    ]
    const didMount = useRef(false);

    // const any = (str, lst) =>{
    //     let result = false
    //     for(const item in lst){
    //         if(str === item){
    //             return true
    //         }
    //     }


    // }
    useEffect(() => {
        if (didMount.current) {
            let query_url = "http://127.0.0.1:8000/api/relation/?"
            /// First clean the query string before proceeding!
            if (searchState.relation) {
                if (query_url.endsWith("?")) {
                    /// It means this is the first query that we will look up
                    query_url = query_url + `relation_type=${searchState.relation}`
                } else {
                    query_url = query_url + `&relation_type=${searchState.relation}`
                }
            }
            if (searchState.age) {
                if (query_url.endsWith("?")) {
                    /// It means this is the first query that we will look up
                    query_url = query_url + `age=${searchState.age}`
                } else {
                    query_url = query_url + `&age=${searchState.age}`
                }
            }
            if (searchState.gender) {
                if (query_url.endsWith("?")) {
                    /// It means this is the first query that we will look up
                    query_url = query_url + `gender=${searchState.gender}`
                } else {
                    query_url = query_url + `&gender=${searchState.gender}`
                }
            }

            if (searchState.age) {
                if (query_url.endsWith("?")) {
                    /// It means this is the first query that we will look up
                    query_url = query_url + `age=${searchState.age}`
                } else {
                    query_url = query_url + `&age=${searchState.age}`
                }
            }

            if (searchState.language) {
                if (query_url.endsWith("?")) {
                    /// It means this is the first query that we will look up
                    query_url = query_url + `language=${searchState.language}`
                } else {
                    query_url = query_url + `&language=${searchState.language}`
                }
            }
            if (searchState.country) {
                if (query_url.endsWith("?")) {
                    /// It means this is the first query that we will look up
                    query_url = query_url + `country=${searchState.country}`
                } else {
                    query_url = query_url + `&country=${searchState.country}`
                }
            }

            if (searchState.relation === "All") {
                query_url = "http://127.0.0.1:8000/api/relation/?"
            }
            axios({
                headers: {
                    'Authorization': localStorage.auth_token,
                    'X-CSRFToken': csrftoken
                },
                method: 'get',
                url: query_url,
            })
                .then(function (response) {
                    setState(oldstate => {
                        let response_data = response.data
                        let paginated_results = {
                            ...oldstate,
                            next: response_data.next,
                            previous: response_data.previous,
                            count: response_data.count,
                            page_active: response_data.page_active,
                            num_pages: response_data.num_pages,
                            data: response_data.data,
                            countries: response_data.countries,
                            languages: response_data.languages,
                            headers: [],
                            limit: 5,
                            query: query_url
                        };
                        return paginated_results
                    });
                    // setSearchState(oldstate => {
                    //     let paginated_results = {
                    //         ...oldstate,
                    //         query: query_url
                    //     };
                    //     return paginated_results
                    // });
                })
                .catch(function (error) {
                });
        } else {
            didMount.current = true;
        }
    }, [searchState])


    useEffect(() => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: 'get',
            url: process.env.API_URL + `api/relation/`,
        })
            .then(function (response) {
                setState(oldstate => {
                    let response_data = response.data
                    let paginated_results = {
                        ...oldstate,
                        next: response_data.next,
                        previous: response_data.previous,
                        count: response_data.count,
                        page_active: response_data.page_active,
                        num_pages: response_data.num_pages,
                        data: response_data.data,
                        countries: response_data.countries,
                        languages: response_data.languages,
                        age: response_data.age,
                        headers: [],
                        limit: 5,
                    };
                    return paginated_results
                });
            })
            .catch(function (error) {
            });
    }, [])


    const RelationApi = () => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: 'post',
            url: process.env.API_URL + "api/relation/",
            data: {
                method: "update",
                id: modalState.id,
                relation: state.relation


            }
        })
            .then(function (response) {
                setState(oldstate => ({ ...oldstate, relation: "", display: false, id: 0 }))
                setModalState({
                    modalIsOpen: false,
                    id: null,
                    type: "",
                    message: null
                })
                axios({
                    headers: {
                        'Authorization': localStorage.auth_token,
                        'X-CSRFToken': csrftoken
                    },
                    method: 'get',
                    url: process.env.API_URL + `api/relation/`,
                })
                    .then(function (response) {
                        setState(oldstate => {
                            let response_data = response.data
                            let paginated_results = {
                                ...oldstate,
                                next: response_data.next,
                                previous: response_data.previous,
                                count: response_data.count,
                                page_active: response_data.page_active,
                                num_pages: response_data.num_pages,
                                data: response_data.data,
                                countries: response_data.countries,
                                languages: response_data.languages,
                                age: response_data.age,
                                headers: [],
                                limit: 5,
                            };
                            return paginated_results
                        });
                    })
                    .catch(function (error) {
                    });

            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const FieldSelectListener = (type, event) => {
        event.persist();
        if (event.target.value === "null") {
            event.target.value = ""
        }
        setSearchState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }

    const relationHeader = () => {
        if (searchState.relation === "interested") {
            return "Interested"
        }
        if (searchState.relation === "in_conversation") {
            return "In conversation"
        }
        if (searchState.relation === "accepted") {
            return "Accepted"
        }
        if (searchState.relation === "standby") {
            return "Stand By"
        }
        else {
            return "All"
        }
    }
    return (
        <>
            {modalState && modalState.modalIsOpen && modalState.type === "hit_limit" ? <HitLimitModal setModalState={setModalState} message={modalState.message}></HitLimitModal> : null}
            {modalState && modalState.modalIsOpen && modalState.type === "move_to" ? <MoveToModal RelationApi={RelationApi} setModalState={setModalState} relationState={state} setRelationState={setState}></MoveToModal> : null}
            {modalState && modalState.modalIsOpen && modalState.type === "create_relation" ? <CreateRelation setModalState={setModalState} userData={context.appRenderState.userData} setRelationState={setState} searchState={state}></CreateRelation> : null}
            <div className="col-12">
                <div className="row">
                    {/* <div className="jobs__header--title">Jobs</div> */}

                </div>
                <div className="row job_kanban_main_menu-top">
                    <div className="job_kanban_main_menu">
                        <div className="horizontal__filter--select-options">
                            {/* <Select
                                onChange={(e) => FieldSelectListener("gender", e)}
                                name="gender"
                                label={"Gender"}
                                options={gender_options || []}
                                selected={searchState.gender || ""}
                                upperLabel={"Gender"}
                            /> */}
                            {/* <Select
                                onChange={(e) => FieldSelectListener("age", e)}
                                name="age"
                                label={"Age"}
                                options={state.age || []}
                                selected={searchState.age || ""}
                                upperLabel={"Age"}
                            />
                            <Select
                                onChange={(e) => FieldSelectListener("language", e)}
                                name="language"
                                label={"Language"}
                                options={state.languages || []}
                                selected={searchState.language || ""}
                                upperLabel={"Language"}
                            />
                            <Select
                                onChange={(e) => FieldSelectListener("country", e)}
                                name="country"
                                label={"Country"}
                                options={state.countries || []}
                                selected={searchState.country || ""}
                                upperLabel={"Country"}
                            /> */}
                            <div className="create-campaign" style={{ marginTop: "22px", marginLeft: "15px" }} onClick={() => setModalState(oldstate => ({ ...oldstate, type: "create_relation", modalIsOpen: true, id: null }))}>Create Relation</div>
                        </div>
                        <div className="horizontal__filter__balloon--options">
                            <div className="horizontal__filter__balloon--option horizontal__filter__balloon--option--all" onClick={() => setSearchState(oldstate => ({ ...oldstate, relation: "" }))}>
                                All
                            </div>
                            <div className="horizontal__filter__balloon--option horizontal__filter__balloon--option--interested" onClick={() => setSearchState(oldstate => ({ ...oldstate, relation: "interested" }))}>
                                Interested
                            </div>
                            <div className="horizontal__filter__balloon--option horizontal__filter__balloon--option--in_conversation" onClick={() => setSearchState(oldstate => ({ ...oldstate, relation: "in_conversation" }))}>
                                In conversation
                            </div>
                            <div className="horizontal__filter__balloon--option horizontal__filter__balloon--option--accepted" onClick={() => setSearchState(oldstate => ({ ...oldstate, relation: "accepted" }))}>
                                Accepted
                            </div>
                            <div className="horizontal__filter__balloon--option horizontal__filter__balloon--option--standby" onClick={() => setSearchState(oldstate => ({ ...oldstate, relation: "standby" }))}>
                                Stand By
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 job_kanban_col">
                        <div className="job_kanban__header">
                            <p>{relationHeader()}</p>
                        </div>
                        <div className="job_kanban__list">
                            <div className="job_kanban__list__body">

                                {state && state.data ?
                                    state.data.map((item, i) => (
                                        <RelationItem data={item} setModalState={setModalState}></RelationItem>
                                    ))
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-sm-12"
                        style={{ display: 'flex', justifyContent: 'center' }}>
                        <Pagination
                            api_url={"api_url"}
                            state={state}
                            setState={setState}

                        ></Pagination>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Relations;