import React, { useEffect, useState } from "react";
import Input from "./Input";
import Select from "./Select";
import axios from "axios";
import getCookie from '../auth/Cookie';
import { useOutletContext } from "react-router-dom";
var csrftoken = getCookie('csrftoken');
const EditDetailsModal = ({ settingsState }) => {
    const context = useOutletContext();
    const [state, setState] = useState({
        email: null,
        country: null,
        city: null,
        countries: null
    })

    const FieldListener = (type, event) => {
        event.persist();
        setState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }
    const editDetail = () => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: "post",
            url: `${process.env.API_URL}api/settings/`,
            data: {
                method: "update",
                type: "billing",
                email: state.email,
                country: state.country,
                city: state.city,
            }
        })
            .then(function (response) {
                context.setModalState({ modalIsOpen: false, type: null })
            })
    }
    useEffect(() => {
        if (settingsState && settingsState.countries) {
            setState(oldstate => ({ ...oldstate, "countries": settingsState.countries, "country": settingsState.country }))
        }

    }, [])
    return (
        <>
            {state ?
                <div className='modal-background'>
                    <div className='card generalcard'>
                        <div className="modal-title" style={{ paddingBottom: "5px" }}>
                            Edit Details
                        </div>
                        <div style={{ textAlign: "center", paddingBottom: "15px" }}>Edit your current details</div>
                        <Select
                            onChange={(e) => FieldListener("country", e)}
                            label="Country"
                            name="country"
                            options={state.countries || []}
                            selected={state.country || ''}
                        />
                        <Input
                            onChange={(e) => FieldListener("city", e)}
                            type="text"
                            label="City"
                            name="city"
                            value={state.city || ''}
                        />
                        <div className="button-container-center">
                            <div onClick={() => { context.setModalState({ modalIsOpen: false }) }} className="button-red button-100">Cancel</div>
                            <div onClick={() => { editDetail() }} className="button-blue button-100">Submit</div>
                        </div>
                    </div>

                </div>
                : null}

        </>
    )
}
export default EditDetailsModal;