import React, { } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import App from './App';
import Login from '../auth/Login';
import CreateAccount from '../auth/CreateAccount';
import CreateAccountCreator from "../auth/CreateAccountCreator";
import PasswordResetConfirm from '../auth/PasswordResetConfirm';
import BrandOrCreator from "../auth/BrandOrCreator";
import PasswordReset from '../auth/PasswordReset';
import NoMatchRoute from '../web/NoMatchRoute';

import Campaign from '../campaign/Campaign';
import CampaignDetail from '../campaign/CampaignDetail';
import CampaignEdit from '../campaign/CampaignEdit';
import CampaigneCreate from '../campaign/CampaignCreate';

import Jobs from "../job/Jobs";
import Job from "../job/Job";
import JobDetail from "../job/JobDetail";
import JobCreate from '../job/JobCreate';
import JobEdit from '../job/JobEdit';

import Settings from "../settings/Settings";
import Home from "../home/Home";
import ErrorPage from "../web/ErrorPage";
import SendUGCForm from "../form/SendUGCForm";
import JobUGCForm from "../job/JobUGCForm"
import EmailConfirm from "../auth/EmailConfirm"
import PreSuccess from "../auth/PreSuccess"
import Relations from "../relations/Relations"
import ProductList from "../product/ProductList";
import ProductCreate from "../product/ProductCreate";
import ProductUpdate from "../product/ProductUpdate";
import UGCList from "../ugc/UGCList";
const PrivateRoute = ({ isAuth, children, setRouteState }) => {
    if (isAuth) {
        return children
    }
    setRouteState(oldstate => {
        let state_result = {
            ...oldstate,
            previous_path: window.location.pathname
        };
        return state_result
    });
    return <Navigate to="/app/login" />
}


const AppManager = ({ appRenderState, setAppRenderState }) => {


    return (
        <Router>
            <Routes>
                <Route path="app/login" element={<Login appState={appRenderState} setAppAuth={setAppRenderState} />}></Route>
                <Route path="app/register/" element={<BrandOrCreator appState={appRenderState} setAppAuth={setAppRenderState} />}></Route>
                <Route path="app/register/brand-onboarding/" element={<CreateAccount appState={appRenderState} setAppAuth={setAppRenderState} />}></Route>
                <Route path="app/register/creator-onboarding/" element={<CreateAccountCreator appState={appRenderState} setAppAuth={setAppRenderState} />}></Route>
                <Route path="app/password-reset-confirm/" element={<PasswordResetConfirm appState={appRenderState} setAppAuth={setAppRenderState} />}></Route>
                <Route path="app/password-reset/" element={<PasswordReset appState={appRenderState} setAppAuth={setAppRenderState} />}></Route>
                <Route path="app/register/pre-confirm/" element={<PreSuccess />}></Route>
                <Route path='app' element={
                    <PrivateRoute isAuth={appRenderState.isAuth} setRouteState={setAppRenderState}>
                        < App appRenderState={appRenderState} />
                    </PrivateRoute>
                }>
                    {/* <Route path="" element={<Home></Home>}></Route> */}
                    <Route path="" element={<Campaign></Campaign>}></Route>
                    <Route path="campaign/" element={<Campaign></Campaign>}></Route>
                    <Route path="campaign/:id/edit/" element={<CampaignEdit></CampaignEdit>}></Route>
                    <Route path="campaign/:id/" element={<CampaignDetail></CampaignDetail>}></Route>
                    <Route path="campaign/create/" element={<CampaigneCreate></CampaigneCreate>}></Route>

                    {/* <Route path="jobs/" element={<Jobs></Jobs>}></Route> */}
                    <Route path="job" element={<Job></Job>}></Route>
                    <Route path="job/create/" element={<JobCreate></JobCreate>}></Route>
                    <Route path="job/:id/edit/" element={<JobEdit></JobEdit>}></Route>
                    <Route path="job/:id/content/" element={<JobDetail></JobDetail>}></Route>

                    <Route path="relations" element={<Relations></Relations>}></Route>
                    <Route path="product/" element={<ProductList></ProductList>}></Route>
                    <Route path="product/create/" element={<ProductCreate></ProductCreate>}></Route>
                    <Route path="product/update/" element={<ProductUpdate></ProductUpdate>}></Route>


                    <Route path="ugcs/" element={<UGCList></UGCList>}></Route>
                    <Route path="settings/" element={<Settings></Settings>}></Route>
                </Route>
                <Route path="app/something-went-wrong/" element={<ErrorPage></ErrorPage>}></Route>
                <Route path="app/cmpn/:id/preview/" element={<SendUGCForm></SendUGCForm>}></Route>
                <Route path="app/cmpn/:id/" element={<SendUGCForm></SendUGCForm>}></Route>
                <Route path="app/job/:id/" element={<JobUGCForm></JobUGCForm>}></Route>
                <Route path="app/job/:id/preview" element={<JobUGCForm></JobUGCForm>}></Route>
                <Route path="app/email-confirm/:id/:token/" element={<EmailConfirm></EmailConfirm>}></Route>

                <Route path='app/*' element={< NoMatchRoute />}></Route>

            </Routes>
        </Router>
    );
};

export default AppManager;

