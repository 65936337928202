import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../web/ErrorMessage";
import getCookie from '../auth/Cookie';
var csrftoken = getCookie('csrftoken');
type IErrors = {
    errors?: Array<string>;
}
const PasswordResetConfirm = ({ appState, setAppAuth }) => {
    const [registerState, setRegisterState] = useState({
        username: "",
        email: "",
        password: "",
        password_confirm: ""
    })

    const [errorState, setErrorState] = useState<IErrors>({
        errors: []
    });
    const navigate = useNavigate()
    const FieldListener = (type, event) => {
        event.persist();
        setRegisterState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });

    }
    // const navigate = useNavigate();
    const submit = async e => {
        e.preventDefault();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        axios({
            headers:{
                'X-CSRFToken': csrftoken
            },
            method: 'post',
            url: process.env.API_URL + "api/password_reset/confirm/",
            data: {
                "token": urlParams.get("token"),
                "password":registerState.password
            }
        })
            .then(function (response) {
                window.location.href = "/login/"
            })
            .catch(function (error) {
                setErrorState({
                    "errors": [error.response.data.password]
                })
            });
    }
    useEffect(() => {
        if (appState && appState.isAuth) {
            navigate("/app/")
        }

    }, [appState]);

    const displayErrors = () => {
        if (errorState.errors.length > 0) {
            return <ErrorMessage errors={errorState.errors} setErrorState={setErrorState}></ErrorMessage>;
        } else {
            return null;
        }
    };

    const DisplayImageTruckMobile = () => {
        if(window.innerWidth < 769) {
            return(
            <div className="image-truck-mobile">
            <img src={Image} className="" alt="loading..." />
        </div>
            )
        }

    }
    return (
        <div className="container login">
            <div className="row login--row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                {DisplayImageTruckMobile()}
                    <div className="Auth-form-container">
                        <div className="Auth-form">
                            <div className="Auth-form-content">
                                <h3 className="Auth-form-title login-title">Enter your new password</h3>
                                <h6 className="login-h6 login-title">This is the last step in recovering your password</h6>
                                <div className="form-group mt-3">
                                    <input name='password'
                                        type="password"
                                        className="form-control mt-1"
                                        placeholder="Enter password"
                                        value={registerState.password}
                                        required
                                        onChange={e => FieldListener("password", e)} />
                                </div>
                                <div className="d-grid gap-2 mt-3">
                                    <button onClick={e => submit(e)} type="button"
                                        className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        {displayErrors()}
                    </div>

                </div>
                <div className="col-6 img-container">
                    {/* <img src={Image} className="" alt="loading..." /> */}
                </div>
            </div>
        </div>
    )
}
export default PasswordResetConfirm;