import React, { FunctionComponent } from 'react';
type ITableHead = {
    columns: any;
    key: number;
    deleteEntry: any;
    setModalState: any;
}

const TableRowCampaign: FunctionComponent<ITableHead> = ({ columns, openDeleteModal, setModalState }) => {

    const copyToClipBoard = (iframe_url) => {
        navigator.clipboard.writeText(iframe_url)
        setModalState({
            modalIsOpen: true,
            id: null,
            type: "copy_to_clipboard"
        })
    }

    const typeIcon = (type) => {
        if (type === "video/mp4") {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" style={{ width: "25px" }} fill="#fe6a68"
                ><path d="m19,0H5C2.243,0,0,2.243,0,5v14c0,2.757,2.243,5,5,5h10.515c.163,0,.324-.013.485-.024v-4.976c0-1.654,1.346-3,3-3h4.976c.011-.161.024-.322.024-.485V5c0-2.757-2.243-5-5-5Zm-10.342,10.069c-.318,0-.582-.246-.605-.563l-.201-2.776-.644,1.416c-.216.474-.889.474-1.105,0l-.644-1.416-.201,2.776c-.023.317-.287.563-.605.563-.352,0-.631-.299-.605-.651l.338-4.659c.032-.535.358-.759.749-.759.26,0,.501.184.638.486l.883,1.942.883-1.942c.137-.302.378-.486.638-.486.391,0,.717.224.749.759l.338,4.659c.026.352-.253.651-.605.651Zm3.809-2.099l-.717.003v1.471c0,.345-.28.625-.625.625s-.625-.28-.625-.625v-4.819c0-.345.28-.625.625-.625h1.342c1.109,0,2.012.891,2.012,1.985s-.902,1.985-2.012,1.985Zm6.79,1.475c0,.345-.28.625-.625.625s-.625-.28-.625-.625v-1.471h-1.23c-.828-.002-1.499-.673-1.499-1.501v-1.847c0-.345.28-.625.625-.625h.018c.348,0,.629.284.625.632-.006.514-.011,1.136-.014,1.586-.002.277.222.503.499.504l.969.002.003-2.099c0-.345.28-.624.625-.624h.004c.345,0,.625.28.625.625v4.819Zm-6.028-3.46c0,.398-.349.735-.762.735l-.71.003-.005-1.473h.715c.413,0,.762.336.762.735Zm5.771,12.015h4.54c-.347.913-.88,1.754-1.591,2.465l-1.484,1.485c-.712.711-1.552,1.244-2.465,1.591v-4.54c0-.551.448-1,1-1Z"></path></svg>
            )
        } else if (type === "folder") {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="25" height="25" fill="#d9d9d9"><path d="m0,18V5C0,2.794,1.794,1,4,1h2c.464,0,.928.109,1.342.316l3.155,1.578c.138.069.293.105.447.105h5.056c2.757,0,5,2.243,5,5h-10.976c-2.638,0-5.003,1.766-5.754,4.303l-2.673,9.339c-.977-.913-1.597-2.203-1.597-3.642Zm23.493-6.983c-.479-.643-1.209-1.012-2.008-1.025-.015,0-.027.008-.042.008h-11.419c-1.758,0-3.335,1.177-3.834,2.862l-2.818,9.843c.513.178,1.055.295,1.628.295h12.558c2.226,0,4.15-1.432,4.802-3.607l1.532-6.116c.234-.782.089-1.605-.398-2.26Z" /></svg>
            )
        }
        else {
            return "-"
        }
    }

    const tableColumn = () => {
        let table_columns = []
        let action_icons = []
        table_columns.push(<td style={{ width: "80px" }} className="truncate_text">{typeIcon(columns["mimeType"]) || "-"}</td>)
        table_columns.push(<td style={{ display: "flex" }} className="truncate_text"><div className='creator-username'>{columns["name"] || "-"}</div></td>)
        table_columns.push(<td className="truncate_text">{columns["brand"] || "-"}</td>)
        table_columns.push(<td className="truncate_text">{columns["creator"] || "-"}</td>)

        action_icons.push(
            <>
                {columns["web_view_url"] ?
                    <a className='svg-background cursor-point' style={{ marginRight: "20px" }} href={columns["web_view_url"]} target="_blank" >
                        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="20" height="20" fill="#FFE9E9"><path d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z" /></svg>
                    </a>
                    : null
                }
                {columns["download_url"] ?
                    <a className='cursor-point' href={columns["download_url"]} download target="_blank">
                        <div className='' style={{ backgroundColor: "whitesmoke", padding: "3px", borderRadius: "8px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" style={{ padding: "2px" }}>
                                <rect width="24" height="24" rx="8" fill="#" />
                                <path d="M9.878,18.122a3,3,0,0,0,4.244,0l3.211-3.211A1,1,0,0,0,15.919,13.5l-2.926,2.927L13,1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1l-.009,15.408L8.081,13.5a1,1,0,0,0-1.414,1.415Z" fill="#666666" /><path d="M23,16h0a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17A1,1,0,0,0,23,16Z" fill="#666666" />
                            </svg>         </div>           </a>
                    : null
                }


                {/* <div onClick={() => openDeleteModal(columns["ugc_id"])} className='cursor-point' style={{ marginRight: "20px" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="8" fill="#FFE9E9" />
                        <path d="M16.5 8H14.95C14.834 7.43571 14.5269 6.92867 14.0806 6.56436C13.6343 6.20005 13.0761 6.00073 12.5 6L11.5 6C10.9239 6.00073 10.3657 6.20005 9.91937 6.56436C9.47309 6.92867 9.16605 7.43571 9.05 8H7.5C7.36739 8 7.24021 8.05268 7.14645 8.14645C7.05268 8.24021 7 8.36739 7 8.5C7 8.63261 7.05268 8.75979 7.14645 8.85355C7.24021 8.94732 7.36739 9 7.5 9H8V15.5C8.00079 16.1628 8.26444 16.7982 8.73311 17.2669C9.20178 17.7356 9.8372 17.9992 10.5 18H13.5C14.1628 17.9992 14.7982 17.7356 15.2669 17.2669C15.7356 16.7982 15.9992 16.1628 16 15.5V9H16.5C16.6326 9 16.7598 8.94732 16.8536 8.85355C16.9473 8.75979 17 8.63261 17 8.5C17 8.36739 16.9473 8.24021 16.8536 8.14645C16.7598 8.05268 16.6326 8 16.5 8ZM11.5 7H12.5C12.8101 7.00038 13.1126 7.09669 13.3658 7.27572C13.619 7.45476 13.8107 7.70775 13.9145 8H10.0855C10.1893 7.70775 10.381 7.45476 10.6342 7.27572C10.8874 7.09669 11.1899 7.00038 11.5 7ZM15 15.5C15 15.8978 14.842 16.2794 14.5607 16.5607C14.2794 16.842 13.8978 17 13.5 17H10.5C10.1022 17 9.72064 16.842 9.43934 16.5607C9.15804 16.2794 9 15.8978 9 15.5V9H15V15.5Z" fill="#FC5A5A" />
                        <path d="M11 15C11.1326 15 11.2598 14.9473 11.3536 14.8536C11.4473 14.7598 11.5 14.6326 11.5 14.5V11.5C11.5 11.3674 11.4473 11.2402 11.3536 11.1464C11.2598 11.0527 11.1326 11 11 11C10.8674 11 10.7402 11.0527 10.6464 11.1464C10.5527 11.2402 10.5 11.3674 10.5 11.5V14.5C10.5 14.6326 10.5527 14.7598 10.6464 14.8536C10.7402 14.9473 10.8674 15 11 15Z" fill="#FC5A5A" />
                        <path d="M13 15C13.1326 15 13.2598 14.9473 13.3536 14.8536C13.4473 14.7598 13.5 14.6326 13.5 14.5V11.5C13.5 11.3674 13.4473 11.2402 13.3536 11.1464C13.2598 11.0527 13.1326 11 13 11C12.8674 11 12.7402 11.0527 12.6464 11.1464C12.5527 11.2402 12.5 11.3674 12.5 11.5V14.5C12.5 14.6326 12.5527 14.7598 12.6464 14.8536C12.7402 14.9473 12.8674 15 13 15Z" fill="#FC5A5A" />
                    </svg>
                </div> */}
            </>
        )

        table_columns.push(<td className="truncate_text" key={columns["id"]}><div className='trunc-action'>{action_icons}</div></td>)

        return table_columns
    }
    return (
        <>
            <tr key={columns["id"]}>
                {tableColumn()}
            </tr>
        </>
    )
}

export default TableRowCampaign;
