import React, { useState, useEffect } from "react";
import Select from "./Select";
import Icon from "../general/Icon";
import axios from "axios";
import getCookie from '../auth/Cookie';
var csrftoken = getCookie('csrftoken');
import { useOutletContext } from "react-router-dom";
const UpgradePlanModal = ({ settingsState }) => {
    const context = useOutletContext();
    let currentPage
    const [stepState, setStepState] = useState({
        step: "default"
    })
    const [state, setState] = useState({
        plan: null
    })


    const changePlan = () => {
        axios({
            headers: {
                'Authorization': localStorage.auth_token,
                'X-CSRFToken': csrftoken
            },
            method: "post",
            url: `${process.env.API_URL}api/settings/`,
            data: {
                plan: state.plan,
                user_id: state.user_id

            }
        })
            .then(function (response) {
                context.setModalState({ modalIsOpen: false, type: null })
            })
    }
    const FieldListener = (type, event) => {
        event.persist();
        setState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }
    useEffect(() => {
        if (settingsState) {
            setState(settingsState)
        }

    }, [])
    switch (stepState.step) {
        case "default":
            currentPage = <>
                <div className="modal-title" style={{ paddingBottom: "5px" }}>
                    Upgrade Plan
                </div>
                <div className="modal-description">
                    Choose the best plan
                </div>
                <div className="upgrade-plan-select-container">
                    {state && state.plans && state.plans.plans_json_list ?
                        <Select
                            onChange={(e) => FieldListener("plan", e)}
                            label="Choose plan"
                            name="plan"
                            options={state.plans.plans_json_list || []}
                            selected={state.plan || ''}
                        />
                        : null}
                    <div onClick={() => { setStepState({ "step": "upgrade-are-you-sure" }) }} className="button-regular button-change-plan">Change plan</div>
                </div>
                <hr style={{ margin: "40px 0" }}></hr>
                <div className="cancel-sub-container">
                    <div style={{ paddingBottom: "10px" }}>User current plan is standard plan</div>
                    <div className="cancel-sub">
                        <div>$99/month</div>
                        <div onClick={() => { setStepState({ "step": "cancel-are-you-sure" }) }} className="button-grey">
                            Cancel Subscription
                        </div>
                    </div>
                </div>
            </>
            break;
        case "cancel-subscription":
            currentPage = <>
                <div className="center-icon">
                    <Icon
                        type={"fa"}
                        icon={"check"}
                        icon_size={2}

                        icon_color_code={"#3A72B9"}
                    ></Icon>
                </div>
                <div className="modal-title" style={{ paddingBottom: "5px", fontSize: "18px" }}>
                    Your subscription cancelled successfully
                </div>
                <div className="button-container-center">
                    <div onClick={() => { context.setModalState({ "modalIsOpen": false }) }} className="button-regular button-100">Ok</div>
                </div>
            </>
            break

        case "cancel-are-you-sure":
            currentPage = <>
                <div className="center-icon">
                    <Icon
                        type={"fa"}
                        icon={"triangle-exclamation"}
                        icon_size={4}
                        icon_color_code={"#fe6a68"}
                    ></Icon>
                </div>
                <div className="modal-title" style={{ paddingBottom: "5px", fontSize: "18px" }}>
                    Are you sure you would like to cancel your subscription?
                </div>
                <div className="button-container-center">
                    <div onClick={() => { context.setModalState({ modalIsOpen: false }) }} className="button-red button-100">Cancel</div>
                    <div onClick={() => { setStepState({ "step": "cancel-subscription" }) }} className="button-blue button-100">Continue</div>

                </div>
            </>
            break;

        case "upgrade-are-you-sure":
            currentPage = <>
                <div className="center-icon">
                    <Icon
                        type={"fa"}
                        icon={"face-smile"}
                        icon_size={4}
                        icon_color_code={"#b2ccfb"}
                    ></Icon>
                </div>
                <div className="modal-title" style={{ paddingBottom: "5px", fontSize: "18px" }}>
                    Are you sure you would like to upgrade your subscription?
                </div>
                <div className="button-container-center">
                    <div onClick={() => { context.setModalState({ modalIsOpen: false }) }} className="button-grey button-100">Cancel</div>
                    <div onClick={() => changePlan()} className="button-blue button-100">Continue</div>

                </div>
            </>
            break;
    }

    return (
        <>
            <div className='modal-background'>
                <div className='card generalcard'>
                    <div className="container-right-icon">
                        <div onClick={() => { context.setModalState({ modalIsOpen: false }) }} className="right-icon">
                            <Icon
                                type={"fa"}
                                icon={"xmark"}
                                icon_size={2}

                                icon_color_code={"#6d6f71"}
                            ></Icon>
                        </div>
                    </div>
                    {state ? currentPage : null}
                </div>
            </div>
        </>
    )
}
export default UpgradePlanModal;