import React, { useEffect, useState } from "react";
import Icon from "../general/Icon";
import Input from "./Input";
const FolderModal = ({ modalState, setModalState, IntegrationStorage }) => {
    const [state, SetState] = useState({
    })
    const FieldListener = (type, event) => {
        event.persist();
        SetState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });
    }

    return (
        <>
            <div className='modal-background'>
                <div className='card generalcard'>
                    <div className="container-right-icon">
                        <div onClick={() => { setModalState({ modalIsOpen: false }) }} className="right-icon">
                            <Icon
                                type={"fa"}
                                icon={"xmark"}
                                icon_size={2}

                                icon_color_code={"#6d6f71"}
                            ></Icon>
                        </div>
                    </div>
                    <>
                        <div style={{ fontWeight: "700", fontSize: "20px", paddingBottom:"10px" }}>Create Folder</div>
                        <div className="row">
                            <div className="col-12 brand_image_col_6">
                                <Input
                                    onChange={(e) => FieldListener("name", e)}
                                    type="text"
                                    label=""
                                    name="name"
                                    value={state.name || ''}
                                />
                            </div>

                        </div >
                        <div className="button-container-center" style={{paddingTop: "0px"}}>
                            <div onClick={() => { setModalState({ modalIsOpen: false }) }} className="button-red button-100">Cancel</div>
                            <div onClick={() => {
                                IntegrationStorage({
                                    "method": "create",
                                    "action": "create_folder",
                                    "name": state.name
                                })
                            }} className="button-blue button-100">Create folder</div>
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}
export default FolderModal;
