import React, { useState } from "react";
import ThreeStepModalSwitch from "./ThreeStepModalSwitch";
import Select from "./Select";
import Icon from "../general/Icon";
import TextArea from "./TextArea";
import Input from "./Input"
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import getCookie from '../auth/Cookie';
var csrftoken = getCookie('csrftoken');
import Spinner from "../../files/spinningugcbird.gif";
const UGCRewardRejectModal = ({ refreshData }) => {
    let currentPage
    const context = useOutletContext();
    const [stepState, setStepState] = useState({
        step: "start"
    })
    const [state, setState] = useState({
    })

    const [prepareState, setPrepareState] = useState({
        step: "loading"

    })
    const FieldListener = (type, event) => {
        event.persist();
        setState(oldstate => {
            let state_result = {
                ...oldstate,
                [type]: event.target.value,

            };
            return state_result
        });

    }
    const updateUGCStatus = () => {
        if (context.modalState && context.modalState.obj_id && context.modalState.ugc_reward_reject) {
            axios({
                headers: {
                    'Authorization': localStorage.auth_token,
                    'X-CSRFToken': csrftoken
                },
                method: "patch",
                url: process.env.API_URL + "api/ugc/",
                data: {
                    id: context.modalState.obj_id,
                    status: context.modalState.ugc_reward_reject,
                    thankyou_title:state.thankyou_title,
                    thankyou_message: state.thankyou_message,
                    reject_title:state.reject_title,
                    reject_message:state.reject_message,
                    reward: state.reward
                }
            })
                .then(() => {
                    setPrepareState(oldstate => {
                        let results = {
                            ...oldstate,
                            step: "ok"
                        };
                        return results
                    })
                    refreshData()
                })
                .catch((error) => {
                    setPrepareState(oldstate => {
                        let results = {
                            ...oldstate,
                            step: "error"
                        };
                        return results
                    })
                })
        }

    }
    let currentPrepare = null
    switch (prepareState.step) {
        case "loading":
            currentPrepare = <>
                <div className="modal-title" style={{ paddingBottom: "5px", marginTop: "20px" }}>
                    Sending the email and saving the content to Google Drive
                </div>
                <div className="modal-body">
                    <img src={Spinner}></img>
                </div>
            </>
            break;
        case "ok":
            currentPrepare = <>
                <div className="modal-title" style={{ paddingBottom: "5px", marginTop: "20px" }}>
                    Success
                </div>
                {/* <div className="modal-body">
                    <img src={Spinner}></img>
                </div> */}
            </>
            break;

        case "error":
            currentPrepare = <>
                <div className="modal-title" style={{ paddingBottom: "5px", marginTop: "20px" }}>
                    Something went wrong
                </div>
                {/* <div className="modal-body">
                    <img src={Spinner}></img>
                </div> */}
            </>
            break;
    }


    switch (stepState.step) {
        case "start":
            currentPage = <>
                <div className="modal-title" style={{ paddingBottom: "5px", marginTop: "20px" }}>
                    Are you happy with this UGC?
                </div>
                <div className="modal-description" style={{ textAlign: "center" }}>
                    Compleet task and reward your UGC Creator
                </div>
                <div className="ugc-button-container">

                    <div onClick={() => context.setModalState(oldstate => {
                        let results = {
                            ...oldstate,
                            ugc_reward_reject: null,
                            modalIsOpen: false,
                            type: null

                        };
                        return results
                    })} className="ugc-reject-button">Cancel</div>
                    {/* <div onClick={updateUGCStatus} className="ugc-reward-button">Continue</div> */}
                    <div onClick={() => setStepState({ step: "reward" })} className="ugc-reward-button">Continue</div>
                </div>
            </>
            break;
        case "reward":
            currentPage = <>
                <div className="modal-title" style={{ paddingBottom: "5px", marginTop: "20px" }}>
                    Are you really happy with this UGC give a custom reward!
                </div>
                <div className="modal-description">
                    Note if you want to use the default reward you can skip this step.
                </div>
                <div className="modal-body">
                    <div className="row" style={{ marginTop: "20px" }}>
                        <Input
                            onChange={(e) => FieldListener("reward", e)}
                            type="text"
                            label=""
                            name="reward"
                            value={state.reward || ''}
                            placeholder="Enter the custom reward"
                        />
                    </div>
                </div>

                <div className="ugc-button-container">
                    <div onClick={() => (setStepState({ step: "email" }), setState({ reward: null }))} className="ugc-reject-button">Skip</div>
                    <div onClick={() => setStepState({ step: "email" })} className="ugc-reward-button">Continue</div>
                </div>
            </>
            break

        case "email":
            currentPage = <>
                <div className="modal-title" style={{ paddingBottom: "5px", marginTop: "20px" }}>
                    Write a custom email message for the creator
                </div>
                <div className="modal-description">
                    Note if you want to use the default email message you can skip this step.
                </div>
                <div className="modal-body">
                    <div className="row" style={{ marginTop: "20px" }}>
                        <Input
                            onChange={(e) => FieldListener("thankyou_title", e)}
                            type="text"
                            label=""
                            name="thankyou_title"
                            value={state.thankyou_title || ''}
                            placeholder="Enter the custom thankyou title"
                        />
                    </div>
                    <div className="row">
                        <TextArea
                            onChange={(e) => FieldListener("thankyou_message", e)}
                            label=""
                            name="text"
                            value={state.thankyou_message || ''}
                            placeholder={"Describe the steps the content creator should follow"}
                        />
                    </div>
                </div>

                <div className="ugc-button-container">
                    <div onClick={() => (setStepState({ step: "reward" }), setState({ reward: null }))} className="ugc-reject-button">Back</div>
                    <div onClick={() => (setStepState({ step: "prepare" }), updateUGCStatus())} className="ugc-reward-button">Continue</div>
                </div>
            </>
            break

        case "prepare":
            currentPage = <>
                {currentPrepare}
{/* 
                <div className="ugc-button-container">
                    <div onClick={() => (setStepState({ step: "email" }), setState({ reward: null }))} className="ugc-reject-button">Skip</div>
                    <div onClick={() => setStepState({ step: "email" })} className="ugc-reward-button">Continue</div>
                </div> */}
            </>
            break

    }

    return (
        <>
            <div className='modal-background'>
                <div className='card generalcard-big'>
                    <div className="container-right-icon">
                        <div onClick={() => context.setModalState(oldstate => {
                            let results = {
                                ...oldstate,
                                ugc_reward_reject: null,
                                modalIsOpen: false,
                                type: null

                            };
                            return results
                        })} className="right-icon">
                            <Icon
                                type={"fa"}
                                icon={"xmark"}
                                icon_size={2}

                                icon_color_code={"#6d6f71"}
                            ></Icon>
                        </div>
                    </div>
                    <div className="test">
                        <div className="module-steps">
                            <div className="module-step">
                                <div className={`module-step-icon ${stepState.step === "start" ? "module-step-icon-active" : ""}`}>
                                    <Icon
                                        type={"fa"}
                                        icon={"flag-checkered"}
                                        icon_size={1}
                                        icon_color_code={"#BFC1C3"}
                                    ></Icon>
                                </div>

                                <div className="module-step-text">
                                    <div className="module-title">
                                        Create a reward
                                    </div>
                                    <div className="module-text">
                                        Start
                                    </div>
                                </div>
                                <div className="module-step-arrow">
                                    <Icon
                                        type={"fa"}
                                        icon={"arrow-right"}
                                        icon_size={1}
                                        icon_color_code={"#BFC1C3"}
                                    ></Icon>
                                </div>
                            </div>
                            <div className="module-step">
                                <div className={`module-step-icon ${stepState.step === "reward" ? "module-step-icon-active" : ""}`}>
                                    <Icon
                                        type={"fa"}
                                        icon={"gift"}
                                        icon_size={1}
                                        icon_color_code={"#BFC1C3"}
                                    ></Icon>
                                </div>

                                <div className="module-step-text">
                                    <div className="module-title">
                                        Add custom reward
                                    </div>
                                    <div className="module-text">
                                        Custom reward details
                                    </div>
                                </div>
                                <div className="module-step-arrow">
                                    <Icon
                                        type={"fa"}
                                        icon={"arrow-right"}
                                        icon_size={1}
                                        icon_color_code={"#BFC1C3"}
                                    ></Icon>
                                </div>
                            </div>
                            <div className="module-step">
                                <div className={`module-step-icon ${stepState.step === "email" ? "module-step-icon-active" : ""}`}>
                                    <Icon
                                        type={"fa"}
                                        icon={"envelope"}
                                        icon_size={1}
                                        icon_color_code={"#BFC1C3"}
                                    ></Icon>
                                </div>

                                <div className="module-step-text">
                                    <div className="module-title">
                                        Email
                                    </div>
                                    <div className="module-text">
                                        Message details
                                    </div>
                                </div>
                                <div className="module-step-arrow">
                                    <Icon
                                        type={"fa"}
                                        icon={"arrow-right"}
                                        icon_size={1}
                                        icon_color_code={"#BFC1C3"}
                                    ></Icon>
                                </div>
                            </div>
                            <div className="module-step">
                                <div className={`module-step-icon ${stepState.step === "prepare" ? "module-step-icon-active" : ""}`}>
                                    <Icon
                                        type={"fa"}
                                        icon={"spinner"}
                                        icon_size={1}
                                        icon_color_code={"#BFC1C3"}
                                    ></Icon>
                                </div>

                                <div className="module-step-text">
                                    <div className="module-title">
                                        Prepare reward
                                    </div>
                                    <div className="module-text">
                                        Preparing the reward
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {currentPage}
                </div>

            </div>
        </>
    )
}
export default UGCRewardRejectModal;